#page-ebooks {
  h2 {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
    margin-bottom: 26px;
  }

  .c-ebook {
    .ebook-cover {
      border-radius: 8px;
    }
    border-radius: 8px;
  }
  .ebook-cover {
    position: relative;
    object-fit: cover;
    width: 100%;
    max-width: 500px;
    .page-ebook-background {
      display: inline-block;
      width: 100%;
      height: inherit;
    }
    .page-ebook-background-color {
      background-color: #0f1b35 !important;
      display: inline-block;
      width: 100%;
      height: inherit;
    }
    img {
      display: inline-block;
      width: 100%;
    }
    .eclipse {
      position: absolute;
      height: inherit;
      width: 100%;
      bottom: 0px;
      right: 0px;
      background: linear-gradient(to top, #121212 0%, transparent 40%);
    }
  }

  h4 {
    color: #fff !important;
  }
  .author {
    div,
    span {
      color: #fff !important;
    }
  }

  .info-ebook {
    h2 {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: #ffffff;
      margin-bottom: 26px;
    }
    .uk-card-body {
      border-radius: 8px;
      padding: 20px;
      background-color: #121212;
      color: #fff;
      .table {
        margin: 0;
        td {
          border-top: 0;
          &:first-child {
            border-top: 1px solid #161d2d;
          }
          &:hover {
            background-color: #161d2d;
          }
          border-bottom: 1px solid #161d2d;
          cursor: pointer;
        }
      }
    }
  }

  .uk-card-default {
    border-radius: 8px;
    background-color: #121212;
  }
}
