@import url('../assets/css/style.css');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.dropdown-notifications {
  background-color: #040915 !important;
  border-radius: 8px;
  &:before {
    border-bottom: 13px solid #040915 !important;
  }
  li {
    &:hover {
      background-color: #161d2d !important;
    }
    a {
      &:hover {
        background-color: transparent !important;
      }
      color: #fff !important;
    }
  }
}

.uk-pagination {
  li {
    span {
      background-color: #fff;
      color: #161d2d;
      &:hover {
        background-color: #c8c9f8;
      }
    }
    &.uk-active {
      span {
        background-color: #575cdd;
      }
    }
  }
}
.card-course {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 312px;
  background: #202634;
  font-size: 32px;
  color: #586077;
  text-align: center;
  padding: 0 20px;
  @media only screen and (max-width: 956px) {
    font-size: 24px;
  }
}
.card-course-lg {
  height: 512px;
}

#page-home-component {
  .container {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: inherit;
    height: inherit;

    margin: 0;
    .row {
      .content {
        .top {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 2px;
          color: #ffffff;
        }
        h1 {
          max-width: 470px;
          color: #fff !important;
          font-weight: 500;
          font-size: 51px;
          line-height: 56px;
          display: flex;
          align-items: center;
          color: #ffffff;
          margin-bottom: 4;
        }
        p {
          font-family: 'Inter', 'Roboto', sans-serif;
          font-size: 17px;
          font-weight: 400;
          max-width: 510px;
          color: #fff;
          margin-bottom: 50px;
          text-shadow: #000 2px 0 5px;
          line-height: 27px;
        }
        .bottom-bar {
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          .right {
            flex-wrap: wrap;
            display: flex;
            row-gap: 16px;
            column-gap: 17px;
          }
          .list {
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            &:hover {
              transform: scale(0.95);
            }
            i {
              font-size: 24px;
            }
            span {
              font-weight: 400;
              font-size: 13.6416px;
              line-height: 20px;
              text-align: center;
              letter-spacing: -0.0408px;
              color: var(--color-text) !important;
            }
          }
          .btn-primary {
            border-radius: 100px;
            padding: 17.5px 24px;
            display: flex;
            align-items: center;
            background-color: #df0043;
            &:hover {
              background-color: #b60037;
            }
            cursor: pointer;
            i {
              font-size: 21px;
              color: #fff;
              margin-right: 6px;
            }
            span {
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              color: #fff;
            }
          }
          .btn-secundary {
            border-radius: 100px;
            padding: 17.5px 24px;
            display: flex;
            align-items: center;
            background: rgba(0, 0, 0, 0.49);
            border-radius: 100px;

            &:hover {
              background: rgba(255, 255, 255, 0.288);
            }
            cursor: pointer;
            i {
              font-size: 18px;
              color: white;
              margin-right: 4px;
            }
            span {
              white-space: nowrap;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .page-home-component-bg {
    position: relative;
    object-fit: fill;
    height: 605px;
    width: 100%;
    .background {
      display: inline-block;
      width: 100%;
      height: inherit;
    }
    .background-color {
      background-color: var(--color-400-primary) !important;
      display: inline-block;
      width: 100%;
      height: inherit;
    }
    .eclipse {
      position: absolute;
      height: inherit;
      width: 100%;
      bottom: 0px;
      right: 0px;
      background: linear-gradient(to top, #121212 10%, #0000 40%);
    }
  }
}

#layout-page-product {
  header {
    background: transparent !important;
  }
  background-color: var(--color-400-primary) !important;
  .btn-primary {
    background-color: var(--color-500-secondary) !important;
    &:hover {
      background-color: var(--color-600-secondary) !important;
    }
  }
  #page-home-component {
    .page-home-component-bg .background-color {
      background-color: var(--color-600-primary) !important;
    }
    .eclipse {
      background: linear-gradient(
        to top,
        var(--color-400-primary) 10%,
        transparent 40%
      );
    }
  }
  #page-course {
    h2 {
      color: var(--color-text);
    }
    h2::before {
      content: '';
      width: 4px;
      height: 33px;
      background-color: var(--color-400-secondary) !important;
      margin-right: 11px;
      border-radius: 100px;
    }
    .list-products {
      .product-card-component {
        min-width: 290px !important;
        @media only screen and (max-width: 600px) {
          min-width: 200px !important;
        }

        .card-course {
          background-color: var(--color-600-primary);
          color: var(--color-100-primary);
        }
        .info {
          width: 100%;
          background-color: var(--color-600-primary);
          .bottom {
            .lessons {
              background-color: var(--color-700-primary);
            }
          }
        }
      }
    }
    .uk-card-default {
      background-color: var(--color-600-primary);
      .avatar-image {
        border: 2px solid var(--color-700-primary);
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .product-card-component {
      min-width: 200px !important;
      .info {
        padding: 10px;
        h3 {
          font-size: 16px;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        h3::before {
          display: none;
        }
        .bottom {
          .lessons {
            background: transparent;
            i {
              display: none;
            }
            span {
              font-size: 13px;
              color: #9a96ab;
            }
          }
        }
      }
    }
  }
}

#page-learn {
  .course-header {
    background-color: var(--color-500-primary) !important;
  }
  .course-content {
    background-color: var(--color-500-primary) !important;
  }
  .nav .nav-link.active {
    color: #fff;
    margin-bottom: -4px;
    border-bottom: 3px solid var(--color-400-secondary) !important;
  }
  .nav .nav-link.active {
    background-color: var(--color-600-primary) !important;
    color: #fff !important;
  }
  .course-content-inner .content .row-nav {
    border-bottom: 1px solid var(--color-600-primary) !important;
  }
  .html-description {
    background-color: var(--color-500-primary) !important;
    color: var(--color-text);
    background-color: #01040c;
    padding: 0px;
    font-size: 16px;
    a {
      color: #2f23b9 !important;
      text-decoration: underline !important;
      cursor: pointer !important;
    }
  }
  .open-notes-wrap .open-notes div {
    outline: 2px solid var(--color-400-secondary) !important;
    svg {
      path {
        fill: var(--color-400-secondary) !important ;
      }
    }
  }
  .check-box {
    .react-switch {
      .react-switch-bg {
        background-color: #20c374 !important;
      }
      &.not-checked {
        .react-switch-bg {
          background-color: var(--color-400-secondary) !important;
        }
      }
    }
  }
  .react-switch-handle {
    height: 16px !important;
    width: 16px !important;
    top: 2px !important;
    background-color: #fff !important;
  }
  .next-video {
    .btn-primary {
      background-color: var(--color-400-secondary) !important;
      border-color: var(--color-400-secondary) !important;
      color: var(--color-400-primary) !important;
    }
  }
  .course-content-inner .video-responsive {
    background-color: var(--color-700-primary) !important;
  }
  .course-content-inner .content .open-notes-wrap .open-notes div:hover {
    background-color: var(--color-600-secondary) !important;
  }

  .course-sidebar {
    background-color: var(--color-600-primary) !important;
    .course-sidebar-title {
      background-color: var(--color-600-primary) !important;
    }
    .course-video-list li {
      background-color: var(--color-600-primary) !important;
    }
    .course-video-list li.watched {
      background-color: var(--color-600-primary) !important;
    }
    .drop-show {
      &.not {
        .accordion-check-2 {
          svg {
            path {
              fill: var(--color-100-secondary) !important;
            }
          }
        }
      }
      &.done {
        .accordion-check-2 {
          svg {
            path {
              fill: var(--color-400-secondary) !important;
            }
          }
        }
      }
    }
    .course-video-list {
      li.watched {
        svg {
          path {
            fill: var(--color-400-secondary) !important;
          }
        }
      }
    }
  }
  .highlight-watched {
    li.current {
      svg {
        path {
          fill: var(--color-100-secondary) !important;
        }
      }
      &.watched {
        svg {
          path {
            fill: var(--color-400-secondary) !important;
          }
        }
      }
    }
  }

  .course-video-list {
    li {
      background-color: #0c1504 !important;
      a {
        color: #fff !important;
      }
      .accordion-check {
        svg {
          path {
            fill: var(--color-100-secondary) !important;
          }
        }
      }
    }
  }

  .uk-accordion-content {
    border-bottom: 1px solid var(--color-500-primary) !important;
  }
  .uk-accordion-title {
    padding: 30px 16px;
    @media (max-width: 600px) {
      padding: 16px !important;
    }
    border-bottom: 1px solid var(--color-500-primary) !important;
  }

  .course-video-list-section li a {
    background-color: var(--color-600-primary) !important;
  }
  .course-video-list-section li a:hover {
    background-color: var(--color-700-primary) !important;
  }

  .accordion-expand i {
    color: var(--color-400-secondary) !important;
  }
  .course-content-inner .content .tools .check-box .text {
    color: var(--color-text) !important;
  }
  .content .row-nav .nav .nav-link span {
    color: var(--color-text) !important;
  }
  .course-header h4 {
    color: var(--color-text) !important;
  }
  .course-video-list-section li a .accordion-module-content .module-text {
    color: var(--color-text) !important;
  }
  .course-video-list-section li a .total-lessons {
    color: var(--color-text) !important;
  }
  .course-video-list li a .lesson-video-title {
    color: var(--color-text);
  }
  .course-video-list li a .lesson-video-duration {
    color: var(--color-text);
  }

  .post {
    background-color: var(--color-500-primary) !important;
  }
  .btn-close {
    background-color: transparent !important;
    border: 2px solid var(--color-text) !important;
    color: var(--color-text) !important;
    box-shadow: none !important;
  }

  .btn-primary {
    color: var(--color-text-secondary);
  }
}

#page-learn {
  .notes {
    background-color: var(--color-400-primary) !important;
    .note {
      background-color: var(--color-600-primary) !important;
      .head {
        color: var(--color-text) !important;
      }
      .text {
        color: var(--color-text) !important;
      }
      svg {
        circle {
          stroke-width: 1.5px;
          stroke: var(--color-100-secondary) !important;
        }
        path {
          fill: var(--color-100-secondary) !important;
        }
      }
    }
    .btn {
      background-color: var(--color-400-secondary) !important;
      span,
      i {
        color: var(--color-text-secondary) !important;
      }
    }
  }
}
.notes {
  .header {
    i,
    h2 {
      color: var(--color-text) !important;
    }
  }
}

.btn-close {
  background-color: transparent !important;
  border: 2px solid var(--color-text) !important;
  color: var(--color-text) !important;
  box-shadow: none !important;
}

#whatsapp-element {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: #33cc5a;
  position: fixed;
  bottom: 100px;
  right: 27px;
  border-radius: 100%;
  i {
    font-size: 40px;
    color: #fff;
  }
}
