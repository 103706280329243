* {
  box-sizing: border-box;
}

.course-card {
  margin-bottom: 30px;
}

.page-content {
  padding: 0;
  margin: 0;
  & .container {
    padding-top: 45px;
  }
}

button,
.btn {
  cursor: pointer;
}

#loading {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  i {
    font-size: 80px;
    opacity: 0.8;
    color: rgba(0, 0, 0, 0.3);
  }
  span {
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
    opacity: 0.5;
  }
}

.skeleton {
  > span:first-child {
    display: block !important;
  }

  > span:last-child {
    margin-bottom: 2px !important;
    display: block;
  }

  span > .react-loading-skeleton {
    display: block;
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: -0px;
    }
  }
}

.cap {
  text-transform: capitalize;
}

header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9994;
  width: 100%;
  background-color: transparent;
  margin-bottom: 30px;
  background: linear-gradient(180deg, #121212cc 0%, #12121200 85%);
  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .nav2 {
    display: none;
  }
  .header-container {
    display: flex;
  }
  .header-left {
    display: flex;
    ul {
      li {
        a {
          opacity: 0.8 !important;
          color: #999;
          border: 0 !important;
          &:hover {
            opacity: 1 !important;
          }
          &.nav-active {
            opacity: 1 !important;
            font-weight: 700 !important;
          }
        }
      }
    }
  }
  .nav1 {
    a {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      display: flex;
      align-items: center;
      color: #ffffff !important;
      &:hover {
        color: #b2e9e0 !important;
      }
      &.nav-active {
        color: #4dd0bb !important;
      }
      i {
        font-size: 24px;
        margin-right: 8px;
      }
    }
  }
  .header-right {
    display: flex;
    align-items: center;
    .header-widget {
      svg {
        font-size: 24px;
      }
    }
    @media only screen and (max-width: 956px) {
      .header-widget {
        display: none;
      }
    }
  }
  .logo {
    font-size: 30px !important;
    font-weight: bold !important;
    color: #12111b !important;
    margin-right: 32px !important;
    max-width: 130px;
  }
  .menu {
    display: flex;
    align-items: center;
    list-style-type: none;
    padding: 0 !important;
    margin: 0 !important;
    li {
      a {
        font-weight: 400;
        font-size: 16px;
        padding: 16px 0;
        margin-right: 16px;
        font-size: 16px;
        color: #12111b;
      }
      &:last-child a {
        margin-right: 0 !important;
      }
    }
  }
}
.nav-active {
  color: #16151e !important;
  border: 0 !important;
  opacity: 1 !important;
  &:hover {
    border: 0 !important;
  }
}
.profile-box {
  display: flex;
  &:focus-visible {
    border: 0;
    outline: 0;
  }
  .profile-name {
    margin-right: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #ffffff;
  }
  .profile-picture {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
}
.dropdown-user-menu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .dropdown-link {
    margin: 0 !important;
  }
}
#btn-mobile {
  height: 10px;
  display: none;
}

@media only screen and (max-width: 956px) {
  header {
    padding-top: 16px;
  }
  .nav1 {
    display: none;
  }
  .header-container {
    align-items: normal !important;
  }
  .container {
    padding-top: 0;
  }

  .header-widget {
    display: none;
  }
  .active {
    background-color: #12111b;
    color: #fff !important;
  }
  .nav2.activeMenuMobile {
    position: absolute;
    width: 100%;
    top: 63px;
    right: 0;
    background-color: #121212;
    display: block;
    z-index: 1000;
    transition: 0.3s;

    ul {
      display: block;
    }
    li {
      &:first-child {
        border-top: 2px solid #0a0a0a;
      }

      border-bottom: 1px solid #0a0a0a;
    }
    li a {
      display: block;
      padding: 16px 15px;
      margin-right: 0;
      color: #fff !important;
      opacity: 1;
    }
    li a.nav-active {
      background-color: #000000 !important;
      border-bottom: 0 !important;
      color: #fff !important;
    }
    li:hover {
      color: #555;
    }
    li a:hover {
      opacity: 0.8;
      background: #161d2d;
    }
  }
  #btn-mobile {
    display: flex;
    padding: 12px 12px 30px 12px;
    font-size: 1rem;
    border: none;
    background: none;
    cursor: pointer;
    &:focus,
    &:focus-visible {
      border: none !important;
      outline: none !important;
    }
  }
  #hamburger {
    border-top: 2px solid #f6f6f6;
    width: 20px;
    height: 2px;
  }
  #hamburger::after,
  #hamburger::before {
    background-color: #f6f6f6;
    content: '';
    display: block;
    width: 20px;
    height: 2px;
    margin-top: 5px;
  }
}

.author {
  display: flex;
  font-size: 14px;
  align-items: flex-start;
  align-items: center;

  .avatar {
    margin-right: 30px;
    width: 120px;
    height: auto;
  }

  .nickname {
    font-size: 16px;
    font-weight: bold;
    color: #12111b;
  }
  .contact {
    color: #fff;
    span {
      margin-top: 16px;
      display: block;
    }
  }
}

.avatar-image {
  border-radius: 100%;
  border: 2px solid #12111b;
  min-width: 50px;
  min-height: 50px;
  overflow: hidden;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.wrap-page-content {
  min-height: calc(100vh - 260px);
  margin-bottom: 50px;

  .no-content {
    margin-top: 63px;
    h3 {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 24px;
      line-height: 30px;
      color: #ffffff;
      margin-bottom: 26px;
      text-transform: none;
    }
  }
}

footer {
  padding-top: 72px;
  padding-bottom: 121px;
  a {
    display: flex;
    justify-content: center;
    max-width: 150px;
    margin: 0 auto;
    img {
      width: 120px;
    }
  }
}

.container {
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-top: 0px !important;
}

.margin-top-back {
  margin-top: -30px;
}

.btn {
  border-radius: 100px;
}
.btn-default {
  background-color: #4dd0bb !important;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #424242 #1a1a1a;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px !important;
  height: 14px !important;
}

*::-webkit-scrollbar-track {
  background-color: #1a1a1a !important;
}

*::-webkit-scrollbar-thumb {
  background-color: #424242 !important;
  border-radius: 20px;
}
.modal-content {
  border: 0 !important;
  outline: 0 !important;
}

button.close {
  color: #fff;
  opacity: 1;
  border: 0;
  outline: 0;
  text-shadow: none !important;
  &:hover {
    color: rgba(210, 210, 210, 0.9) !important;
  }
}

.intro-background-banner {
  object-fit: cover;
  object-position: center center;
  background-attachment: fixed;
}
.intro-background-banner-mobile {
  object-fit: cover;
}

.content {
  .top,
  h1 {
    text-shadow: #000 2px 0 5px;
  }
  .bottom-bar {
    filter: drop-shadow(0px 1px 10px #0000009a);
  }
}

.list-products {
  display: flex;
  row-gap: 30px !important;
  overflow-x: auto !important;
  padding-bottom: 14px;
  margin-bottom: 40px !important;
  .link-course {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-left: 32px;
    @media only screen and (max-width: 600px) {
      margin-left: 16px;
    }

    &:first-child {
      margin-left: 0;
    }
  }
  .product-card-component {
    position: relative;
    width: 304px !important;
    border-radius: 8px !important;
    cursor: pointer;
    overflow: hidden;
    &:hover {
      .info {
        -webkit-box-shadow: 2px -4px 59px 23px rgba(0, 0, 0, 0.37);
        -moz-box-shadow: 2px -4px 59px 23px rgba(0, 0, 0, 0.37);
        box-shadow: 2px -4px 59px 23px rgba(0, 0, 0, 0.37);
        bottom: 0;
      }
    }
    .img-wrap {
      width: 100%;
      justify-content: center;
      align-items: flex-end;
      display: flex;
      position: relative;
      height: 494px;
    }
    img {
      display: inline-block;
      border-radius: 8px 8px 0 0;
      height: 494px;
      object-fit: cover;
      width: inherit;
    }
    .info {
      position: absolute;
      bottom: -160px;
      padding: 16px;
      border-radius: 0;
      background-color: #252525;
      width: inherit;
      box-sizing: border-box;

      .duration {
        display: flex;
        align-items: center;
        color: #929292;
        i {
          font-size: 19px;
          margin-right: 8px;
        }
        .text {
          font-size: 12px;
          font-weight: 500;
        }
      }
      .category {
        margin-top: 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 400;
        font-size: 12px;
        color: #fff;
      }
      h3 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 600 !important;
        font-size: 18px !important;
        color: #d3d3d3;
        margin-bottom: 0px !important;
        margin-top: 4px;
      }
      .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        .lessons {
          display: flex;
          align-items: center;
          i {
            font-size: 24px;
            color: #fff;
            margin-right: 8px;
          }
          span {
            font-size: 14px;
            font-weight: 500;
            color: #fff;
          }
        }
        .button {
          display: flex;
          align-items: center;
          padding: 9.5px 13.5px;
          background-color: #df0043;
          border-radius: 100px;
          color: #fff;
          box-sizing: border-box;
          &:hover {
            background-color: #c7023d;
          }
          i {
            font-size: 21px;
          }
          span {
            font-size: 14px;
            font-weight: 600;
          }
        }
      }
      .description {
        font-size: 12px;
        line-height: 18px;
        color: rgba(255, 255, 255, 0.5);
        margin-top: 8px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  /*  @media only screen and (max-width: 600px) {
    .product-card-component {
      min-width: 200px !important;
      .info {
        padding: 10px;

        h3 {
          font-size: 16px;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        h3::before {
          display: none;
        }
        .bottom {
          .lessons {
            background: transparent;
            i {
              display: none;
            }
            span {
              font-size: 13px;
              color: #9a96ab;
            }
          }
        }
      }
    }
  } */
}

h2 {
  display: flex;
  align-items: center;
  font-weight: 600 !important;
  font-size: 27px !important;
  color: #ffffff;
  margin-bottom: 35px !important;
  font-family: 'Inter', 'Roboto', sans-serif;
}
