#page-profile {
  width: 100%;
  .page-profile-bg {
    position: relative;
    object-fit: fill;
    height: 500px;
    width: 100%;
    .profile-background {
      display: inline-block;
      width: 100%;
      height: inherit;
    }
    .profile-background-color {
      background-color: #0f1b35 !important;
      display: inline-block;
      width: 100%;
      height: inherit;
    }
    .eclipse {
      position: absolute;
      height: inherit;
      width: 100%;
      bottom: 0px;
      right: 0px;
      background: linear-gradient(to top, #121212 0%, transparent 40%);
    }
  }
  .page-profile-content {
    position: relative;
    z-index: 222;

    .title {
      display: flex;
      align-items: center;
      margin: 0;
      .avatar-image {
        border: 0;
        width: 73px;
      }
      div {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 51px;
        line-height: 56px;
        color: #ffffff;
        margin-left: 16px;
      }
    }

    form {
      background-color: #040915;
      padding: 24px;
      border-radius: 16px;
      .sub-title {
        display: flex;
        align-items: center;
        i {
          font-size: 25px;
          margin-right: 8px;
        }
        h2 {
          color: white;
          margin-bottom: 0;
        }
      }
      .input-list {
        color: #111;
        margin-top: 24px;
        .col,
        .col-md-12 {
          .form-group {
            margin-bottom: 24px;
            &:last-child {
              margin-bottom: 0 !important;
            }
            input {
              border: 0;
              padding: 0 16px;
              border-radius: 8px;
              background-color: #060d24 !important;
              &::placeholder {
                color: #4dd0bb;
              }
              &:hover {
                &::placeholder {
                  color: #a1f6e9;
                }
              }
            }
          }
        }
      }
    }
  }
}

.input-image {
  position: relative;
  margin-bottom: 16px;
  &.dragActive {
    z-index: 222;

    border-color: #4dd0bb;
  }
  &.input-image-error {
    border-color: #e9453a;
  }
  .alert {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .dragActiveMessage {
    z-index: 333;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #4dd0bb;
    background-color: #040915 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    i {
      margin-bottom: 8px;
      font-size: 50px;
    }
  }
  padding: 16px;
  display: flex;
  border: 2px dashed #47444e;
  &:hover {
    border: 2px dashed rgba(24, 24, 24, 0.45);
  }
  border-radius: 10px;
  .left {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 48px;
    }
  }
  .right {
    margin-left: 36px;
    h4 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      color: #ffffff;
    }
    p {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: rgba(255, 255, 255, 0.4);
      max-width: 274px;
    }
  }
  .input-default {
    z-index: 111;
    color: #4dd0bb !important;
    border: 2px solid #4dd0bb !important;
    cursor: pointer;
  }
  .btn {
    display: inline-block;
    border: 2px solid;
    border-radius: 100px;
    padding: 5px 16px;
    font-size: 13px !important;
    &:hover {
      background-color: #4dd0bb !important;
      color: #fff !important;
    }
  }
}

.image-photo {
  display: block;
  width: 100px;
  img {
    border-radius: 50%;
  }
}

@media screen and (max-width: 768px) {
  .row-info {
    & > .col-md-6:last-child {
      margin-top: 24px;
    }
  }
}

.css-b62m3t-container {
  margin-bottom: 16px;
  .css-qc6sy-singleValue {
    margin-left: 8px;
  }
  #react-select-2-input {
    display: flex;
    padding: 0 16px;
  }
  .css-1s2u09g-control,
  .css-1pahdxg-control {
    background-color: #060d24 !important;
    border: 0;
    .css-qc6sy-singleValue {
      color: #111;
    }
  }
}
.css-26l3qy-menu {
  display: block;
  background-color: #060d24 !important;
  .css-9gakcf-option {
    background-color: #4dd0bb !important;
  }
  .css-yt9ioa-option {
    background-color: #060d24 !important;
  }
  .css-1n7v3ny-option {
    background-color: #161d2d !important;
  }
}

@media screen and (max-width: 768px) {
  .page-profile-content {
    .title {
      div {
        font-size: 32px !important;
        line-height: 40px !important;
      }
    }
  }
}
