section#auth {
  background-color: red;
  .validating {
    margin-top: 30px;
    text-align: center;
    i {
      font-size: 30px;
    }
    span {
      display: block;
    }
  }

  .invalid {
    margin-top: 30px;
    b {
      font-size: 18px;
    }
  }
}

.access-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #121212;
  min-width: 100vw;
  min-height: 100vh;
  .wrap-container {
    display: flex;
    max-width: 1310px;
    padding: 15px;
    @media only screen and (max-width: 1046px) {
      flex-direction: column-reverse;
    }
    .wrap-right-image {
      border-radius: 24px;
      max-width: 700px;
      width: 100%;
      @media only screen and (max-width: 1230px) {
        max-width: 620px;
      }
    }
    .access-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 80px;
      max-width: 600px;
      @media only screen and (max-width: 1230px) {
        max-width: 400px;
        padding: 0 50px;
      }
      @media only screen and (max-width: 1046px) {
        margin: 0 auto;
        margin-top: 40px;
        margin-bottom: 50px;
      }
      @media only screen and (max-width: 600px) {
        padding: 15px;
      }

      .top {
        margin: 0 auto;
        text-align: center;
        img {
          width: 105px;
        }
        h2 {
          display: flex;
          justify-content: center;
          margin-top: 24px;
          color: #ffffff;
          font-family: 'Poppins';
          font-weight: 600;
          font-size: 30px;
        }
        span {
          font-family: 'Poppins';
          font-style: normal;
          font-size: 18px;
          color: #888888;
        }
        @media only screen and (max-width: 1340px) {
          h2 {
            font-size: 25px;
          }
          span {
            font-size: 16px;
          }
        }
      }

      form {
        label {
          margin-bottom: 14px;
          font-weight: 500;
          font-size: 12;
        }
        input {
          padding: 0 14px;
          background-color: #1e1e1e !important;
          border-radius: 8px;
          color: #fff;
          border: 1px solid #2c2c2c !important;
          font-size: 14px;
          font-weight: 400;

          margin-bottom: 16px;
          &::placeholder {
            font-size: 14px;
            font-weight: 400;
            color: #828282 !important;
          }
        }
      }
      .forgot-pass {
        display: flex;
        justify-content: flex-end;
        font-family: 'Poppins';
        font-size: 14px;
        font-weight: 600;
        color: #5bebd4;
        margin-top: 8px;
        margin-bottom: 24px;
        &:hover {
          color: #4bd6bf !important;
        }
      }
      .btn {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 16px 0;
        color: #354052;
        background-color: #5bebd4 !important;
        border-radius: 8px;
        &:hover {
          background-color: #4bd6bf !important;
        }
        span {
          margin: 0;
          display: block;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #354052;
        }
        i {
          margin: 0;
          margin-left: 10px;
          font-size: 21px;
        }
      }
    }
  }
  #poweredby {
    margin: 0 auto;
    margin-top: 80px;
    max-width: 158px;
    @media only screen and (max-width: 1046px) {
      display: none;
    }
  }
}

@media only screen and (max-width: 800px) {
  .access-card {
    h2 {
      font-size: 21px !important;
    }
    span {
      font-size: 14px !important;
    }
  }
}

.modal-access {
  .modal-dialog {
    max-width: 450px;
    .modal-content {
      background-color: #202020 !important;
      .modal-header {
        border-bottom: 1px solid #161d2d;
        h5 {
          color: #fff;
        }
      }
      .modal-body {
        padding: 16px !important;
        .alert {
          margin: 0 0 16px 0;
        }
        p {
          font-family: 'Poppins', 'sans-serif';
          font-size: 14px;
          line-height: 22px;
          font-weight: 500;
          letter-spacing: 0em;
          text-align: left;
          margin: 0;
        }
      }
    }
  }

  .forgot-input {
    height: 46px;
    padding: 0 14px;
    background-color: #1e1e1e !important;
    border-radius: 8px;
    border: 1px solid #2c2c2c !important;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 16px;
    color: #fff !important;
    &::placeholder {
      background-color: #1e1e1e !important;
      border-radius: 8px;
      border: 1px solid #2c2c2c !important;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 16px;
    }
  }
}
.forgot-mail {
  background-color: #5bebd4 !important;
  color: #354052 !important;
  width: 100%;
  margin: 0 !important;
  border-radius: 8px !important;
  font-family: 'Poppins', 'sans-serif' !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  padding: 16px !important;
  border: 0 !important;
}
