.bg-404 {
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-width: 100vw;
  background-color: #0e0b38;
  img {
    width: 139px;
  }
  h1 {
    margin-top: 39px;
    font-family: 'Poppins';
    font-weight: 600 !important;
    font-size: 28px;
    line-height: 42px;
    max-width: 470px;
    color: #fff;
  }
  p {
    margin-top: 10px;
    font-family: 'Poppins';
    font-weight: 400 !important;
    font-size: 17px;
    line-height: 26px;
    color: #fff;
    max-width: 434px;
  }
  .btn {
    padding: 17px 24px;
    border-radius: 100px;
    margin-top: 52px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    background-color: #fff;
    color: #4dd0bb;
    border: 0px;
    &:hover {
      background-color: #4dd0bb;
      color: #fff;
    }
  }
  .right {
    img {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .bg-404 {
    .left {
      margin: 0 auto;
      text-align: center;
      h1 {
        max-width: 350px;
        margin-left: auto;
        margin-right: auto;
        font-size: 21px;
        line-height: 35px;
      }
      p {
        font-size: 14px;
        margin-left: auto;
        margin-right: auto;
      }
      .btn {
        margin-top: 15px !important;
      }
    }
    .right {
      margin: 0 auto;
      margin-top: 50px;
      img {
        display: block;
        max-width: 80%;
        margin: 0 auto !important;
      }
    }
  }
}
