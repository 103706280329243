#subscription-page,
#page-activity,
#home-component {
  width: 100%;
  .page-subscription-bg {
    position: relative;
    object-fit: fill;
    height: 500px;
    width: 100%;
    .subscription-background {
      display: inline-block;
      width: 100%;
      height: inherit;
    }
    .subscription-background-color {
      background-color: #0f1b35 !important;
      display: inline-block;
      width: 100%;
      height: inherit;
    }
    .eclipse {
      position: absolute;
      height: inherit;
      width: 100%;
      bottom: 0px;
      right: 0px;
      background: linear-gradient(to top, #121212 0%, transparent 40%);
    }
  }

  .subscriptions {
    position: relative;
    z-index: 222;

    h2 {
      z-index: 223;
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 51px;
      line-height: 56px;
      color: #ffffff;
      margin-bottom: 55px;
    }
  }
}

#page-recurrences {
  table {
    th {
      border-top: 0;
      vertical-align: middle;
    }
    td {
      vertical-align: middle;
    }
  }
}
.form-error {
  color: #af4141;
  margin-bottom: 8px;
  line-height: 1rem;
  span {
    font-size: 12px;
  }
}
.is-invalid {
  border-color: #af4141;
}
#modal-style {
  color: #666 !important ;
  border-radius: 6px;
  .modal-content {
    background-color: #121212 !important;
    p {
      margin: 0;
      font-size: 16px;
      line-height: 21px;
    }

    box-shadow: 0px 9px 70px rgba(255, 255, 255, 0.12);
    .modal-header {
      border-bottom: 1px solid #161d2d;
      .modal-title {
        color: #fff;
      }

      .close {
        color: #fff !important;
        opacity: 1;
        text-shadow: 0 0 0;
        &:focus {
          outline: none;
        }
        &:hover {
          span {
            color: #4dd0bb !important;
          }
        }
      }
    }
    .modal-body {
      p {
        color: #fff;
      }
    }
  }
}

.profile-bg-container {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.profile-container {
  width: 100%;
}
.page-content-courses {
  margin-top: 0px !important;
}

.data-table {
  border-radius: 16px;
  background-color: #252134 !important;
  .top-bar {
    display: flex;
    .text {
      max-width: 589px;
      font-family: 'Poppins', 'sans-serif';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #b2e9e0;
    }
    .filter {
      display: flex;
      align-items: center;
      margin-left: auto;
      cursor: pointer;
      span {
        font-family: 'Poppins', 'sans-serif';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        color: #9e98eb;
        margin-right: 4px;
      }
      i {
        color: #9e98eb;
        font-size: 24px;
      }
    }
  }
  table {
    margin-top: 16px;
    td,
    th {
      padding-top: 16px;
      padding-bottom: 16px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #fff;
      border-top: 1px solid #252134;
    }
    thead {
      tr {
        background-color: #4dd0bb;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #ffffff;
        th {
          flex-wrap: nowrap;
          flex-shrink: 0;

          &:nth-child(2) {
            min-width: 150px;
          }
          &:nth-child(5) {
            min-width: 170px;
          }
          &:nth-child(7),
          &:nth-child(8) {
            min-width: min-content !important;
          }
          i {
            font-size: 24px !important;
            margin-right: 12px;
          }
          span {
            display: inline-block;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #fff;
          }
        }
        div {
          display: flex !important;
          align-items: center !important;
        }
      }
    }
    tbody {
      tr {
        td {
          min-width: 120px;
          background-color: #1b1827;
        }
      }
    }
  }
}

#BadgeDataTable {
  .light {
    padding: 5px 10px;
    border-radius: 100px;
    color: #7e7e7e;
    background-color: #dcdcdc;
  }
  .success {
    padding: 5px 10px;
    border-radius: 100px;
    color: #007f00;
    background-color: #b5f7b5;
  }
  .danger {
    padding: 5px 10px;
    border-radius: 100px;
    color: #d30000;
    background-color: #ffe0e0;
  }
  span {
    font-size: 14px;
    &:before {
      content: '';
      display: inline-block;
      margin-bottom: 2px;
      margin-right: 8px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: currentColor;
    }
  }
}
@media screen and (max-width: 768px) {
  .data-table {
    .top-bar {
      .text {
        font-size: 12px !important;
      }
    }
  }
}

.input-list {
  margin-top: 24px;

  .col,
  .col-md-12,
  .col-md-6 {
    .form-group {
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0 !important;
      }
      label {
        color: white;
      }
      input {
        border: 0;
        padding: 0 16px;
        border-radius: 8px;
        color: #fff;
        &::placeholder {
          color: #4dd0bb;
        }
        &:hover {
          &::placeholder {
            color: #a1f6e9;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  #modal-style {
    .modal-content {
      p {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
