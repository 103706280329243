#product-page {
  width: 100%;
  .page-product-bg {
    position: relative;
    object-fit: fill;
    height: 500px;
    width: 100%;
    .page-product-background {
      display: inline-block;
      width: 100%;
      height: inherit;
    }
    .eclipse {
      position: absolute;
      height: inherit;
      width: 100%;
      bottom: 0px;
      right: 0px;
      background: linear-gradient(to top, #121212 0%, transparent 40%);
    }
  }

  .products {
    position: relative;
    z-index: 222;

    &:first-child {
      margin-top: 0px;
    }
    h2 {
      z-index: 223;
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 51px;
      line-height: 56px;
      color: #ffffff;
      margin-bottom: 55px;
    }

    .list-products {
      display: grid;
      grid-template-columns: repeat(auto-fill, 290px);
      gap: 30px;
      .link-course {
        display: flex;
      }
    }
  }
}
.page-product-background-color {
  background-color: #0f1b35 !important;
  display: inline-block;
  width: 100%;
  height: inherit;
}

@media only screen and (max-width: 833px) {
  #product-page {
    .list-products {
      justify-content: center;
    }
  }
}
@media only screen and (max-width: 500px) {
  #product-page {
    .list-products {
      display: flex !important;
      flex-wrap: wrap;
      .item {
        width: 100%;
      }
    }
  }
}
