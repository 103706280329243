:root {
  --color-100: #c0c2c5;
  --color-200: #82848a;
  --color-300: #434750;
  --color-400: #dd0d0d;
  --color-500: #030710;
  --color-600: #02050b;
  --background-100: #02050b;
  --background-200: #02050b;
  --background-300: #02050b;
  --background-400: #02050b;
  --background-500: #02050b;
  --background-600: #02050b;
}
