#page-course {
  .course-cover {
    position: relative;
    object-fit: cover;
    height: 500px;
    width: 100%;
    .page-course-background {
      display: inline-block;
      width: 100%;
      height: inherit;
    }
    .page-course-background-color {
      background-color: #0f1b35 !important;
      display: inline-block;
      width: 100%;
      height: inherit;
    }
    img {
      display: inline-block;
      width: 100%;
    }
    .eclipse {
      position: absolute;
      height: inherit;
      width: 100%;
      bottom: 0px;
      right: 0px;
      background: linear-gradient(to top, #121212 0%, transparent 40%);
    }
    .play-course {
      .container {
        position: relative;
        .c-text {
          width: 100%;
          position: absolute;
          bottom: 120px;
          left: 0;
          padding: 0 15px;
          .title {
            font-weight: 500;
            font-size: 21px;
          }
        }
        .btn-primary {
          width: fit-content;
          margin-top: 24px;
          border-radius: 100px;
          padding: 8px 16px;
          display: flex;
          align-items: center;
          height: 40px;
          background-color: white;
          &:hover {
            background-color: #c2c4c4;
          }
          cursor: pointer;
          i {
            font-size: 21px;
            color: #040915;
            margin-right: 4px;
          }
          span {
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            color: #040915;
          }
        }
        .no-course {
          opacity: 0.7;
          cursor: not-allowed;
        }
      }
    }
  }
  .course-info {
    margin-top: 42px;
    h2 {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: #ffffff;
      margin-bottom: 26px;
    }

    .author-info {
      color: white;
      h4 {
        color: white;
      }
      .nickname {
        color: white !important;
      }
    }

    .uk-card-default {
      background-color: #040915;
      color: #fff;
      border-radius: 8px;
    }

    .course-curriculum {
      li {
        background-color: #040915;
        color: #fff;
        cursor: pointer;
        &::before {
          background-color: #4dd0bb;
        }
        a {
          background-color: #040915;
          color: #fff;
          &::before {
            color: #4dd0bb;
          }
        }
      }
    }
  }
  .list-products {
    display: flex;
    row-gap: 30px !important;
    column-gap: 30px !important;
    overflow-x: auto !important;
    padding-bottom: 24px;
    margin-bottom: 24px;
    .course-without-content {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 21px;
      width: 290px;
      height: 512px;
      padding: 0px 20px;
      border-radius: 8px;
      background-color: #0c1325;
      cursor: not-allowed;
    }
    .link-course {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-left: 32px;
      &:first-child {
        margin-left: 0;
      }
    }
    @media only screen and (max-width: 600px) {
      .list-products {
        row-gap: 0px !important;
        column-gap: 0px !important;
        a {
          margin-left: 16px !important;
        }
      }
    }
  }
}
