:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace;

  --color-text: #ffffff;
  --color-text-primary: #ffffff;
  --color-text-secondary: #000000;

  /*   --color-100-primary: #c0c2c5;
  --color-200-primary: #82848a;
  --color-300-primary: #434750;
  --color-400-primary: #040915;
  --color-500-primary: #030710;
  --color-600-primary: #02050b;
  --color-700-primary: #00040a; */

  /*   --color-100-secondary: #d3f3ee;
  --color-200-secondary: #a6e8dd;
  --color-300-secondary: #7adccc;
  --color-400-secondary: #4dd0bb;
  --color-500-secondary: #3a9c8c;
  --color-600-secondary: #27685e;
  --color-700-secondary: #13342f; */

  --color-100-primary: #353535;
  --color-200-primary: #2b2b2b;
  --color-300-primary: #212121;
  --color-400-primary: #171717;
  --color-500-primary: #121212;
  --color-600-primary: #0d0d0d;
  --color-700-primary: #080808;

  --color-100-secondary: #e94f7d;
  --color-200-secondary: #e6356a;
  --color-300-secondary: #e21a56;
  --color-400-secondary: #df0043;
  --color-500-secondary: #af0035;
  --color-600-secondary: #800026;
  --color-700-secondary: #500018;
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  transition: all 0.3s;
}

html,
span,
div {
  font-family: Muli, Poppins, Helvetica, sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  overflow-x: hidden;
  margin: 0;
  font-family: 'Poppins', arial, 'sans-serif', helvetica !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8;
  color: #fff;
  text-align: left;
  -webkit-text-size-adjust: 100%;
  background-color: #121212;
}

@media (max-width: 960px) {
  body {
    /* background: white; */
  }
}

[tabindex='-1']:focus {
  outline: 0 !important;
}

a,
.uk-link {
  color: #949393;
  text-decoration: none;
  cursor: pointer;
}

a:hover,
.uk-link:hover,
.uk-link-toggle:hover .uk-link,
.uk-link-toggle:focus .uk-link {
  color: #12111b;
  text-decoration: none;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  text-decoration: none;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp,
:not(pre) > code,
:not(pre) > kbd,
:not(pre) > samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace;
  font-size: 0.875rem;
  color: #f0506e;
  white-space: nowrap;
  padding: 2px 6px;
  background: #f8f8f8;
}

pre {
  color: #666;
  -moz-tab-size: 4;
  tab-size: 4;
  /* 1 */
  overflow: auto;
  -ms-overflow-style: scrollbar;
  line-height: 23px;
  padding: 13px;
  /* border: 1px solid #e5e5e5; */
  border-radius: 3px;
  background: #fff;
  font-size: 14px;
}

pre code {
  font-family: Consolas, monaco, monospace;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

/* Block elements
 ========================================================================== */
/*
 * Margins
 */
p,
ul,
ol,
dl,
pre,
address,
fieldset,
figure {
  margin: 0 0 10px 0;
}

/* Add margin if adjacent element */
* + p,
* + ul,
* + ol,
* + dl,
* + pre,
* + address,
* + fieldset,
* + figure {
  /* margin-top: 15px; */
}

/* Headings
  ========================================================================== */
h1,
.uk-h1,
h2,
.uk-h2,
h3,
.uk-h3,
h4,
.uk-h4,
h5,
.uk-h5,
h6,
.uk-h6,
.uk-heading-small,
.uk-heading-medium,
.uk-heading-large,
.uk-heading-xlarge,
.uk-heading-2xlarge {
  margin: 0 0 15px 0;
  font-weight: 600;
  color: #12111b;
  text-transform: capitalize;
}

/* Add margin if adjacent element */
* + h1,
* + .uk-h1,
* + h2,
* + .uk-h2,
* + h3,
* + .uk-h3,
* + h4,
* + .uk-h4,
* + h5,
* + .uk-h5,
* + h6,
* + .uk-h6,
* + .uk-heading-small,
* + .uk-heading-medium,
* + .uk-heading-large,
* + .uk-heading-xlarge,
* + .uk-heading-2xlarge {
  /* margin-top: 30px; */
}

/*
  * Sizes
  */
h1,
.uk-h1 {
  font-size: 21px;
  line-height: 1.2;
}

h2,
.uk-h2 {
  font-size: 19px;
  line-height: 1.3;
}

h3,
.uk-h3 {
  font-size: 17px;
  line-height: 1.4;
}

h4,
.uk-h4 {
  font-size: 16px;
  line-height: 1.4;
}

h5,
.uk-h5 {
  font-size: 16px;
  line-height: 1.5;
}

h6,
.uk-h6 {
  font-size: 14px;
  line-height: 1.4;
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  h1,
  .uk-h1 {
    font-size: 35px;
    font-weight: 600;
  }

  h2,
  .uk-h2 {
    font-size: 27px;
    font-weight: 600;
  }

  h3,
  .uk-h3 {
    font-size: 25px;
    line-height: 1.4;
  }

  h4,
  .uk-h4 {
    font-size: 21px;
    line-height: 1.4;
  }

  h5,
  .uk-h5 {
    font-size: 17px;
    line-height: 1.4;
  }

  h6,
  .uk-h6 {
    font-size: 15.5px;
    line-height: 1.4;
  }
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0 0 15px 0;
  font-weight: 600;
  color: #12111b;
  text-transform: capitalize;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-5 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: '\2014 \00A0';
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
  }
}

@media (min-width: 768px) {
  .container {
  }
}

@media (min-width: 992px) {
  .container {
  }
}

@media (min-width: 1200px) {
  .container {
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.code-example {
  max-height: 350px !important;
  margin-top: 20px;
}

/* ---------------------------------- */
/* Forms Reset & Styles
------------------------------------- */
input,
input[type='text'],
input[type='password'],
input[type='email'],
input[type='number'],
textarea,
select {
  height: 46px;
  line-height: 45px;
  padding: 0px 17px;
  outline: none;
  font-size: 14px;
  color: #808080;
  margin: 0 0 16px 0;
  border: 1px solid #e2e5ec;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  display: block;
  font-weight: 500;
  opacity: 1;
  border-radius: 4px;
  /* border: none; */
  /* box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.12); */
  /* background-color: #dee2e6; */
}

select {
  padding: 15px 18px;
  cursor: pointer;
}

input {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

input:focus,
input[type='text']:focus,
input[type='password']:focus,
input[type='email']:focus,
input[type='number']:focus,
textarea:focus {
  color: #808080;
  transition: box-shadow 0.2s !important;
  opacity: 1;
  border: none;
  box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.12);
}

/* With Border */
input.with-border,
input[type='text'].with-border,
input[type='password'].with-border,
input[type='email'].with-border,
input[type='number'].with-border,
textarea.with-border,
select.with-border {
  border: 1px solid #e0e0e0;
  box-shadow: none;
}

input.with-border:focus,
input[type='text'].with-border:focus,
input[type='password'].with-border:focus,
input[type='email'].with-border:focus,
input[type='number'].with-border:focus,
textarea.with-border:focus {
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.08);
}

input[type='submit'] {
  border: none;
  padding: 11px 18px;
  width: auto;
}

input[type='checkbox'] {
  display: inline;
}

input[type='radio'] {
  width: 15px;
  height: 15px;
  cursor: pointer;
  box-shadow: none;
}

/* Input Placeholder Color */
::placeholder {
  color: #808080;
  opacity: 1;
}

textarea {
  height: auto;
  line-height: 27px;
  padding: 20px;
  min-height: 130px;
  transition: none !important;
  min-width: 100%;
}

label,
legend {
  display: block;
  font-weight: bold;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 8px;
}

label span,
legend span {
  font-weight: normal;
  font-size: 14px;
  color: #444;
}

fieldset {
  padding: 0;
  border: none;
}

/* ---------------------------------- */
/* Input With Icon
------------------------------------- */
.input-with-icon {
  display: block;
  position: relative;
}

.input-with-icon input {
  padding-right: 45px;
}

.input-with-icon i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  font-size: 20px;
  color: #a0a0a0;
  font-style: normal;
  cursor: normal;
  pointer-events: none;
}

.input-with-icon i.currency {
  font-size: 14px;
}

/* Input with boxed icon */
.input-with-icon-left {
  position: relative;
}

.input-with-icon-left input {
  padding-left: 65px;
}

.input-with-icon-left i {
  position: absolute;
  top: 0;
  color: #a0a0a0;
  text-align: center;
  line-height: 48px;
  width: 48px;
  height: 48px;
  font-size: 19px;
  background-color: #f8f8f8;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  display: block;
  border-radius: 4px 0 0 4px;
}

.input-with-icon-left.no-border i {
  border: none;
}

/* Input with boxed icon */
.input-with-icon-right {
  position: relative;
}

.input-with-icon-right input {
  padding-right: 65px;
}

.input-with-icon-right i {
  position: absolute;
  top: 0;
  right: 0;
  color: #a0a0a0;
  text-align: center;
  line-height: 48px;
  width: 48px;
  height: 48px;
  font-size: 19px;
  background-color: #f8f8f8;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  display: block;
  border-radius: 0 4px 4px 0;
}

.input-with-icon-right.no-border i {
  border: none;
}

/* 
 ========================================================================
   Component: Container
 ========================================================================== */
/*
 * 1. Box sizing has to be `content-box` so the max-width is always the same and
 *    unaffected by the padding on different breakpoints. It's important for the size modifiers.
 */
.container {
  /* 1 */

  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 20px;
}

/* Phone landscape and bigger */
@media (min-width: 960px) {
  .container {
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 0px;
  }
}

/*
   * Micro clearfix
   */
.container::before,
.container::after {
  content: '';
  display: table;
}

.container::after {
  clear: both;
}

/*
   * Remove margin from the last-child
   */
.container > :last-child {
  margin-bottom: 0;
}

/*
   * Remove padding from nested containers
   */
.container .container {
  padding-left: 0;
  padding-right: 0;
}

/* Size modifier
   ========================================================================== */
.container-xsmall {
  margin-left: auto;
  margin-right: auto;
}

.container-small {
  margin-left: auto;
  margin-right: auto;
}

.container-large {
  margin-left: auto;
  margin-right: auto;
}

.container-expand {
  max-width: none;
  margin-left: auto;
  margin-right: auto;
}

/* Expand modifier
 ========================================================================== */
/*
* Expand one side only
*/
.container-expand-left {
  margin-left: 0;
}

.container-expand-right {
  margin-right: 0;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .container-expand-left.container-xsmall,
  .container-expand-right.container-xsmall {
    max-width: calc(50% + (750px / 2) - 30px);
  }

  .container-expand-left.container-small,
  .container-expand-right.container-small {
    max-width: calc(50% + (900px / 2) - 30px);
  }
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .container-expand-left,
  .container-expand-right {
    max-width: calc(50% + (1200px / 2) - 40px);
  }

  .container-expand-left.container-xsmall,
  .container-expand-right.container-xsmall {
    max-width: calc(50% + (750px / 2) - 40px);
  }

  .container-expand-left.container-small,
  .container-expand-right.container-small {
    max-width: calc(50% + (900px / 2) - 40px);
  }

  .container-expand-left.container-large,
  .container-expand-right.container-large {
    max-width: calc(50% + (1600px / 2) - 40px);
  }
}

/* ---------------------------------- */
/* section
------------------------------------- */

.section {
  box-sizing: border-box;
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
}

/* Desktop and bigger */
@media (min-width: 960px) {
  .section {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

/*
 * Micro clearfix
 */
.section::before,
.section::after {
  content: '';
  display: table;
}

.section::after {
  clear: both;
}

/*
 * Remove margin from the last-child
 */
.section > :last-child {
  margin-bottom: 0;
}

/* Size modifiers
 ========================================================================== */
/*
* Small
*/
.section-small {
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
}

/*
* Large
*/
.section-large {
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
}

/*
* XLarge
*/
.section-xlarge {
  padding-top: 140px;
  padding-bottom: 140px;
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .section-small {
    padding-top: 30px;
    padding-bottom: 30px;
    position: relative;
  }

  .section-xlarge {
    padding-top: 210px;
    padding-bottom: 210px;
  }

  .section-large {
    padding-top: 140px;
    padding-bottom: 140px;
  }
}

/* ---------------------------------- */
/* section-header
------------------------------------- */

.section-header {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;
  align-items: flex-end;
}

.section-header-left {
  flex: 1;
  min-width: 1px;
}

.section-header-left > * {
  margin-bottom: 0;
  color: #12111b;
}

.section-header-right {
  width: auto;
  align-items: center;
  display: flex;
  justify-content: center;
}

.section-header-right > * {
  margin-left: 10px;
}

.section-header-right a.see-all {
  position: relative;
  padding-right: 23px;
  color: #9799b1;
  line-height: 13px;
  font-size: 14px;
}

.section-header-right a.see-all:hover {
  color: #12111b;
}

.section-header-right a.see-all::after {
  font-family: 'Feather-Icons';
  content: '\e930';
  font-size: 18px;
  right: 0;
  position: absolute;
}

/* ---------------------------------- */
/* home slider
------------------------------------- */

.home-hero {
  padding: 105px 0;
  position: relative;
  background-size: cover;
  height: 530px;
  background-position: 0px -175px;
  align-items: center;
  justify-content: center;
  display: flex;
  align-items: center;
}

.home-hero:before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  /* z-index: 0; */
  /* background: linear-gradient(45deg, #36426b, transparent); */
}

/* Tablet landscape and smaller */
@media (max-width: 768px) {
  .section-header {
    /* display:block; */
    margin-bottom: 10px;
    padding-bottom: 13px;
    align-items: center;
  }

  .section-header-title > * {
    font-size: 17px;
    line-height: 1;
  }

  .section-header-link a {
    font-size: 14px;
    bottom: 0;
  }
}

/* ---------------------------------- */
/* header
------------------------------------- */

/* .header {
	top: 0;
	z-index: 1000;
	padding: 0;
	height: 70px;
	display: flex;
	align-items: center;
	transition: 0.3s;
	padding-left: 70px !important;
}

.page-menu ~ .header {
	margin-left: 250px;
	padding-left: 0px !important;
	width: calc(100% - 250px);
}

.page-menu ~ .header .container {
	padding: 30px;
}

.page-menu.menu-large ~ .header {
	margin-left: 70px;
	width: calc(100% - 70px);
}
*/

.header.header-transparent {
  position: absolute;
  width: 100%;
}

.header.header-horizontal {
  width: 100% !important;
  padding-left: 0 !important;
  background-color: #fff;
  box-shadow: 0px 2px 6px 0px #00000017;
}

.header.header-horizontal .logo {
  display: flex !important;
}

.header-sticky {
  -webkit-box-shadow: 0 0.5rem 0.5rem -0.5rem rgba(0, 0, 0, 0.2) !important;
  box-shadow: 0 0.5rem 0.5rem -0.5rem rgba(0, 0, 0, 0.2) !important;
  background: rgba(255, 255, 255, 0.96);
  position: fixed;
  z-index: 1000;
}
/*
.header .container {
	padding: 0px 16px;
	width: 100%;
}

.header .uk-navbar {
	align-items: center;
}

.header .logo {
	display: none;
	align-items: center;
}

.header .logo img {
	width: 30px;
}

.header .logo span {
	font-size: 19px;
	margin-left: 10px;
	display: none;
}

.page-content .header {
	padding-left: 0 !important;
}


.admin-panel .header {
	background: #12111b !important;
	position: fixed;
}
*/
.admin-panel .page-menu .btn-menu-trigger {
  left: 20px;
}

.admin-panel .page-menu.menu-large .btn-menu-trigger {
  left: 206px;
}

.admin-panel .page-menu .logo {
  background: #12111b;
  height: 70px;
  border-bottom: 0;
}
.admin-panel .page-menu .logo span {
  font-size: 18px;
  font-weight: 800;
  background-image: linear-gradient(
    45deg,
    #b2b5ee 0,
    #d9dbfd 35%,
    #a7b9da 60%,
    #6d74ff 100%
  ) !important;
}
.admin-panel .page-menu.menu-large .logo span {
  background-image: linear-gradient(
    45deg,
    #b2b5ee 0,
    #b2b5ee 35%,
    #a7b9da 60%,
    #6d74ff 100%
  ) !important;
}
.admin-panel .searchbox {
  border-radius: 4px !important;
  background: #ffffff2e !important;
  border-color: transparent !important;
  min-width: 426px;
  padding: 5px 18px;
}
.admin-panel .searchbox .uk-search-input {
  padding: 0px 0px !important;
}
.admin-panel .dropdown-search {
  min-width: 426px;
}
.admin-panel .dropdown-user-details {
  padding: 0px 16px;
  border: 0;
  padding-top: 10px;
}
.admin-panel .dropdown-user-details .dropdown-user-name span {
  color: #ffffffb3;
  font-size: 11.7px;
}
.admin-panel .dropdown-user-details .dropdown-user-name {
  color: white;
  padding-bottom: 0;
}
/* arrow indicator */
.admin-panel .dropdown-user-details::before {
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: 'Feather-Icons';
  content: '\e92e';
  right: 0;
  position: absolute;
}
.admin-panel .dropdown-user-details.uk-open::before {
  transform: rotate(180deg);
  background-image: none !important;
}
@media (min-width: 1220px) {
  .admin-panel .page-menu {
    background: transparent;
    box-shadow: none;
  }

  .admin-panel .page-content {
    padding-top: 70px;
  }
}

.admin-panel .page-menu.menu-large {
  background: white;
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.04);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.04);
  z-index: 1001;
}

.admin-panel .page-menu .page-menu-inner {
  overflow-y: auto;
  height: calc(100% - 70px);
}
/* 
.admin-panel .header .header-widget {
	background: #12111b;
}
*/
.admin-panel .header.uk-light .header-widget .header-widget-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}
/* 
@media (max-width: 960px) {
	.header .logo span {
		display: inline;
	}
}

@media (max-width: 1220px) {
	.header {
		height: 60px;
		padding-left: 0 !important;
		background-color: #fff;
		position: fixed;
		box-shadow: 0px 2px 6px 0px #00000017;
		width: 100% !important;
		margin-left: 0 !important;
	}

	.header .header-widget {
		position: absolute;
		right: 0;
		background: #ffffff;
	}

	.header .logo {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		position: absolute;
	}
}
 */
.display-as {
  border-radius: 7px;
  display: flex;
  background: #e0e0e0;
}

.display-as a {
  padding: 10px 11px;
  font-size: 16px;
  display: inline-block;
  line-height: 1;
  color: #040404;
}

.display-as a.active {
  background: #efefef;
  color: #ffffff;
  border-radius: 5px;
  cursor: auto;
  background: #12111b;
  box-shadow: 0px 2px 8px -6px rgba(0, 0, 0, 0.3);
}

@media (max-width: 640px) {
  .display-as a {
    padding: 7px 8px;
    font-size: 16px;
  }
}

/* ---------------------------------- */
/* layouts
------------------------------------- */

.page-content {
  display: block;
  position: relative;
}

.page-content-inner {
  max-width: 1150px;
  margin: auto;
  padding: 30px !important;
  position: relative;
}

.side-nav ~ .page-content {
  margin-left: 70px;
}

.header-horizontal ~ .page-content {
  margin-top: 70px;
}

@media (max-width: 1220px) {
  .header-horizontal ~ .page-content {
    /* margin-top: 40px; */
  }
}

/* ---------------------------------- */
/*  Page Menu
------------------------------------- */

.page-menu {
  width: 250px;
  height: 100%;
  background: #ffffff;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 100;
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.04);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.04);
  transition: all 300ms 0s ease-in-out;
}

.page-menu .page-menu-inner {
  overflow-y: auto;
  height: calc(100% - 40px);
}

.page-menu .simplebar-scrollbar {
  width: 5px;
}

.page-menu .simplebar-track.simplebar-vertical:hover {
  width: 17px !important;
}

.page-menu ~ .page-content {
  margin-left: 250px;
}

.page-menu.menu-large ~ .page-content {
  margin-left: 80px;
}

.page-menu .logo {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  padding: 17.5px 30px;
  background-color: transparent;
  /* padding-bottom: 0; */
  border-bottom: 1px solid #f2f2f2;
}

.page-menu .logo i {
  font-size: 28px;
  color: #12111b;
}

.page-menu .logo span {
  font-size: 18px;
  font-weight: 800;
  background-image: linear-gradient(
    45deg,
    #12111b 0,
    #7f85ab 35%,
    #a7b9da 60%,
    #b2b5ee 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  color: #12111b !important;
  padding-left: 10px !important;
}

.page-menu .logo img {
  width: 33px;
}

/* ---------------------------------- */
/* Page menu Navigation  
------------------------------------- */
.page-menu ul {
  list-style: none;
  padding: 0;
  margin: 21px 0 0 0;
  padding-top: 23px;
}

.page-menu ul:before {
  content: attr(data-submenu-title);
  padding: 0 35px;
  margin-top: -25px;
  display: block;
  color: #66676b;
  font-weight: 600;
  font-size: 14px;
}

/* 1st Level */
.page-menu ul li {
  display: block;
  transition: 0.3s;
  line-height: 25px;
  font-size: 15px;
}

.page-menu ul li a {
  color: #707070;
  display: flex;
  transition: 0.3s;
  cursor: pointer;
  position: relative;
  padding: 13px 32px;
}

.page-menu ul li a i {
  margin-right: 20px;
  width: 20px;
  font-size: 22px;
  color: #909090;
  transition: 0.3s;
  position: relative;
  top: 2px;
  display: inline-block;
}

.page-menu ul li.active-submenu a i,
.page-menu ul li.active a i,
.page-menu ul li:hover a i {
  color: #66676b;
}

.page-menu ul li.active-submenu,
.page-menu ul li.active,
.page-menu ul li:hover {
  background-color: rgba(102, 103, 107, 0.04);
  width: 100%;
}

.page-menu ul li.active-submenu a,
.page-menu ul li:hover a,
.page-menu ul li.active a {
  color: #66676b;
}

/* Dropdown Styles */
.page-menu ul li ul {
  padding: 0;
  margin: 0;
  visibility: hidden;
  max-height: 0;
  opacity: 0;
  line-height: 0;
  transition: 0.3s;
  pointer-events: none;
  display: none;
}

.page-menu ul li.active-submenu ul {
  visibility: visible;
  padding: 0px 0 13px 0;
  line-height: 24px;
  opacity: 1;
  max-height: 200px;
  pointer-events: all;
  margin-top: 0 !important;
  padding-top: 0 !important;
  display: inline-block;
}

.page-menu ul li ul li {
  background-color: transparent;
  border: none;
  padding-left: 63px;
  position: relative;
  margin: 0;
}

.page-menu ul li ul li a {
  padding: 9px 0 !important;
}

.page-menu ul li ul li:hover {
  border: none;
  background-color: transparent;
}

/* Indicator Arrow */
.page-menu ul li a:after {
  font-family: 'Feather-Icons';
  content: '\e92e';
  opacity: 1;
  font-size: 16px;
  transition: 0.3s;
  opacity: 1;
  line-height: 17px;
  width: 17px;
  height: 17px;
  color: #a8a8a8;
  display: inline-block;
  border-radius: 3px;
  margin-left: 7px;
  text-indent: 0;
  top: 1px;
  position: absolute;
  right: 35px;
  top: 15px;
}

.page-menu ul li.active-submenu a:after {
  content: '\e931';
}

.page-menu ul li.active-submenu a:after,
.page-menu ul li.active a:after,
.page-menu ul li:hover a:after {
  color: #66676b;
  opacity: 0.8;
}

.page-menu ul li ul:before,
.page-menu ul li a:only-child:after {
  content: '';
  display: none;
}

.page-menu ul li ul li a:after {
  display: none !important;
}

/* menu toggle btn */
.btn-menu-trigger {
  width: 40px;
  height: 40px;
  position: relative;
}

.btn-menu-trigger {
  display: inline;
  left: 206px;
  position: absolute;
  border-radius: 100%;
  margin-top: 13px;
  color: #929292;
  z-index: 10;
  cursor: pointer;
}

.btn-menu-trigger::before {
  font-family: 'unicons';
  font-size: 23px;
  content: '\ebf0';
  line-height: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
.admin-panel .page-menu .btn-menu-trigger::before {
  content: '\e872';
  color: white;
}
.admin-panel .page-menu.menu-large .btn-menu-trigger::before {
  content: '\ebf1';
  color: #929292;
}

/* only large screen */

@media (min-width: 1220px) {
  .page-menu.menu-large {
    width: 80px;
    overflow: hidden;
  }

  .page-menu.menu-large:hover {
    width: 250px;
    transition: all 300ms 0s ease-in-out;
    z-index: 10001;
  }

  .page-menu.menu-large .logo {
    padding: 17.5px 30px;
    background-color: #f9f9f9;
  }

  .page-menu.menu-large:hover .logo {
    padding: 14px 30px;
    /* background-color: transparent; */
  }

  .page-menu.menu-large:hover .logo a span {
    padding-left: 14px !important;
  }

  .page-menu.menu-large ul li a {
    padding: 17px 30px;
  }

  .page-menu.menu-large:hover ul li a {
    justify-content: initial !important;
  }

  .page-menu.menu-large:hover ul li a i {
    font-size: 20px;
  }

  .page-menu.menu-large ul:before,
  .page-menu.menu-large ul li a span,
  .page-menu.menu-large .logo a span {
    position: absolute;
    padding-left: 30px;
  }

  .page-menu.menu-large ul:before,
  .page-menu.menu-large ul li a span,
  .page-menu.menu-large ul li a:after,
  .page-menu.menu-large .logo a span,
  .page-menu.menu-large .btn-menu-trigger,
  .page-menu.menu-large li.active-submenu {
    display: none;
  }

  .page-menu.menu-large:hover ul:before,
  .page-menu.menu-large:hover ul li a span,
  .page-menu.menu-large:hover.page-menu ul li a:after,
  .page-menu.menu-large:hover .logo a span,
  .page-menu.menu-large:hover .btn-menu-trigger,
  .page-menu.menu-large:hover li.active-submenu {
    display: inline-block;
  }

  .page-menu.menu-large:hover ul * li * a {
    padding: 11px 32px;
    justify-content: flex-start;
    align-items: initial;
  }

  .page-menu.menu-large ul {
    margin-top: 0;
    padding-top: 0;
  }

  .page-menu.menu-large:hover ul {
    margin: 21px 0 0 0;
    padding-top: 23px;
  }
}

@media (max-width: 1220px) {
  .page-menu {
    margin-left: -250px;
  }

  .mobile-active .page-menu {
    margin-left: 0px;
    box-shadow: 1px 1px 0px 1000px #0000003b;
    z-index: 1001;
  }

  .btn-menu-close {
    width: 40px;
    height: 40px;
    position: relative;
  }

  .mobile-active .btn-menu-close {
    display: inline;
    left: 232px;
    position: absolute;
    background: #f3f3f3;
    border-radius: 100%;
    margin-top: 7px;
  }

  .btn-menu-close::before {
    font-family: 'Feather-Icons';
    font-size: 22px;
    content: '\e92f';
    line-height: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }

  .btn-menu-trigger {
    display: none;
  }
}

/* ---------------------------------- */
/* Side Nav 
------------------------------------- */

.side-nav .side-nav-bg {
  width: 70px;
  height: 100%;
  background: #ffffff;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1;
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.04);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.04);
}

.side-nav {
  position: relative;
  height: 100%;
}

.side-nav {
  position: fixed;
  width: 70px;
  height: 100%;
  left: 0;
  top: 0;
  background: #ffffff;
  z-index: 999999;
  transition: all 300ms 0s cubic-bezier(0.24, 0.49, 0.61, 0.31);
  /* -webkit-box-shadow: 0 5px 15px rgba(0,0,0,.04); */
  /* box-shadow: 0 5px 15px rgba(0,0,0,.04); */
}

.side-nav .logo {
  padding: 18.5px 0;
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f9f9f9;
}

.side-nav .logo i {
  font-size: 28px;
  opacity: 1;
  color: #12111b;
}

.side-nav .logo img {
  width: 33px;
}

.side-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.side-nav ul li {
  display: block;
}

.side-nav > ul > li > a {
  display: block;
  position: relative;
  z-index: 1000;
  color: #12111b;
  padding: 10px 0px;
  font-size: 19px;
  margin: 17px 15px;
  display: flex;
  justify-content: center;
  border-radius: 12px;
}

.side-nav ul li .side-menu-slide {
  position: fixed;
  height: 100%;
  width: 300px;
  left: -300px;
  top: 0;
  padding-left: 70px;
  display: block;
  background: #fff;
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  box-shadow: 8px 22px 23px rgba(0, 0, 0, 0.08);
  -webkit-transition: all 0.34s ease-in;
  transition: all 0.34s ease-in;
}

.side-nav ul li .side-menu-slide ul li a {
  width: 100%;
  padding: 16px 14px;
  padding-left: 26px;
  color: #83839c;
  margin: 0;
  font-weight: 500;
  text-align: left;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: auto;
  border-bottom: 1px solid #f7f7fc;
  font-size: 15px;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.side-nav ul li:hover .side-menu-slide {
  left: 0;
  -webkit-transition: all 0.34s ease-out;
  transition: all 0.34s ease-out;
}

.side-nav ul li .side-menu-slide ul li a:hover {
  color: #007791;
  padding-left: 34px;
  background: rgba(66, 133, 244, 0.04);
}

.side-nav ul li .side-menu-slide ul li a i {
  margin-right: 9px;
  margin-left: -1px;
}

.side-nav ul li .side-menu-slide ul li a:hover:after {
  opacity: 1;
  right: 20px;
}

.side-nav ul li .side-menu-slide .side-menu-slide-content {
  left: -300px;
  height: 100vh;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.side-nav .tooltips {
  position: absolute;
  left: 100%;
  display: inline-block;
  line-height: 26px;
  margin-left: 25px;
  background: #12111b;
  color: #fff;
  padding: 0px 10px;
  border-radius: 6px;
  font-size: 11px;
  white-space: nowrap;
  opacity: 0;
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  -webkit-transform-origin: left center;
  transform-origin: left center;
  pointer-events: none;
  -webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
  transition: opacity 0.2s, transform 0.2s, -webkit-transform 0.2s;
}

.side-nav ul li a:hover .tooltips {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.side-nav .tooltips:before {
  position: absolute;
  top: 50%;
  left: -3px;
  margin-top: -3px;
  content: '';
  display: inline-block;
  border-top: 3px solid transparent;
  border-right: 3px solid #12111b;
  border-bottom: 3px solid transparent;
}

/* chenge to white color if .uk-light */
.uk-light.side-nav .logo i,
.uk-light.side-nav ul li a {
  color: white;
}

/* simplebar scrollbar */

.side-menu-slide-content .simplebar-vertical .simplebar-scrollbar:before {
  background: #b6babf;
}

/* accordion */
.side-menu-slide-content .uk-accordion li {
  margin-top: 0;
}

.side-menu-slide-content .uk-accordion-content {
  border-right: 5px solid #9ac9d5;
  margin-top: 0;
}

.side-menu-slide-content .uk-accordion-title::after {
  margin-right: 12px;
}

.side-menu-slide-content .uk-accordion-title::before {
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: 'Feather-Icons';
  content: '\e930';
  background-image: none !important;
  color: rgba(0, 0, 0, 0.54);
}

.side-nav .uk-drop {
  width: 250px;
  margin-left: 11px;
}

@media (max-width: 1220px) {
  .page-content {
    margin-left: -0px !important;
  }

  .page-content-inner {
    padding: 15px;
  }

  .side-nav {
    margin-left: -70px;
  }

  .mobile-active .side-nav {
    margin-left: 0;
  }

  .side-overly {
    position: fixed;
    display: none;
    width: 100%;
    top: 0;
    z-index: 1;
    height: 100%;
    background: rgba(0, 0, 0, 0.25);
  }

  .mobile-active .side-overly {
    display: inline;
  }

  .logo .logo-flip {
    display: none;
  }

  /* tragger */
  .btn-mobile {
    border-radius: 5px;
    background: #ffffff;
    width: 40px;
    height: 40px;
    position: relative;
  }

  .btn-mobile::before {
    font-family: 'Feather-Icons';
    font-size: 22px;
    content: '\e98f';
    line-height: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }

  .mobile-active .btn-mobile::before {
    content: '\ea02';
  }

  .mobile-active .btn-mobile {
    /* position: absolute; */
    z-index: 100;
    left: 70px;
    border-radius: 100%;
    background: #f3f5f8;
  }

  .mobile-active.menu-small .btn-mobile,
  .mobile-active.menu-expand .btn-mobile {
    left: 70px;
  }

  .mobile-active.menu-expand-active .btn-mobile {
    left: 250px;
  }
}
.header-widget {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-widget .header-widget-icon {
  align-items: center;
  border: 1px solid transparent;
  font-weight: 400;
  white-space: nowrap;
  margin-left: 6px;
  position: relative;
  border-radius: 50%;
  color: #12111b;
}

.header.header-horizontal .header-widget-icon {
  color: #b0b2be;
}

.uk-light .header-widget .header-widget-icon {
  color: rgba(255, 255, 255, 0.7);
}

.uk-light .header-widget .header-widget-icon:hover {
  color: white !important;
}

.header-widget .header-widget-icon i {
  position: relative;
  text-align: center;
  flex: 1;
  font-size: 28px;
  padding: 0 !important;
  display: block;
}

.header-widget .header-widget-icon span {
  position: absolute !important;
  display: block;
  top: 2px;
  margin-left: -10px;
  font-weight: 700;
  height: 16px;
  width: 16px;
  line-height: 15px;
  text-align: center;
  color: #fff;
  font-size: 10px;
  background-color: #ec5252;
  border-radius: 50%;
  padding: 0;
  text-align: center;
  right: 3px;
  top: 3px;
}

.header-widget .header-widget-icon:not(.profile-icon):hover,
.header-widget .header-widget-icon:not(.profile-icon):focus {
  color: #343767;
}

.header-widget .header-widget-icon.btn-my-courses {
  border-radius: 5px;
  padding: 6px 11px;
  font-weight: 600;
  color: #b0b2be;
}

@media (min-width: 640px) and (max-width: 1220px) {
  /*
	.header .header-widget {
		top: -4px;
	}
*/
}
@media (max-width: 640px) {
  .is-active.header-widget {
    display: inline-flex;
  }

  .header-widget .header-widget-icon {
    margin-left: 0px;
    padding-top: 14px;
    color: #343767 !important;
  }

  .header-widget .header-widget-icon i {
    font-size: 22px;
  }
  /*
	.header .header-widget-icon {
		color: white !important;
	}
*/
  .header-profile-icon {
    width: 35px !important;
  }

  .header-widget .header-widget-icon.btn-my-courses {
    display: none !important;
  }
}

@media (max-width: 1220px) {
  .header-widget .header-widget-icon,
  .btn-mobile,
  .header.uk-light .uk-navbar-toggle,
  .header.uk-light .uk-navbar a {
    color: #343767 !important;
  }
}

/* ---------------------------------- */
/* Dropdown Notification box on header
------------------------------------- */

.dropdown-notifications {
  width: 355px;
  border-radius: 4px;
  padding: 0 !important;
}

.dropdown-notifications.large {
  width: 420px;
}

.dropdown-notifications.small {
  width: 230px;
}

.header-widget .dropdown-notifications:before {
  content: '';
  position: absolute;
  right: 8px;
  top: -13px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 13px solid #e8e9eb;
  border-color: transparent transparent #eaeaea transparent;
  top: -13px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 13px solid #e8e9eb;
  border-color: transparent transparent #eaeaea transparent;
}

.dropdown-notifications-headline {
  padding: 12px 20px;
  line-height: 24px;
  border-bottom: 1px solid #e6e6e6;
  color: #29303b;
}

.dropdown-notifications-headline h4 {
  display: inline-block;
  margin-bottom: 0;
  font-size: 14.4px;
  font-weight: 600;
  color: #666 !important;
}

.dropdown-notifications-headline a {
  float: right;
  position: relative;
  border-radius: 4px;
  height: 32px;
  text-align: center;
  line-height: 36px;
  top: -4px;
  right: -8px;
  color: #888;
  transition: 0.25s;
  outline: 0;
  border: 0;
  font-size: 16px;
  background: transparent !important;
}

button.mark-as-read:hover {
  background-color: #f2f2f2 !important;
  color: #888;
}

.dropdown-notifications-content {
  display: block;
  width: 100%;
  height: 315px !important;
}

.dropdown-notifications-content ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.dropdown-notifications-content ul li {
  border-bottom: 1px solid #f3f3f3;
  transition: 0.25s;
  margin-right: 0;
  position: relative;
}

.dropdown-notifications-content ul li:last-child {
  border-bottom: 1px solid transparent;
}

.dropdown-notifications-content ul li:hover,
.notifications-not-read {
  background-color: #f7f8fa;
}

.dropdown-notifications-content ul li a {
  display: flex !important;
  flex-wrap: wrap;
  color: #666 !important;
  position: relative;
  padding: 16px 20px;
}

.dropdown-notifications-content .notification-image {
  height: auto;
  max-width: 105px;
  display: inline-block;
  text-align: center;
  color: #a0a0a0;
  transition: 0.25s;
  position: relative;
}

.dropdown-notifications-content .notification-image img {
  border-radius: 5px;
}

.dropdown-notifications-content .notification-image::before {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: 62px;
  top: 0;
  z-index: 1000000;
  background: url(../images/icon-play.svg) no-repeat;
  background-size: auto 50%;
  background-position: center;
}

.dropdown-notifications-content .notification-image,
.dropdown-notifications-content .notification-text {
  flex: 1;
}

.notification-text,
.dropdown-notifications-content .notification-text {
  padding-left: 16px;
  padding-right: 5px;
  display: inline-block;
  line-height: 23px;
  font-size: 13.7px;
}

.dropdown-notifications-content .notification-text .time-ago {
  font-size: 12.7px;
  color: #a0a0a0;
}

.dropdown-notifications-content
  .notification-text.notification-msg-text
  .time-ago {
  position: absolute;
  top: 14px;
  display: inline-block;
  right: 16px;
  font-size: 11.5px;
}

.dropdown-notifications-content .notification-avatar {
  height: 42px;
  width: 42px;
  max-width: 42px;
  display: inline-block;
  border-radius: 50%;
  overflow: visible;
  align-self: flex-start;
}

.dropdown-notifications-content .notification-icon {
  font-size: 22px;
  height: 37px;
  width: 37px;
  padding: 0;
  max-width: 40px;
  display: inline-block;
  background-color: #f2f2f2;
  border-radius: 10px;
  line-height: 42px;
  text-align: center;
  color: white;
  transition: 0.25s;
  position: relative;
}

.dropdown-notifications-content .notification-icon i {
  position: relative;
}

.dropdown-notifications-content .notification-avatar img {
  width: 100%;
  image-rendering: -webkit-optimize-contrast;
  border-radius: 50%;
}

.dropdown-notifications-content .notification-msg-text p {
  display: inline-block;
  line-height: 23px;
  font-size: 14px;
  margin: 4px 0;
}

.dropdown-notifications-footer {
  background: #f7f8fa;
  height: 51px;
  display: flex;
  justify-content: center;
  border-top: 1px solid #ebeef3;
  border-radius: 0 0 4px 4px;
}

.dropdown-notifications-footer a {
  color: #007791 !important;
  display: block;
  font-size: 13px;
  font-weight: 600;
  padding: 16px 15px;
  width: 100%;
  text-align: center;
}

/* customize scrollbar */
.dropdown-notifications-content .simplebar-scrollbar {
  right: 4px !important;
}

.dropdown-notifications-content .simplebar-scrollbar:before {
  background-color: #d0d0d0;
}

/* my course dropdown */
.my-courses-dropdown:before {
  display: none;
}

.my-courses-dropdown .course-title {
  font-weight: bolder;
  display: block;
}

.my-courses-dropdown .course-number {
  font-size: 13px;
  letter-spacing: 2px;
}

.my-courses-dropdown .course-instructor {
  color: #929292;
  font-size: 13px;
}

.my-courses-dropdown .course-progressbar {
  margin-top: 3px;
}

/* header search box */
.searchbox {
  padding: 6px 19px;
  width: 100%;
  margin-right: 20px;
  min-width: 220px;
  background: #f2f3f5;
  border-radius: 36px;
  position: relative;
}

.uk-light .searchbox {
  background-color: rgba(255, 255, 255, 0.0784313725490196);
  border: 2px solid rgba(255, 255, 255, 0.15);
}

.searchbox .uk-search-input {
  background: rgb(243, 243, 243) !important;
  background: transparent !important;
  font-weight: 600;
  height: auto;
}

.searchbox .btn-searchbox {
  position: absolute;
  right: 8px;
  border: 0;
  top: 4px;
  background: transparent;
}

.searchbox .btn-searchbox::after {
  font-family: 'Feather-Icons';
  position: absolute;
  text-align: center;
  content: '\e9c1';
  color: darkgrey;
  z-index: 2;
  line-height: 29px;
  right: 10px;
  font-weight: 700;
  font-size: 18px;
}

.dropdown-search {
  border-radius: 4px;
  padding: 0 !important;
  width: 280px !important;
  margin-left: -22px;
}

/* dropdown-search Nav */

ul.dropdown-search-list .list-title {
  transition: 0.3s;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.3px;
  color: #9799a2;
  padding: 7px 14px;
  background: #f5f6f7;
  border-bottom: 1px solid #e9eaed;
}

ul.dropdown-search-list {
  list-style: none;
  padding: 0;
  margin: 0;
  padding-bottom: 6px;
}

ul.dropdown-search-list li {
  margin: 0;
  padding: 0;
  display: block !important;
}

ul.dropdown-search-list li a {
  display: block;
  font-size: 13px;
  transition: 0.3s;
  color: #9799a2;
  padding: 7px 18px;
  /* font-weight: 600; */
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

ul.dropdown-search-list li a:hover {
  background-color: #f0f5fd;
  color: #2f3152;
}

ul.dropdown-search-list .menu-divider {
  margin: 4px 0;
  border: 0.3px solid rgba(222, 223, 224, 0.45);
}

/* header profile icon */
.profile-icon {
  padding: 0 !important;
  margin-left: 14px !important;
}

.header-profile-icon {
  width: 32px;
  padding: 0 !important;
  border-radius: 100%;
  position: relative;
}

/* for horizontal layout */
.header-horizontal .header-profile-icon {
  width: 56px;
}

/* User Menu Small Nav */
ul.dropdown-user-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  padding: 6px 0px;
}

ul.dropdown-user-menu li {
  margin: 0;
  padding: 0;
  display: block !important;
}

ul.dropdown-user-menu li a {
  display: block;
  font-size: 13px;
  transition: 0.3s;
  color: #9799a2;
  padding: 7px 17px;
  font-weight: 600;
}

ul.dropdown-user-menu li a:hover {
  background-color: #f0f5fd;
  color: #2f3152;
}

ul.dropdown-user-menu li a i {
  font-size: 19px;
  position: relative;
  top: 1px;
  margin-right: 7px;
  -webkit-font-smoothing: antialiased;
  transition: 0.3s;
}

ul.dropdown-user-menu .menu-divider {
  padding: 0px;
}

/* dropdown User Details */
.dropdown-user-details {
  display: flex;
  padding: 21px 16px;
  padding-bottom: 9px;
  border-bottom: 1px solid #e6e6e6;
}

/* Avatar */
.dropdown-user-avatar {
  display: inline-block;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  position: relative;
}

.dropdown-user-avatar img {
  width: 100%;
  display: inline-block;
  border-radius: 50%;
  image-rendering: -webkit-optimize-contrast;
}

.dropdown-user-name {
  line-height: 20px;
  padding: 2px 0 0 15px;
  width: 100%;
  padding-bottom: 8px;
  font-weight: 600;
  color: #333;
  font-size: 18px;
}

.dropdown-user-details span {
  display: block;
  font-size: 13.7px;
  color: #888;
  font-weight: 500;
}

/* button option menu  */
.btn-option {
  position: absolute !important;
  top: 8px;
  right: 4px;
  font-size: 21px;
  padding: 5px 3px !important;
}

/* dropdown option menu */
.dropdown-option-nav {
  padding: 0 !important;
}

.dropdown-option-nav:before {
  content: '';
  position: absolute;
  right: 8px;
  top: -13px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 10px solid #e8e9eb;
  border-color: transparent transparent #eaeaea transparent;
  top: -10px;
}

.uk-dropdown-top-right.dropdown-option-nav:before {
  right: 5px;
  top: -13px;
  border-bottom: 0;
  top: auto;
  bottom: -10px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #d4d4d4;
}

.dropdown-option-nav ul {
  list-style: none;
  margin: 0;
  padding: 6px 0px;
  position: relative;
}

.dropdown-option-nav ul li {
  margin: 0;
  padding: 0;
  display: block !important;
}

.dropdown-option-nav .divider-line {
  margin: 4px 0;
  border: 0.3px solid rgba(222, 223, 224, 0.45);
}

.dropdown-option-nav ul li span {
  display: flex;
  align-items: center;
  font-size: 13px;
  transition: 0.3s;
  color: #9799a2;
  padding: 9px 14px;
}

.dropdown-option-nav ul li > span:hover {
  background-color: #f0f5fd;
}

.dropdown-option-nav ul li > span i {
  font-size: 19px;
  position: relative;
  top: 1px;
  margin-right: 7px;
  -webkit-font-smoothing: antialiased;
  transition: 0.3s;
}

.dropdown-option-nav ul li > span:hover {
  color: #2f3152;
}

@media (max-width: 640px) {
  .user-menu .dropdown-notifications,
  .dropdown-notifications {
    width: 100% !important;
    margin: auto;
    margin: auto;
    margin: auto;
    right: 10px !important;
    margin: auto;
    left: auto !important;
    margin: auto;
    max-width: 400px;
    top: 56px !important;
  }

  .dropdown-notifications:before,
  .dropdown-option-nav:before {
    display: none;
  }
}

@media (max-width: 420px) {
  /*.user-menu .dropdown-notifications */
  .dropdown-notifications {
    width: 100% !important;
    margin: auto;
    margin: auto;
    margin: auto;
    right: 0 !important;
    margin: auto;
    left: 0 !important;
    margin: auto;
    max-width: 360px;
    top: 60px !important;
  }

  .dropdown-notifications-content .notification-text .time-ago {
    font-size: 13px;
  }

  .btn-option {
    top: -4px;
    right: -5px;
    padding: 5px 3px;
  }
}

.nav-courses li a {
  padding: 10px 40px 10px 50px !important;
  border-radius: 7px;
}

.nav-courses li a i {
  left: 16px;
  top: 11px !important;
  font-size: 22px !important;
  position: absolute !important;
  bottom: 12px;
  display: block;
}

@media (max-width: 1099px) {
  .dropdown-nav li a i {
    display: none;
  }

  .course-path-info ul {
    column-count: 1;
  }
}

/* Breadcrumbs */
#breadcrumbs {
  display: inline-block;
  border-radius: 5px;
  font-weight: 600;
  color: #062e69;
  font-size: 14px;
}

#breadcrumbs ul {
  margin: 0;
  padding: 0 8px 0px 0;
  line-height: 25px;
  font-weight: 500;
}

#breadcrumbs ul li a {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: #15307d;
  opacity: 0.7;
}

#breadcrumbs ul li a:hover {
  color: #66676b;
}

.uk-light #breadcrumbs ul li a,
.uk-light #breadcrumbs ul {
  color: white;
}

.uk-light #breadcrumbs ul li:before {
  color: rgba(255, 255, 255, 0.63);
}

#breadcrumbs ul li {
  display: inline-block;
  list-style: none;
  margin: 0 0 0 2px;
  position: relative;
}

#breadcrumbs ul li:first-child:before {
  display: none;
}

#breadcrumbs ul li:first-child {
  margin-left: 0;
}

#breadcrumbs ul li:before {
  display: inline-block;
  font-size: 11px;
  margin-right: 4px;
  font-family: 'Feather-Icons';
  font-weight: 700;
  transform: translate(0%, 5%);
  content: '\e930';
}

#breadcrumbs ul li.home-icon a {
  opacity: 1;
  font-size: 24px;
  top: 4px;
  position: relative;
  line-height: 0;
  padding: 0;
  display: inline-block;
}

#breadcrumbs ul li.home-icon i {
  line-height: 0;
}

@media (max-width: 640px) {
  #breadcrumbs {
    display: none;
  }
}

/* ---------------------------------- */
/* Course details
------------------------------------- */
.course-details {
  margin: 20px 0;
  margin-bottom: 40px;
  padding: 20px;
}

.course-details .container {
  padding: 0;
}

.course-details h1 {
  margin-bottom: 10px;
  font-size: 30px;
}

.course-details p {
  letter-spacing: 0.2px;
  font-size: 16px;
  line-height: 25px;
  color: #656565;
}

.course-details-info {
  margin: 4px 0;
}

.course-details-info ul {
  display: flex;
  margin-top: 5px;
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 15px;
}

.course-description-content h4 {
  font-weight: 600;
}

.course-description-content h4:not(:first-child) {
  margin-top: 28px;
  font-weight: 600;
}

.course-details-sticky {
  top: 0;
  right: 0;
}

/* light color */
.uk-light .course-details p,
.uk-light .course-details-info ul li {
  color: rgba(255, 255, 255, 0.7) !important;
}

@media (max-width: 768px) {
  .course-details {
    margin: 0;
  }

  .course-details h1 {
    font-size: 22px;
  }

  .course-details-info {
    margin: 10px 0;
    margin-bottom: 0;
  }

  .course-details-info ul {
    display: block;
    flex-wrap: wrap;
  }

  .course-details-info {
    font-size: 15px;
  }

  .star-rating .star.half:after,
  .star-rating .star:before {
    font-size: 16px !important;
  }

  .star-rating .avg {
    font-size: 10px !important;
    line-height: 12px !important;
    padding: 5px 5px !important;
    margin-right: 6px !important;
  }
}

.course-details-info ul li {
  margin-right: 15px;
  color: #808080;
  display: block;
}

.course-details-info ul li:last-child {
  margin-right: 0;
}

.course-details-info ul li .star-rating {
  top: -2px;
  position: relative;
}

.course-details-info ul li i {
  margin-right: 3px;
  position: relative;
  top: 0;
}

.course-details-info a {
  color: #000;
  font-weight: 600;
}

.course-details-info a:hover {
  color: #66676b;
}

/* course description contetns */
.course-description-content h3:not(:first-child) {
  margin-top: 28px;
}

/* course info list */
.course-info-list {
  margin: 15px 0 0;
}

.course-info-list-single {
  display: flex;
  padding: 11px 10px 3px 9px;
  flex-wrap: nowrap;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
}

.course-info-list-single-icon {
  margin-left: auto;
  color: #12111b;
  font-size: 23px;
}

.course-info-list-single-label > * {
  display: inline-block;
  vertical-align: top;
  font-size: 15px;
}

.btn-course-start {
  position: relative !important;
  display: inline-block !important;
  color: #12111b !important;
  background-color: rgb(255, 255, 255);
  align-items: center;
  font-size: 1rem !important;
  padding: 13px 17px !important;
  line-height: 26px !important;
  max-height: 54px;
}

.btn-course-start:hover {
  background-color: #12111b;
  color: white !important;
}

.btn-course-start-2 {
  position: relative;
  display: inline-block;
  color: #12111b;
  font-size: 22px;
  font-weight: 600;
}

.btn-course-start-2 i {
  font-size: 18px;
  font-weight: 700;
}

/* course-card-trailer in course resume page */
.course-card-trailer {
  margin-top: -300px !important;
  padding: 10px;
  border-radius: 10px;
  background-color: white;
  box-shadow: -12px 12px 21px -5px rgba(25, 42, 70, 0.13);
}

.course-resume-wrapper {
  margin-top: -65px;
  padding-top: 75px;
  padding-right: 20px;
  padding-bottom: 40px;
  background-size: contain;
  background-image: url(../images/bg-shapes/course-dhashboard-bg.jpg);
  background-position: center bottom;
  background-repeat: no-repeat;
}

.night-mode .course-resume-wrapper {
  background-image: url(../images/bg-shapes/course-dhashboard-bg-dark.jpg);
}

.course-resume-wrapper .subnav {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 8px 0px;
  border-radius: 0px 20px 20px;
  max-width: 750px;
  margin-top: 30px;
  padding-top: 6px;
  background: white;
  overflow: hidden;
}

.course-resume-wrapper .subnav i {
  display: none;
}

@media (max-width: 960px) {
  .course-card-trailer {
    margin-top: 0px !important;
  }

  .course-resume-wrapper {
    background-image: none;
    padding: 0;
    padding-bottom: 0px;
    padding-top: 90px;
    margin-bottom: 20px;
  }

  .course-resume-wrapper .subnav {
    margin-top: 23px;
    border-radius: 10px;
    margin-bottom: -18px;
  }

  .course-resume-wrapper .subnav span {
    display: none;
  }

  .course-resume-wrapper .subnav i {
    display: block;
    font-size: 21px;
  }

  .btn-course-start-2 {
    font-size: 18px;
  }
}

/* ---------------------------------- */
/* course curriculum list
------------------------------------- */

ul.course-curriculum-list {
  padding: 0;
  list-style: none;
}

.course-curriculum-list > li {
  display: block;
  padding: 14px 55px 14px 65px;
  line-height: 27px;
  font-size: 15.5px;
  font-weight: 600;
  position: relative;
  color: #666;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.course-curriculum-list > li:hover {
  background-color: #f9fafe;
}

.course-curriculum-list > li > a {
  padding: 3px 7px;
  margin-left: 15px;
  border-radius: 4px;
  background-color: #4ed7a8;
  color: #fff;
  font-size: 13px;
}

.course-curriculum-list > li:before {
  transition: transform 0.4s cubic-bezier(0.8, -0.41, 0.19, 2.5);
  font-family: 'unicons';
  position: absolute;
  left: 20px;
  text-align: center;
  width: 27px;
  height: 27px;
  line-height: 27px;
  content: '\eb1d';
  background: #474a58;
  color: white;
  border-radius: 100%;
  z-index: 2;
}

.course-curriculum-list li > span {
  font-size: 13px;
  font-weight: 600;
  color: #989898;
  position: absolute;
  right: 12px;
}

@media (max-width: 960px) {
  .course-curriculum-list > li {
    font-size: 13px;
    padding: 10px 54px 10px 50px;
  }

  .course-curriculum-list > li:before {
    left: 13px;
  }
}

/* ---------------------------------- */
/* course curriculum list accordion
------------------------------------- */

.course-curriculum > li {
  background-color: #fff;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.06) !important;
  border-radius: 6px !important;
  overflow: hidden;
}

.course-curriculum .uk-accordion-title {
  padding: 18px 20px;
  font-size: 17px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.54);
}

/* accordion indicator */
.course-curriculum .uk-accordion-title::before {
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: 'Feather-Icons';
  content: '\e92e';
  background-image: none !important;
  color: rgba(0, 0, 0, 0.54);
}

.course-curriculum .uk-open > .uk-accordion-title::before {
  transform: rotate(180deg);
  background-image: none !important;
}

.course-curriculum .uk-accordion-content {
  margin: 0;
}

@media (max-width: 960px) {
  .course-curriculum .uk-accordion-title {
    padding: 12px 18px;
    font-size: 14px;
  }
}

/* ---------------------------------- */
/* course faq accordion
------------------------------------- */

.course-faq > li.uk-open {
  background-color: #ffffff;
  border-radius: 7px;
  box-shadow: 0 4px 11px -4px rgba(0, 0, 0, 0.2);
}

.course-faq > li {
  overflow: hidden;
  margin-top: 0 !important;
}

.course-faq .uk-accordion-title {
  padding: 16px 20px;
  font-size: 17px;
  padding-left: 3px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.54);
}

/* accordion indicator */
.course-faq .uk-accordion-title::before {
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: 'Feather-Icons';
  content: '\e930';
  background-image: none !important;
  color: rgba(0, 0, 0, 0.54);
  float: left;
  margin-right: 6px;
  margin-left: 0;
}

.course-faq .uk-open > .uk-accordion-title::before {
  transform: rotate(90deg) !important;
  background-image: none !important;
}

.course-faq .uk-accordion-content {
  margin: 0;
  padding: 18px 20px;
  border-top: 1px solid #e4e4e4;
}

/* ---------------------------------- */
/* review summary
------------------------------------- */

.review-summary-title {
  font-size: 18px;
  font-weight: 600;
}

.review-summary-container,
.review-summary-rating-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.review-summary-avg .star-rating .star:before,
.review-summary-avg .star-rating .star.half:after {
  color: #666666;
}

.review-summary-avg {
  width: auto;
  align-items: center;
  flex-direction: column;
  margin-right: 30px;
  display: flex;
  background: #f0f3fa;
  padding: 20px 30px;
  border-radius: 10px;
  border: 1px solid #daebf9;
}

.review-summary-avg .avg-number {
  font-size: 60px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 8px;
}

.review-summary-rating .review-summary-rating-wrap {
  align-items: center;
  margin-bottom: 2px;
}

.review-summary-rating,
.review-bars {
  flex: 1;
  min-width: 1px;
  align-items: center;
}

.review-bars .full_bar {
  position: relative;
  width: 100%;
  height: 8px;
  border-radius: 8px;
  background-color: #e2e2e2;
}

.review-bars .full_bar .bar_filler {
  position: absolute;
  top: -1px;
  left: 0;
  right: -1px;
  bottom: -1px;
  height: auto;
  background-color: #12111b;
  border-radius: 8px;
}

.review-stars,
.review-avgs {
  width: auto;
  padding-left: 15px;
}

.review-stars .star-rating .star:before {
  line-height: 15px;
}

@media (max-width: 768px) {
  .review-summary-title {
    font-size: 22px;
    font-weight: 600;
    margin: 0 0 10px;
  }

  .review-summary-container {
    display: inline;
  }

  .review-summary-avg {
    background: transparent;
    padding: 0;
    border: 0;
    flex-direction: row;
  }

  .review-summary-avg span {
    display: block;
  }

  .review-summary-avg .avg-number {
    font-size: 40px;
    margin-right: 16px;
  }

  .review-summary-avg .review-star {
    display: none;
  }

  .review-summary-rating .review-summary-rating-wrap {
    margin-bottom: 5px;
  }

  .review-bars .full_bar {
    height: 8px;
    border-radius: 2px;
  }
}

/* ---------------------------------- */
/* Comments
------------------------------------- */
.comments {
  margin: 40px 0px;
}

.comments ul {
  padding-left: 0;
}

.comments ul li {
  display: block;
}

.comments ul li,
.comments ul li ul li,
.comments ul li ul li ul li,
.comments ul li ul li ul li {
  margin: 30px 0 0 0px;
}

.comments ul li ul {
  margin: 0 0 0 100px;
}

.comment-content {
  padding: 0 0 0 90px;
}

.comment-content p {
  margin: 3px 0 0 0;
  line-height: 26px;
  padding: 5px 0;
}

.comments ul li {
  position: relative;
}

.comment-content strong {
  padding-right: 5px;
  color: #666;
}

.comment-content span {
  color: #888;
}

.comments-amount {
  color: #888;
  font-weight: 500;
  margin-left: 8px;
}

.comments-avatar {
  display: inline-block;
  padding: 0 17px 0 0;
  position: absolute;
  left: 0px;
  top: -5px;
}

.comments-avatar img {
  max-width: 60px;
  border-radius: 50%;
}

.comment-by {
  width: 100%;
  padding-bottom: 8px;
  font-weight: 600;
  color: #333;
  font-size: 16px;
}

.comment-by .comment-stars {
  display: inline;
  line-height: 22px;
  font-weight: 500;
  position: absolute;
  right: 0;
  top: 0px;
}

.comment-by h5 {
  margin: 0 0 7px 0;
}

.comment-by a.reply {
  transition: all 0.3s;
}

.comment-by a.reply {
  display: inline;
  background-color: rgba(62, 65, 109, 0.18);
  padding: 6px 15px;
  line-height: 22px;
  font-size: 14px;
  color: #12111b;
  position: absolute;
  right: 0;
  top: 14px;
  border-radius: 4px;
}

.comment-by a.reply i {
  font-size: 12px;
  margin-right: 1px;
}

.comment-by a.reply:hover {
  background-color: #12111b;
  color: #fff;
}

.comment-by span {
  color: #888;
  font-weight: 500;
  margin: 0px 0 0 0;
  float: none;
  display: block;
  text-align: left;
  font-size: 12px;
}

.comment-footer {
  margin-top: 13px;
}

.comment-footer span {
  font-size: 15px;
  margin-right: 12px;
}

.comment-footer a {
  font-weight: 600;
  color: #989898;
  font-size: 12px;
}

.comment-footer a:hover {
  color: #656565;
}

.comment-footer button {
  background: #f0f4fa;
  border: 1px solid #dae7f9;
  padding: 4px 8px;
  border-radius: 4px;
  color: #333333;
  cursor: pointer;
  margin-right: 4px;
  font-size: 11px;
}

@media (max-width: 768px) {
  /* Media Queries for Comments */
  .comments ul li ul {
    margin: 0;
  }

  .comments-avatar {
    top: 3px;
  }

  .comment-content {
    color: #666;
    padding: 0 0 0 70px;
  }

  .comments-avatar img {
    max-width: 50px;
    border-radius: 50%;
    top: 5px;
  }

  .comment-by {
    width: 100%;
    font-size: 17px;
  }

  .comment-stars .star-rating .star.half:after,
  .comment-stars .star-rating .star:before {
    color: #febe42;
    font-size: 14px !important;
  }

  .comment-by a.reply {
    /* position: relative; */
    margin: 10px 2px 0 0;
    top: 0;
    display: inline-block;
  }
}

/* ---------------------------------- */
/* course card   
------------------------------------- */

.course-card {
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  box-shadow: -10px 10px 14px -5px rgba(25, 42, 70, 0.13);
}

.course-card-thumbnail {
  flex: 0 0 auto;
  max-width: none;
  max-height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 150px;
}

.course-card-thumbnail img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.course-card-thumbnail .item-tag {
  left: 16px;
  margin: 0;
  top: 15px;
}

.course-card span.catagroy {
  font-size: 13px;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 7px;
  border-radius: 4px;
}

.course-card-body {
  padding: 16px 17px;
}

.course-card-body .course-card-info {
  display: flex;
  justify-content: space-between;
}

.course-card h4 {
  color: #12111b;
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 0;
  font-size: 16px;
  line-height: 24px;
  overflow: hidden;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.course-card p {
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  color: #686f7a;
  line-height: 23px;
}

.course-card-footer {
  padding: 11px 15px;
  border-top: 1px solid #eaeaea;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px -16px;
  margin-top: 9px;
  margin-bottom: -15px;
  font-weight: 600;
}

.course-card-footer h5 {
  color: #909090;
  margin-bottom: 0;
  margin-top: 0;
  font-size: 12.8px;
  margin-right: 10px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.course-card-footer h5 i {
  margin-right: 5px;
}

/*course-tags */
.course-catagory-tag {
  padding: 4px 9px;
  line-height: 12px;
  display: inline-block;
  position: absolute;
  top: 118px;
  color: white;
  font-weight: 700;
  font-size: 14px;
  border-radius: 5px;
}

/* play button on hover course card image*/

.course-card-thumbnail .play-button-trigger {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: url(../images/icon-play.svg) no-repeat;
  background-size: auto 50%;
  background-position: center;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 150ms ease-in-out;
  -moz-transition: opacity 150ms ease-in-out;
  -ms-transition: opacity 150ms ease-in-out;
  -o-transition: opacity 150ms ease-in-out;
  transition: opacity 150ms ease-in-out;
  -webkit-transition: -webkit-transform 150ms ease-in-out;
  -moz-transition: -moz-transform 150ms ease-in-out;
  -o-transition: -o-transform 150ms ease-in-out;
  transition: transform 150ms ease-in-out;
}

.course-card-thumbnail:hover .play-button-trigger,
.play-button-trigger.show {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

/* course pgrressbar */
.course-progressbar {
  position: relative;
  width: 100%;
  height: 4px;
  /* margin-top: 3px; */
  border-radius: 8px;
  background-color: #e6e8ec;
}

.course-progressbar .course-progressbar-filler {
  position: absolute;
  top: -1px;
  left: 0;
  right: -1px;
  bottom: -1px;
  height: auto;
  background-color: #17d292;
  border-radius: 8px;
}

@media (max-width: 640px) {
  .course-card h4 {
    font-size: 17px;
  }

  .course-card p {
    font-size: 15px;
  }
}

/* ---------------------------------- */
/*   course card list
------------------------------------- */

.course-card-list.course-card {
  margin-bottom: 30px;
}

/* make small the rating stars */
.course-card-list.course-card .star-rating .star.half:after,
.course-card-list.course-card .star-rating .star:before {
  font-size: 18px;
}

.course-card-list.course-card .course-details-info ul li .star-rating {
  top: 4px;
}

.course-card-list.course-card .star-rating .avg {
  font-size: 12px;
  line-height: 12px;
  padding: 4px 6px;
  margin-right: 5px;
}

/* episode-card */
.episode-card.course-card .course-card-body {
  padding: 10px 15px;
}

.episode-card.course-card .course-card-body h4 {
  font-size: 14px;
  line-height: 24px;
}

@media (max-width: 640px) {
  .course-card.course-card-list {
    margin-bottom: 13px !important;
  }

  .course-card.course-card-list h4 {
    font-size: 16px !important;
    line-height: 20px !important;
  }

  .course-card.course-card-list p {
    font-size: 15px !important;
    line-height: 21px !important;
    height: 66px;
    overflow: hidden;
  }
}

/* .episode card */
/* .episode-card.course-card span.item-tag {
	padding: 1px 7px;
} */

/* course card on small device */
@media (max-width: 640px) {
  .course-card {
    /* border-radius: 0; */
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 10px !important;
    box-shadow: none;
  }

  .course-card .course-card-thumbnail {
    border-radius: 5px;
    box-shadow: 0px 2px 7px -3px #0000001a;
  }

  /* 	.course-card .course-card-body {
		padding: 0 10px;
		flex: 1;
		min-width: 1px;
	}
 */
  .course-card .course-card-body h4 {
    font-size: 14px;
    line-height: 20px;
  }

  .course-card .course-card-body p {
    font-size: 13px;
    line-height: 19px;
  }

  .course-card .course-card-footer {
    padding-top: 0;
    border: 0;
  }

  .course-card .course-card-footer h5 i {
    display: none;
  }

  .course-card span.catagroy {
    font-size: 12px;
  }
  /* 
	.course-card .course-card-info {
		display: none !important;
	}
 */
  .episode-card.course-card {
    display: inherit;
  }

  .episode-card.course-card .course-card-thumbnail {
    width: 100%;
    height: 170px;
  }

  .episode-card.course-card .course-card-body {
    padding: 11px 0;
    padding-bottom: 0;
    /* margin-bottom: 18px; */
  }

  .episode-card.course-card .course-card-body h4 {
    font-size: 14px;
  }

  .episode-card.course-card span.item-tag {
    padding: 0px 5px;
    font-size: 10px;
  }
}

/* course card grid */
.course-card-grid > * {
  padding-left: 25px !important;
}

.course-card-grid {
  margin-left: -25px !important;
}

.course-card-grid .uk-grid-margin {
  margin-top: 15px !important;
}

/* ---------------------------------- */
/*  coures grid slider
------------------------------------- */

.course-grid-slider {
  position: relative;
  padding-bottom: 16px;
}

@media (max-width: 960px) {
  .course-grid-slider > * > * {
    padding-left: 25px !important;
  }

  .course-grid-slider > * {
    margin-left: -25px !important;
  }

  .course-grid-slider {
    margin: 0 -15px;
    padding-left: 15px;
  }

  .course-grid-slider ul li {
    width: 220px;
  }

  .course-grid-slider .course-card {
    display: block;
    border: 0;
    margin-bottom: 0 !important;
    padding-bottom: 0;
  }

  .course-grid-slider .course-card .course-card-thumbnail {
    width: 100%;
    height: 115px;
    border-radius: 5px;
    box-shadow: 0px 2px 7px -3px #0000001a;
  }

  .course-grid-slider .course-card .course-card-body {
    padding: 10px 0px;
  }
}

/* ---------------------------------- */
/*  course path card
------------------------------------- */

.course-path-card {
  background: white;
  overflow: hidden;
  border-radius: 5px;
  transition: 0.3s;
  position: relative;
  box-shadow: -10px 10px 14px -9px rgba(25, 42, 70, 0.13);
}

.course-path-card:hover {
  box-shadow: -10px 10px 14px -5px rgba(25, 42, 70, 0.13);
}

.course-path-card-contents {
  padding: 15px 20px;
  padding-top: 12px;
  position: relative;
}

.course-path-card-contents h3 {
  font-size: 17px;
  margin-bottom: 7px;
}

.course-path-card-contents p {
  color: #908d8d;
  margin: 0;
  font-weight: 500;
  font-size: 14px;
}

.course-path-card-footer {
  padding: 10px 10px;
  background: #eaf9e9;
  background: white;
  padding: 10px 20px;
  border-radius: 0 0 13px 13px;
  border-top: 1px solid #eaeaea;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.course-path-card-footer h5 {
  color: #909090;
  margin-bottom: 0;
  margin-top: 0;
  font-size: 13px;
}

.course-path-info ul {
  column-gap: 50px;
  column-count: 3;
  -webkit-column-break-inside: avoid;
  padding-left: 0;
}

.course-path-info ul li {
  padding-left: 0;
  font-weight: 400;
  margin-bottom: 16px;
  font-size: 15px;
  list-style-type: disc;
  margin-left: 18px;
}

@media screen and (max-width: 1024px) and (min-width: 641px) {
  .course-path-info ul {
    column-count: 2;
  }
}

@media screen and (max-width: 641px) {
  .course-path-info ul {
    column-count: 1;
  }
}

/* ---------------------------------- */
/* course path slider
------------------------------------- */

.path-wrap .course-grid-slider > * > * {
  padding-left: 25px !important;
}

.path-wrap .course-grid-slider > * {
  margin-left: -25px !important;
}

.path-wrap .course-card {
  border-radius: 7px;
}

.path-wrap .course-card-thumbnail {
  flex: 0 0 auto;
  max-width: none;
  max-height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 120px;
}

.path-wrap .course-catagory-tag {
  display: none;
}

.path-wrap .course-card .uk-progress {
  height: 6px;
  background: transparent;
  padding: 0;
  margin: 0;
  border-radius: 0px !important;
}

.path-wrap .course-card span.duration {
  background-color: rgba(62, 62, 62, 0.74);
  padding: 1px 8px;
  border-radius: 4px;
  font-size: 12px;
  color: white;
  letter-spacing: 1px;
  position: absolute;
  bottom: 7px;
  right: 8px;
}

.path-wrap .course-card-body {
  padding: 14px 13px;
}

.path-wrap .course-card.completed .course-card-body {
  border-top: 6px solid #62d76b;
}

.path-wrap .course-card-body h4 {
  font-size: 14.5px;
}

.path-wrap .course-card-body p {
  font-size: 13.5px;
  line-height: 20px;
}

.path-wrap .course-card-body span.completed-text {
  color: white;
  background: #62d76b;
  padding: 2px 10px;
  position: absolute;
  margin-top: -29px;
  right: 17px;
  border-radius: 10px;
  font-size: 12px;
  margin-left: -4px;
  z-index: 1000;
}

@media (max-width: 768px) {
  .path-wrap .course-card {
    border-radius: 0px;
  }
}

/* ---------------------------------- */
/* course path accordion
------------------------------------- */

.course-path-level > li {
  margin-top: 0 !important;
}

.course-path-level .uk-accordion-title {
  padding-bottom: 20px;
  padding-top: 15px;
  font-size: 19px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.54);
  border-bottom: 1px solid #ececec;
}

.course-path-level .uk-open .uk-accordion-title {
  border-bottom: 0;
}

/* accordion indicator */
.course-path-level .uk-accordion-title::before {
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: 'Feather-Icons';
  content: '\e930';
  background-image: none !important;
  color: rgba(0, 0, 0, 0.54);
  float: left;
  margin-right: 6px;
  margin-left: 0;
}

.course-path-level .uk-open > .uk-accordion-title::before {
  transform: rotate(90deg) !important;
  background-image: none !important;
}

.course-path-level .uk-accordion-content {
  margin: 0;
  padding: 17px 0;
  padding-top: 0;
}

/* @media only screen and (max-width: 960px) {
	.course-path-level .uk-accordion-title {
		padding-bottom: 17px;
		padding-top: 13px;
		font-size: 15px;
	}

	.course-path-level .uk-accordion-content {
		padding-top: 0;
	}
}
 */
/* course card resume */
.course-card-resume {
  box-shadow: -10px 10px 14px -7px rgba(25, 42, 70, 0.13);
  background: white;
  border-radius: 7px;
  overflow: hidden;
  position: relative;
}

.course-card-resume-thumbnail {
  flex: 0 0 auto;
  max-width: none;
  max-height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100px;
}

.course-card-resume-body {
  padding: 12px;
  padding-bottom: 10px;
}

.course-card-resume h5 {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
}

.course-card-resume span {
  font-weight: 600;
  color: #afafaf;
  font-size: 14px;
  display: block;
  margin-bottom: 5px;
}

/* coure progressbar in resume card */
.course-card-resume .course-progressbar {
  margin: 6px 0 0 -13px;
  margin-bottom: -10px;
  width: 113%;
  border-radius: 0px 0px 16px 16px;
  height: 6px !important;
}

@media (max-width: 768px) {
  .course-resume-grid-slider ul li {
    width: 205px;
    padding-left: 15px;
  }
}

/* ---------------------------------- */
/* course-watch-page
------------------------------------- */

.course-watch-page {
  overflow: hidden;
}

.course-layouts {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  transition: 0.3s;
  height: 100vh;
}

.course-header {
  width: 100%;
  max-width: 100%;
  transition: 0.3s;
  box-shadow: 0px 2px 6px 0px #00000017;
  padding: 20px 1.6%;
  padding-left: 14px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.course-header h4 {
  margin: 0;
  font-size: 20px;
}

.course-header .btn-back {
  margin-right: 9px;
}

.course-header .btn-back i {
  display: block;
  padding: 8px 8px;
  margin-right: 9px;
  font-size: 23px;
  border-radius: 100%;
  color: #dedede;
}

.course-header .btn-back i:hover,
.course-header .btn-back i:hover,
.course-header .btns:hover {
  background: rgba(255, 255, 255, 0.28);
}

.course-header .btn-back i::before {
  transition: transform 0.4s cubic-bezier(0.8, -0.41, 0.19, 2.5);
  font-family: 'Feather-Icons';
  /* content: '\e92f'; */
  border-radius: 100%;
  z-index: 2;
}

/* chenge the traiger when the sidebar collapsed */
.course-layouts.course-sidebar-collapse .course-header .btn-back i::before {
  /* content: '\e98f'; */
}

.course-header .btns {
  padding: 8px 8px;
  margin-left: 9px;
  font-size: 23px;
  border-radius: 100%;
  background: transparent;
  color: #dedede;
}

/* video sidebar */
.course-sidebar {
  width: 25%;
  height: auto;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px #00000017;
  margin: 0;
  padding: 0;
  order: 1;
  z-index: 1;
  transition: 0.3s;
}

.course-sidebar-title {
  background: #f8fbff;
  width: 100%;
  padding: 0px 15px;
  display: flex;
  align-items: center;
  height: 65px;
}

.course-sidebar-title h3 {
  margin-bottom: 0;
  font-size: 1rem;
}

/* if toggle hidden sidebar */
.course-layouts.course-sidebar-collapse .course-sidebar {
  transition: 0.3s;
  width: 0;
}

.course-layouts.course-sidebar-collapse .course-sidebar .course-sidebar-title {
  /* display: none; */
}

.course-sidebar-container {
  width: 100% !important;
  height: calc(99vh - 60px) !important;
}

ul.course-video-list {
  margin: 0 !important;
  padding: 0;
  list-style: none;
  overflow: hidden;
}

.course-video-list li:nth-child(2n) {
  background: #f0f5fd;
  background: #f8fbff;
}

.course-video-list li > a:before {
  transition: transform 0.4s cubic-bezier(0.8, -0.41, 0.19, 2.5);
  font-family: 'Feather-Icons';
  position: absolute;
  left: 20px;
  text-align: center;
  width: 25px;
  height: 25px;
  top: 31%;
  line-height: 25px;
  content: '\e987';
  background: #474a58;
  background: #efefef;
  color: darkgrey;
  border-radius: 100%;
  z-index: 2;
}

.course-video-list li > a:after {
  content: '';
  width: 2px;
  height: 100%;
  top: -18px;
  z-index: 1;
  left: 33px;
  background: #e3e6ec00;
  position: absolute;
  transition: color 150ms ease-in, top 100ms ease-out;
}

.highlight-watched a:before {
  content: '\e9af' !important;
}

.video-list ul > li > a span {
  font-size: 13px;
  font-weight: 700;
  color: #989898;
  position: absolute;
  right: 12px;
}

/* ---------------------------------- */
/* courses filter list
------------------------------------- */

.course-video-list-section > li {
  overflow: hidden;
  margin-top: 0 !important;
}

.course-video-list-section .uk-accordion-title {
  padding: 16px;
  font-size: 15px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.54);
  border-bottom: 1px solid #161d2d;
}

.course-video-list-section .uk-open .uk-accordion-title {
  border-bottom: 0;
}

/* accordion indicator */
.course-video-list-section .uk-accordion-title::before {
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: 'Feather-Icons';
  content: '\e92e';
  background-image: none !important;
  color: rgba(0, 0, 0, 0.54);
  margin-left: 0;
}

/* accordion active */
.course-video-list-section .uk-open > .uk-accordion-title::before {
  content: '\e931';
}

.course-video-list-section .uk-accordion-content {
  margin: 0;
  padding-bottom: 10px;
}

/* ---------------------------------- */
/* course-video-list-2
------------------------------------- */

ul.course-video-list-2 {
  margin: 0;
  padding: 0;
}

ul.course-video-list-2 li {
  position: relative;
  display: block;
  clear: both;
}

ul.course-video-list-2 li a {
  padding: 10px;
  border-bottom: 1px solid #e6e6e6;
  display: block;
  font-weight: 600;
}

ul.course-video-list-2 li img {
  width: 100px;
  height: auto;
  float: left;
  margin-right: 15px;
  border-radius: 6px;
  border: 3px solid transparent;
}

ul.course-video-list-2 li a span.now-playing {
  position: absolute;
  left: 10px;
  bottom: 12px;
  background: #12111b;
  color: #fff;
  padding: 3px 7px;
  display: none;
  font-size: 9px;
  font-weight: 700;
  border-radius: 0 9px;
}

ul.course-video-list-2 li a span.video-title {
  color: #12111b;
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  text-decoration: none;
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
}

ul.course-video-list-2 li a time {
  font-size: 12px;
  color: #888;
  position: relative;
  top: -5px;
}

ul.course-video-list-2 li a:after {
  content: '';
  display: block;
  clear: both;
}

ul.course-video-list-2 li:nth-child(2n) {
  background: #f8fbff;
}

/* if list is is active or selected */
ul.course-video-list-2 li.uk-active a span.now-playing {
  display: block;
}

ul.course-video-list-2 li.uk-active a img {
  border-color: #12111b;
  border-radius: 6px;
}

ul.course-video-list-2 li a:hover,
ul.course-video-list-2 li.uk-active a {
  background-color: #e2ecff;
}

/* ---------------------------------- */
/* video contents
------------------------------------- */

.course-content {
  flex: 1;
  min-width: 1px;
  order: 2;
  background-color: #f8fbff;
  transition: 0.3s;
}

.course-content-inner {
  max-width: 1024px;
  height: calc(99vh - 90px) !important;
  margin: auto;
  justify-content: center;
  align-items: center;
  display: flex;
  transition: 0.3s;
  position: relative;
}

@media (max-width: 768px) {
  .course-watch-page {
    overflow-x: hidden;
    overflow-y: visible;
  }

  .course-layouts {
    /* display: inline;
    flex-wrap: unset; */
  }

  .course-header {
    padding: 15px 10px;
  }

  .course-header h4 {
    font-size: 17px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .course-header .btn-back {
    /* display: none; */
  }

  .course-header .btn-back i {
    padding: 5px;
  }

  /* sidebar */
  .course-sidebar {
    /* width: 100%; */
  }

  .course-layouts.course-sidebar-collapse .course-sidebar {
    left: -100% !important;
  }

  .course-content-inner {
    height: auto !important;
  }

  .course-content {
    min-height: auto;
    padding: 0;
  }
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  display: contents;
  padding: 20px;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  padding: 20px;
}

@media (max-width: 768px) {
  .video-responsive {
    padding: 0;
  }

  .video-responsive iframe {
    position: relative;
    padding: 0px;
    height: 40vw;
    width: 100vw;
  }
}

@media (max-width: 520px) {
  .video-responsive iframe {
    position: relative;
    padding: 0px;
    height: 55vw;
    width: 100vw;
  }
}

/* ---------------------------------- */
/* courses video-list-3
------------------------------------- */
@media (min-width: 960px) {
  .vidlist-3-container {
    box-shadow: 4px 0px 9px 4px #e6e6e6;
  }
}

@media (max-width: 960px) {
  .vidlist-3-container {
    position: fixed;
    right: -330px;
    top: 0;
    height: 100%;
    width: 330px !important;
    background: #f9f9fc;
    overflow-y: scroll;
    z-index: 9;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }

  .is-open .vidlist-3-container {
    right: 0;
    -webkit-box-shadow: -2px 0 30px rgba(0, 0, 0, 0.2);
    box-shadow: -2px 0 30px rgba(0, 0, 0, 0.2);
  }

  /* tragger */
  .btn-vidlist-3 {
    border-radius: 5px;
    width: 40px;
    height: 40px;
    position: fixed;
    z-index: 10000000;
    top: 50%;
    right: 10px;
    display: block;
    background: #525e69;
    color: white;
    border-radius: 100%;
  }

  .btn-vidlist-3::before {
    font-family: 'Feather-Icons';
    font-size: 20px;
    content: '\e9f7';
    line-height: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }

  .is-open .btn-vidlist-3::before {
    content: '\ea02';
  }

  .is-open .btn-vidlist-3 {
    background: #515d67;
    position: fixed;
    top: 50%;
    right: 326px;
    z-index: 10;
  }
}

/* ---------------------------------- */
/* course lesson  3  
------------------------------------- */

.vidlist-3-content {
  height: calc(99vh - 90px) !important;
}

.vidlist-3-section > li {
  overflow: hidden;
  margin-top: 0 !important;
}

.vidlist-3-section .uk-accordion-title {
  padding: 13px 16px;
  font-size: 15px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.54);
  border-bottom: 1px solid #161d2d;
}

.vidlist-3-section .uk-open .uk-accordion-title {
  border-bottom: 0;
}

/* accordion indicator */
.vidlist-3-section .uk-accordion-title::before {
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: 'Feather-Icons';
  content: '\e92e';
  background-image: none !important;
  color: rgba(0, 0, 0, 0.54);
  margin-left: 0;
}

/* accordion active */
.vidlist-3-section .uk-open > .uk-accordion-title::before {
  content: '\e931';
}

.vidlist-3-section .uk-accordion-content {
  margin: 0;
  padding-bottom: 10px;
}

/* video list 3 */
ul.vidlist-3 {
  margin: 0 !important;
  padding: 0;
  list-style: none;
  overflow: hidden;
}

.vidlist-3 li > a {
  display: block;
  padding: 10px 40px 10px 50px;
  line-height: 29px;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  max-width: 100%;
  /* overflow: hidden; */
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vidlist-3 li > a:before {
  left: 15px;
  text-align: center;
  width: 26px;
  height: 26px;
  top: 25%;
  line-height: 26px;
  content: '';
  z-index: 2;
  position: absolute;
  background: url(../images/icon-play.svg);
}

.vidlist-3 li > a:hover,
.vidlist-3 li > a:active,
.vidlist-3 li > a:focus,
.vidlist-3 li.uk-active a {
  background-color: rgba(71, 74, 88, 0.0784313725490196);
}

.vidlist-3 li > a span {
  font-size: 11px;
  font-weight: 600;
  color: #989898;
  position: absolute;
  right: 12px;
}

@media (max-width: 960px) {
  .vidlist-3-section .uk-accordion-title,
  .vidlist-3 li > a {
    font-size: 12px;
  }
}

/* ---------------------------------- */
/* skil card list
------------------------------------- */

.skill-card {
  display: flex;
  position: relative;
  color: #545e6f;
  transition: transform 0.2s ease-in-out;
  background: white;
  border-radius: 9px;
  transition: 0.3s;
  position: relative;
  padding: 20px;
  box-shadow: -10px 10px 14px -9px rgba(25, 42, 70, 0.13);
  align-items: center;
}

.skill-card:hover {
  transform: translate(0, -4px);
}

.skill-card-icon {
  font-size: 35px;
  width: auto;
  margin: 0 17px 0 0;
}

.skill-card-title {
  font-weight: 400;
  font-size: 17.5px;
  margin-top: 2px;
  margin-bottom: 5px;
  position: relative;
  line-height: 20px;
  color: #545e6f;
}

.skill-card-subtitle {
  font-size: 13.4px;
  color: rgba(84, 94, 111, 0.6);
  line-height: 16px;
  font-weight: 400;
  margin: 0;
}

.skill-card-bullet {
  color: rgba(84, 94, 111, 0.2);
  display: inline-block;
  margin: 0 1px;
}

/* ---------------------------------- */
/* sidebar-filter
------------------------------------- */

.sidebar-filter h4 {
  margin-bottom: 0;
  padding-bottom: 11px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  /* border-bottom: 1px solid #dadada; */
}

.btn-sidebar-filter {
  display: none;
}

@media (max-width: 768px) {
  .sidebar-filter.sidebar-filter-visible .sidebar-filter-contents {
    display: block !important;
  }

  .btn-sidebar-filter {
    width: 150px;
    margin: 0;
    margin-top: 15px;
    background-color: #f3f3f3;
    border-radius: 5px;
    color: #454872;
    padding: 12px 17px;
    padding-left: 45px;
    text-align: left;
    font-weight: 600;
    transition: 0.2s;
    display: block;
    line-height: 20px;
    border: 0;
    position: relative;
    display: block;
  }

  .btn-sidebar-filter::after {
    font-family: 'Feather-Icons';
    content: '\e9d1';
    font-size: 18px;
    left: 17px;
    position: absolute;
  }

  .sidebar-filter-visible .btn-sidebar-filter::after {
    font-family: 'Feather-Icons';
    content: '\ea02';
  }

  .sidebar-filter h4 {
    margin-top: 0px;
  }
}

/* courses filter list  accordion */

.btn-filter {
  padding: 8px 11.5px;
  background: #edeff5;
  border-radius: 6px;
  color: #12111b;
  box-shadow: 0px 2px 8px -10px rgba(0, 0, 0, 0.3);
}

.btn-filter:hover {
  color: #fff;
  background: #12111b;
}

@media (max-width: 640px) {
  .btn-filter {
    padding: 5px 10.5px;
  }
}

.sidebar-filter-list > li {
  overflow: hidden;
  margin-top: 0 !important;
}

.sidebar-filter-list .uk-accordion-title {
  padding: 13px 2px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.54);
  border-bottom: 1px solid #e6e6e6;
}

.sidebar-filter-list .uk-open .uk-accordion-title {
  border-bottom: 0;
}

/* accordion indicator */
.sidebar-filter-list .uk-accordion-title::before {
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: 'Feather-Icons';
  content: '\e930';
  background-image: none !important;
  color: rgba(0, 0, 0, 0.54);
  margin-left: 0;
}

.sidebar-filter-list .uk-open > .uk-accordion-title::before {
  transform: rotate(270deg) !important;
  background-image: none !important;
}

.sidebar-filter-list .uk-accordion-content {
  margin: 0;
  padding-bottom: 10px;
}

.sidebar-filter-list .uk-form-controls label {
  display: block;
  padding: 8px 0;
  font-weight: 400;
  cursor: pointer;
  font-size: 15px;
  margin-bottom: 0;
}

.sidebar-filter-list .uk-form-controls label span span {
  font-size: 14px;
}

.sidebar-filter-list .uk-form-controls label .uk-radio {
  margin-right: 9px;
}

.btn-sidebar-filter {
  display: none;
}

@media (max-width: 768px) {
  .sidebar-filter .sidebar-filter-contents {
    padding: 20px 0;
    display: none;
  }

  .sidebar-filter.sidebar-filter-visible .sidebar-filter-contents {
    display: block !important;
  }

  .btn-sidebar-filter {
    width: 150px;
    margin: 0;
    margin-top: 15px;
    background-color: #f3f3f3;
    border-radius: 5px;
    color: #454872;
    padding: 12px 17px;
    padding-left: 45px;
    text-align: left;
    font-weight: 600;
    transition: 0.2s;
    display: block;
    line-height: 20px;
    border: 0;
    position: relative;
    display: block;
  }

  .btn-sidebar-filter::after {
    font-family: 'Feather-Icons';
    content: '\e9d1';
    font-size: 18px;
    left: 17px;
    position: absolute;
  }

  .sidebar-filter-visible .btn-sidebar-filter::after {
    font-family: 'Feather-Icons';
    content: '\ea02';
  }

  .sidebar-filter h4 {
    margin-top: 0px;
  }
}

/* ---------------------------------- */
/* admin course creatore
------------------------------------- */
.c-curriculum .uk-accordion-title::before {
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: 'Feather-Icons';
  content: '\e92e';
  background-image: none !important;
  color: rgba(0, 0, 0, 0.54);
}
.c-curriculum .uk-open > .uk-accordion-title::before {
  transform: rotate(180deg);
  background-image: none !important;
}

.c-curriculum li.uk-open .action-btn {
  display: inline;
}

.c-curriculum > li {
  background: #f1f1f1;
  border-radius: 10px;
  /* border: 2px solid #6d7098; */
  padding: 13px 10px;
  position: relative;
}

.c-curriculum .uk-accordion-title {
  padding: 3px 6px;
  font-size: 15px;
}
.c-curriculum .uk-accordion-title i {
  margin-right: 8px;
}

ul.sec-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.sec-list-item {
  display: flex;
  background-color: #ffffff;
  border: 1px solid #d4d4d4;
  margin-bottom: 7px;
  padding: 6px 10px;
  border-radius: 10px;
  justify-content: space-between;
}

.sec-list-item * {
  display: flex;
  align-items: center;
}

.sec-list-item > * > .uk-sortable-handle {
  margin-right: 5px;
}
.sec-list-item * p {
  margin-bottom: 0;
}
.sec-list-item .uk-checkbox {
  box-shadow: none;
  background-color: white;
}
.action-btn {
  position: absolute;
  right: 45px;
  top: 14px;
  display: none;
}
.action-btn a {
  background: #12111b26;
  margin-left: 10px;
  box-shadow: 0px -3.5px 0px 0px #c1c1c1 inset;
  padding: 8px 10px;
  color: #12111b;
  font-size: 12px;
  border-radius: 3px;
}
.btn-act a:hover {
  background-color: #d0d3ff;
  color: #12111b;
}
.btn-act a {
  padding: 7px;
  color: #676565;
  font-size: 14px;
  margin: 0 5px;
  border-radius: 100px;
  transition: 300ms all ease;
}
/*  User Details */
.user-details-card {
  display: flex;
  padding: 20px 0px;
  align-items: center;
}

/* Avatar */
.user-details-card-avatar {
  display: inline-block;
  border-radius: 50%;
  position: relative;
  max-width: 60px;
}

.user-details-card-avatar img {
  width: 100%;
  display: inline-block;
  border-radius: 50%;
  image-rendering: -webkit-optimize-contrast;
}

.user-details-card-name {
  font-weight: 500;
  color: #333;
  line-height: 20px;
  padding: 2px 0 0 15px;
  font-weight: 600;
}

.user-details-card span {
  display: block;
  font-size: 13.7px;
  color: #888;
  font-weight: 500;
}

.user-details-card span span {
  display: inline;
  margin-left: 6px;
  color: #afafaf;
}

@media (max-width: 768px) {
  .user-details-card-avatar {
    max-width: 50px;
  }
}

/* ---------------------------------- */
/* Star Rating
------------------------------------- */
.star-rating {
  display: inline-block;
  position: relative;
}

.star-rating .star {
  display: inline-block;
  margin: 0;
  padding: 0;
  float: left;
  margin-right: 0;
  position: relative;
  line-height: 1;
}

.star-rating .star.half:after,
.star-rating .star:before {
  font-family: 'Material-Icons';
  content: '\e988';
  display: block;
  color: #febe42;
  font-size: 19px;
}

.star-rating .star.empty:before {
  color: #ddd;
}

.rating:after {
  content: '.';
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.star-rating .star.half:before {
  color: #ddd;
}

.star-rating .star.half:after {
  color: #febe42;
  position: absolute;
  top: 0;
  width: 50%;
  display: block;
  height: 100%;
  overflow: hidden;
}

/* average rating before stars */
.star-rating .avg {
  float: left;
  border-radius: 4px;
  background-color: #febe42;
  color: #fff;
  font-size: 13px;
  line-height: 15px;
  font-weight: 700;
  padding: 3px 7px;
  position: relative;
  margin-right: 10px;
}

/* No stars */
.star-rating.no-stars .star {
  display: none;
}

.star-rating.no-stars:before {
  margin-right: 0;
}

/* ---------------------------------- */
/* Blog Post
------------------------------------- */
.blog-post {
  display: flex;
  background: #fff;
  margin-bottom: 35px;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  overflow: hidden;
  transition: 0.3s;
  box-shadow: 0px 2px 7px -3px #0000001a;
}

.blog-post:hover {
  box-shadow: 0 3px 28px rgba(0, 0, 0, 0.1);
}

.blog-post-thumbnail {
  flex: 0 0 auto;
  max-width: none;
  max-height: 100%;
  overflow: hidden;
  position: relative;
  width: 40%;
}

.blog-post-thumbnail-inner {
  height: 100%;
  width: 100%;
  overflow: hidden;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
  transition: 0.3s;
}

.blog-post-thumbnail-inner img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-post-thumbnail-inner .blog-item-tag {
  bottom: auto;
  left: 20px;
  top: 20px;
  margin: 0;
}

span.blog-item-tag {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  background-color: #fff;
  border-radius: 4px;
  padding: 5px 12px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 9px;
  position: absolute;
  top: 30px;
  left: 32px;
  z-index: 99;
  transition: 0.4s;
}

.blog-post-content {
  flex: 1;
  padding: 25px;
}

.blog-post-content h3,
.blog-post-content h3 a {
  font-size: 21px;
  line-height: 30px;
  margin-bottom: 7px;
  display: block;
}

.blog-post:hover .blog-post-content h3 {
  transition: 0.3s;
  color: #0610a0;
}

a.blog-post-info,
.blog-post-info,
.blog-post-date {
  font-size: 11px;
  color: #888;
  margin-bottom: 10px;
  display: inline-block;
  background-color: #f0f0f0;
  border-radius: 4px;
  line-height: 18px;
  padding: 4px 9px;
  margin-right: 3px;
  transition: 0.3s;
}

a.blog-post-info:hover {
  background-color: #12111b;
  color: white;
}

.blog-post-content-info {
  align-items: center;
  justify-content: space-between;
  display: flex;
  margin-bottom: 9px;
}

span.blog-post-info-date {
  font-weight: 600;
  color: #909090;
  font-size: 12px;
}

span.blog-post-info-tag {
  display: inline-block !important;
  padding: 0.26rem 0.6rem !important;
  font-size: 0.7rem !important;
  font-weight: 600 !important;
  border-radius: 10rem !important;
  line-height: 1 !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  /* height: 15px; */
}

.blog-post-content p {
  padding: 0;
  margin: 0;
  font-size: 18px;
  display: block;
  color: #808080;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  margin-bottom: 6px !important;
}

/* Media Queries for Blog Post */
@media (max-width: 768px) {
  .blog-post {
    flex-direction: column;
  }

  .blog-post-thumbnail {
    width: 100%;
    height: 220px;
  }

  .blog-post-content {
    padding: 20px;
  }

  .blog-post-content h3 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 7px;
  }

  .blog-post-content p {
    font-size: 15px;
  }
}

/* blog post card */
.blog-post.blog-post-card {
  display: block;
}

.blog-post.blog-post-card .blog-post-thumbnail {
  width: 100%;
  height: 170px;
}

.blog-post.blog-post-card .blog-post-content {
  padding: 16px;
  padding-bottom: 6px;
}

.blog-post.blog-post-card .blog-post-content h3 {
  font-size: 20px;
  line-height: 31px;
  font-weight: 600;
  margin-bottom: 7px;
}

.blog-post.blog-post-card .blog-post-content p {
  font-size: 15px;
}

.blog-post.blog-post-card span.blog-post-info-tag {
  padding: 6px 12px;
  font-size: 12px;
}

.blog-post.blog-post-card span.blog-post-info-date {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
}

.blog-post.blog-post-card .blog-post-footer {
  padding: 10px 17px;
  border-top: 1px solid #f0f4fa;
}

.blog-post.blog-post-card .blog-post-content-info {
  margin-bottom: 0;
}

/* Single Post Styles */
.blog-post.single-post {
  flex-direction: column;
}

.blog-post.single-post:hover {
  transform: none;
}

.blog-post.single-post .blog-post-content h3 {
  color: #12111b;
}

.blog-post.single-post p {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.8;
}

@media (min-width: 768px) {
  .blog-post.single-post .blog-post-thumbnail {
    width: 100%;
    height: 380px;
  }

  .blog-post.single-post .blog-post-content h4:not(:first-child) {
    margin-top: 26px;
  }
}

/* blog artical */
.blog-article {
  position: relative;
  margin-bottom: 45px;
}

.blog-article ul.blog-article-meta {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.04em;
  z-index: 20;
  position: relative;
  color: rgba(48, 48, 48, 0.66);
}

.blog-article-thumbnail {
  width: 100%;
  height: 380px;
  max-width: none;
  max-height: 100%;
  position: relative;
}

.blog-article-thumbnail-inner {
  height: 100%;
  width: 100%;
  overflow: hidden;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
  transition: 0.3s;
  border-radius: 8px;
}

.blog-article-thumbnail-inner img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  overflow: hidden;
  left: 0;
  border-radius: 8px;
}

.blog-article-meta {
  padding: 0;
  line-height: 1.2;
  margin: 15px 0px;
  text-transform: uppercase;
  font-size: 13px !important;
}

.blog-article-meta strong {
  color: #a0a0a0;
  padding-right: 10px;
  border-right: 1px solid #d0d0d0;
}

.blog-article-meta a {
  padding-left: 8px;
  color: #12111b;
  font-weight: 600;
}

.blog-article-meta span {
  color: #ababab;
  padding-left: 20px;
  position: relative;
  font-weight: 600;
}

.blog-article-meta span::before {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  left: 5px;
  top: 0;
}

.blog-article-content {
  font-size: 16px;
  line-height: 1.6;
  margin-top: 20px;
  color: #868686;
}

.blog-article-content a {
  font-weight: 700;
  font-size: 14px;
  margin-left: 5px;
}

.blog-article-content-read {
  background-color: white;
  border-radius: 16px;
  margin-top: -80px;
  position: relative;
  padding: 30px;
}

.blog-article-content-read h4 {
  margin-top: 28px;
}

.blog-article-content-read p {
  line-height: 1.8;
  font-size: 15px;
  letter-spacing: 0.2px;
}

/*  blog Single post  article */
.blog-article-single {
  width: 100%;
  height: 430px;
  position: relative;
  display: flex;
  background-size: cover;
  align-items: center;
  background-blend-mode: color-burn;
  background-color: rgba(0, 0, 0, 0.4);
  text-align: center;
  border-radius: 0 0 30px 30px;
  padding-bottom: 70px;
}

.blog-article-single .blog-article-meta {
  margin: 15px 0 !important;
  color: white;
}

.blog-article-single .blog-article-meta span::before {
  display: none;
}

.blog-article-single h1 {
  margin: 36px 0;
  font-size: 35px;
  max-width: 880px;
}

.blog-article-single h1,
.blog-article-single .blog-article-meta strong,
.blog-article-single .blog-article-meta a,
.blog-article-single .blog-article-meta span {
  color: white;
}

.blog-article-auther img {
  width: 100%;
  display: inline-block;
  border-radius: 50%;
  image-rendering: -webkit-optimize-contrast;
  width: 50px;
  height: 50px;
}

.blog-auther-user-name {
  line-height: 18px;
  padding: 15px 0;
  width: 100%;
  padding-bottom: 8px;
  /* font-weight: 600; */
  display: block;
  color: white;
  font-size: 18px;
}

/* Media Queries for Blog Post */
@media (max-width: 768px) {
  .blog-article {
    margin-bottom: 20px;
  }

  .blog-article .blog-article-thumbnail {
    height: 220px;
  }

  .blog-article h2 {
    font-size: 22px;
  }

  .blog-article .blog-article-meta {
    font-size: 12px;
    margin: 10px 0;
  }

  .blog-article .blog-article-content {
    font-size: 18px;
  }

  .blog-article-single {
    width: 100%;
    height: 300px;
  }

  .blog-article-single h1 {
    font-size: 25px;
    margin: 30px 0;
  }

  .blog-article-content-read {
    border-radius: 0;
  }

  .blog-article-content-read h2 {
    font-size: 1.2rem;
  }

  .blog-article-meta {
    font-size: 12px !important;
  }

  .blog-article-content-read h4 {
    margin: 0;
  }
}

/* ---------------------------------- */
/* profile 
------------------------------------- */

.profile-container {
  padding: 40px 20px 0px 20px;
  background: #12111b;
}

.profile-container .contents-inner {
  padding: 0;
}

.profile-layout {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 33px;
}

.profile-layout-content {
  flex: 1;
  min-width: 1px;
}

.profile-layout-content h1 {
  font-size: 35px;
  margin: 15px 0 9px 0;
}

.profile-layout-content h5 {
  color: #8a8a8a;
}

/* profile badge */
.profile-layout-badge {
  width: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 19px;
  align-items: center;
}

.profile-layout-badge * {
  padding: 2px 10px;
  text-align: center;
  font-weight: 600;
  color: white;
}

.profile-layout-badge-num {
  font-size: 28px;
}

/* profile avature */
.profile-layout-avature {
  width: auto;
  margin-right: 30px;
}

.user-profile-photo {
  flex: 0 0 auto;
  max-width: none;
  max-height: 100%;
  overflow: hidden;
  position: relative;
  width: 150px;
  height: 150px;
}

.user-profile-photo img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 100%;
  /* border: 9px solid #edfff3; */
}

.profile-cards {
  background: white;
  padding: 10px;
  border-radius: 7px;
  box-shadow: 0px 2px 10px 0px #0000001a;
  text-align: center;
}

.profile-cards .user-profile-photo {
  width: 120px;
  height: 120px;
  box-shadow: 0px 2px 10px 0px #0000001a;
  border-radius: 100%;
  margin: 15px auto;
}

.profile-cards h2 {
  font-size: 22px;
  margin: 20px 0 7px 0;
}

.profile-cards .profile-cards-footer a {
  color: #12111b;
  font-weight: 600;
}

.profile-cards .profile-cards-footer {
  border-top: 1px solid #e8eaef;
  background: #f9fafe;
  margin: 0px -10px -10px;
  border-radius: 0 0 7px 7px;
  padding: 13px 0;
}

.profile-cards .social-icons {
  margin: 18px 0;
}

.profile-cards .social-icons i {
  font-size: 19px;
  color: #cacaca;
  padding: 5px;
}

.profile-icon-back {
  position: absolute;
  left: 4%;
  background: #f3f3f3;
  border-radius: 50%;
  color: #b0b2be;
  border: 0.0625rem solid rgba(20, 23, 28, 0.05);
  background-color: rgba(20, 23, 28, 0.05);
  padding: 10px;
  line-height: 1;
}

.profile-icon-back:hover {
  background: rgba(20, 23, 28, 0.1);
  color: #b0b2be;
}

.profile-icon-back i {
  font-size: 24px;
}

@media only screen and (max-width: 768px) {
  .profile-container,
  .profile-container .contents-inner {
    padding: 0;
    padding-bottom: 0;
  }

  .profile-layout {
    display: inline;
  }

  .profile-layout-avature {
    width: auto;
  }

  .profile-layout-avature .user-profile-photo {
    margin: auto;
    width: 150px;
    height: 150px;
  }

  .profile-layout-content {
    text-align: center;
  }

  .profile-layout-content h1 {
    font-size: 23px;
  }

  .profile-layout-content h5 {
    font-size: 13px;
  }

  .profile-layout-content .bio-content {
    display: none;
  }

  .profile-icon-back {
    display: none;
  }
}

/* button option menu  */
.btn-option {
  position: absolute !important;
  top: 8px;
  right: 4px;
  font-size: 21px;
  padding: 5px 3px !important;
  z-index: 1;
}

/* dropdown option menu */
.dropdown-option-nav {
  padding: 0 !important;
  min-width: 185px !important;
  margin-top: 3px !important;
}

.dropdown-option-nav:before {
  content: '';
  position: absolute;
  right: 8px;
  top: -13px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 10px solid #e8e9eb;
  border-color: transparent transparent #eaeaea transparent;
  top: -10px;
}

.uk-dropdown-top-right.dropdown-option-nav:before {
  right: 5px;
  top: -13px;
  border-bottom: 0;
  top: auto;
  bottom: -10px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #d4d4d4;
}

.dropdown-option-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  padding: 6px 0px;
}

.dropdown-option-nav ul li {
  margin: 0;
  padding: 0;
  display: block !important;
}

.dropdown-option-nav .divider-line {
  margin: 4px 0;
  border: 0.3px solid rgba(222, 223, 224, 0.45);
}

.dropdown-option-nav ul li a {
  display: block;
  font-size: 14px;
  transition: 0.3s;
  color: #9799a2;
  padding: 9px 19px;
  font-weight: 600;
}

.dropdown-option-nav ul li a:hover {
  background-color: #f0f5fd;
}

.dropdown-option-nav ul li a i {
  font-size: 19px;
  position: relative;
  top: 1px;
  margin-right: 7px;
  -webkit-font-smoothing: antialiased;
  transition: 0.3s;
}

.dropdown-option-nav ul li a:hover {
  color: #2f3152;
}

@media (max-width: 420px) {
  .btn-option {
    top: -4px;
    right: -5px;
    padding: 5px 3px;
  }
}

/* User Menu Small dropdown  */
ul.dropdown-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  padding: 6px 0px;
}

ul.dropdown-menu li {
  margin: 0;
  padding: 0;
  display: block !important;
}

ul.dropdown-menu li a {
  display: block;
  font-size: 14px;
  transition: 0.3s;
  color: #9799a2;
  padding: 9px 20px;
  font-weight: 600;
}

ul.dropdown-menu li a:hover {
  /* background-color: #f0f5fd; */
  color: #2f3152;
}

ul.dropdown-menu li a i {
  font-size: 18px;
  position: relative;
  top: 1px;
  margin-right: 7px;
  -webkit-font-smoothing: antialiased;
  transition: 0.3s;
}

ul.dropdown-menu .menu-divider {
  margin: 4px 0;
  border: 0.3px solid rgba(222, 223, 224, 0.45);
}

ul.dropdown-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  padding: 6px 0px;
}

ul.dropdown-nav li {
  display: block;
  font-size: 13.5px;
  transition: 0.3s;
  display: flex;
  color: #9799a2;
  padding: 9px 22px;
  justify-content: space-between;
  align-items: center;
}

ul.dropdown-nav li:hover {
  color: #2f3152;
}

ul.dropdown-nav li a i {
  font-size: 18px;
  position: relative;
  top: 1px;
  -webkit-font-smoothing: antialiased;
  transition: 0.3s;
}

ul.dropdown-nav .menu-divider {
  margin: 3px 0;
  border-top: 1px solid rgba(222, 223, 224, 0.45);
  padding: 0px;
}

/* dropdown User Details */
.dropdown-user-details {
  display: flex;
  padding: 21px 16px;
  padding-bottom: 9px;
  border-bottom: 1px solid #e6e6e6;
}

/* dropdown User Details */
.dropdown-user-details {
  display: flex;
  padding: 16px 16px;
  padding-bottom: 9px;
  border-bottom: 1px solid #e6e6e6;
  /* width: 100%; */
}

/* Avatar */
.dropdown-user-avatar {
  display: inline-block;
  border-radius: 50%;
  width: 47px;
  height: 47px;
  position: relative;
}

.dropdown-user-avatar img {
  width: 100%;
  display: inline-block;
  border-radius: 50%;
  image-rendering: -webkit-optimize-contrast;
}

.dropdown-user-name {
  line-height: 20px;
  padding: 2px 0 0 15px;
  width: 100%;
  padding-bottom: 8px;
  font-weight: 600;
  color: #333;
  font-size: 17px;
}

.dropdown-user-details span {
  display: block;
  font-size: 12.7px;
  color: #888;
  font-weight: 500;
}

/* ---------------------------------- */
/* responsive tab
------------------------------------- */

.responsive-tab {
  position: relative;
  z-index: 1;
  clear: both;
  width: 100%;
  height: 52px;

  /* Force Hardware Acceleration */
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  will-change: transform;
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}

.responsive-tab ul,
.responsive-tab li,
.responsive-tab a {
  height: 100%;
}

.responsive-tab ul {
  /* enables a flex context for all its direct children */
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  margin: 0;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.responsive-tab ul::after {
  clear: both;
  content: '';
  display: block;
}

.responsive-tab li {
  display: inline-block;
  float: left;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: relative;
}

.responsive-tab a {
  display: block;
  color: #8e8e8e;
  opacity: 0.6;
  line-height: 50px;
  padding-right: 17px;
  padding-left: 0;
  font-weight: 500;
  font-size: 15px;
  position: relative;
}

.responsive-tab a i {
  font-weight: 700;
  margin-right: 9px;
}

.responsive-tab li a:hover {
  color: #12111b;
  opacity: 0.8;
}

.responsive-tab .uk-active,
.responsive-tab .uk-active a {
  color: #12111b;
  opacity: 1;
}

.uk-light .responsive-tab:before {
  background: transparent;
}

.uk-light .responsive-tab .uk-active a,
.uk-light .responsive-tab li a:hover {
  color: white;
}

.uk-light .responsive-tab .uk-active a:before {
  background-color: white;
}

@media only screen and (min-width: 1024px) {
  .responsive-tab {
    height: 56px;
    overflow: visible;
  }

  /* reset mobile style */
  .responsive-tab ul {
    display: block;
  }

  /* reset mobile style */
  .responsive-tab li {
    float: none;
    -webkit-flex-shrink: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    flex: 1 0;
  }

  .responsive-tab a {
    font-size: 15px;
    margin-right: 20px;
    padding: 0;
    line-height: 55px;
  }
}

/* ---------------------------------- */
/* style-3
------------------------------------- */

@media only screen and (min-width: 1024px) {
  .responsive-tab.style-1:before {
    background: transparent;
  }

  .responsive-tab.style-1 {
    height: 38px;
  }

  .responsive-tab.style-1 li a {
    background: #eaeaea;
    border-radius: 97px;
    line-height: 38px;
    color: black;
    font-size: 13px;
    padding: 0px 22px;
    margin-right: 10px;
  }

  .responsive-tab.style-1 li.uk-active a {
    background-color: #12111b;
    color: white;
  }

  .responsive-tab.style-1 .uk-active a:before {
    background-color: transparent;
  }
}

/* ---------------------------------- */
/* style-2
------------------------------------- */
.responsive-tab.style-2 :before {
  position: absolute;
  bottom: 0;
  content: '';
  width: 100%;
  height: 1px;
  border-radius: 27px;
}

.responsive-tab.style-2 .uk-active a:before {
  width: 100%;
  width: 20%;
  height: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #12111b;
}

.responsive-tab.style-2:before {
  background: transparent;
}

.responsive-tab.style-2 .uk-active {
  color: #0a0a0a;
}

/* ---------------------------------- */
/* style-3
------------------------------------- */
.responsive-tab.style-3 .uk-active a:before,
.responsive-tab.style-3:before {
  display: none;
}

@media only screen and (min-width: 1024px) {
  .responsive-tab.style-3 {
    height: auto;
  }

  .responsive-tab.style-3 li a {
    font-size: 17px;
    font-weight: 600;
    line-height: 44px;
  }

  .responsive-tab.style-3 li {
    display: block;
  }

  .responsive-tab.style-3 ul,
  .responsive-tab.style-3 li,
  .responsive-tab.style-3 a {
    height: 100%;
  }

  .responsive-tab.style-3 li .responsive-tab.style-3 a {
    height: 100%;
  }
}

/* ---------------------------------- */
/* style-4
------------------------------------- */
.responsive-tab.style-4 :before {
  position: absolute;
  bottom: 0;
  content: '';
  background: #e8e8e8;
  width: 100%;
  height: 1px;
  border-radius: 27px;
}

.responsive-tab.style-4 a {
  margin-right: 12px;
  padding: 0 10px;
}

.responsive-tab.style-4 .uk-active a:before {
  position: absolute;
  bottom: 0;
  content: '';
  background: #007791;
  width: 100%;
  height: 5px;
  left: 0;
  background: #12111b;
}

.uk-light .responsive-tab.style-4 .uk-active a:before {
  background-color: white;
}

/* ---------------------------------- */
/* style-5
------------------------------------- */
.responsive-tab.style-5 {
  height: 47px;
}

.responsive-tab.style-5 li a {
  line-height: 47px;
  padding: 0px 20px;
  margin: 0px;
}

.responsive-tab.style-5 li.uk-active a {
  padding: 0px 13px;
  margin: 0px 36px;
  background-color: #edeff0;
  color: #12111b;
}

.responsive-tab.style-5 li.uk-active a:hover {
  background-color: #edeff0;
  opacity: 1;
}

.responsive-tab.style-5 li.uk-active a:before {
  background: url(../images/bg-shapes/tab-border-left.png) no-repeat;
  background-size: cover;
  content: '';
  position: absolute;
  left: -35px;
  top: 0;
  font-size: 31px;
  height: 47px;
  width: 36px;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.responsive-tab.style-5 .uk-active a:after {
  background: url(../images/bg-shapes/tab-border-right.png) no-repeat;
  background-size: cover;
  content: '';
  position: absolute;
  right: -35px;
  top: 0;
  color: #f91629;
  height: 47px;
  width: 36px;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

/* ---------------------------------- */
/* List Styles
------------------------------------- */
.list-3,
.list-2,
.list-1 {
  padding: 3px 0 0 0;
  font-size: 15px;
}

.list-3 li,
.list-2 li,
.list-1 li {
  list-style: none;
  margin: 10px 0;
  line-height: 27px;
  margin-left: 20px;
  position: relative;
}

.list-3 li:first-child,
.list-2 li:first-child,
.list-1 li:first-child {
  margin-top: 0;
}

.list-4 li:before,
.list-3 li:before,
.list-2 li:before,
.list-1 li:before {
  margin: 0;
  position: relative;
  color: #66676b;
  float: left;
  margin-left: -20px;
  display: block;
}

.list-1.gray li:before {
  color: #777;
}

.list-2 li,
.list-2 li {
  margin-left: 28px;
}

.list-3 li:before,
.list-2 li:before {
  font-family: 'Material-Icons';
  content: '\e928';
  font-size: 18px;
  font-weight: 600;
  position: relative;
  top: -2px;
  margin-left: -28px;
}

.list-3 li:before {
  content: '\e912';
  font-family: 'Feather-Icons';
  font-weight: 500;
  font-size: 18px;
}

.list-1 li:before {
  content: '';
  height: 6px;
  width: 6px;
  background-color: #66676b;
  border-radius: 2px;
  position: relative;
  top: 9px;
}

/* ---------------------------------- */
/* setting menu list in admin panel
------------------------------------- */
.setting-menu {
  color: #474747;
}
.setting-menu ul li {
  padding-left: 0;
}
.setting-menu ul li a,
.setting-menu .uk-active.li a {
  padding: 4px 20px;
  margin-right: 0px;
  font-size: 15px !important;
  border: 0;
  text-align: left;
}
.setting-menu ul li a:hover {
  background-color: #ededed;
}

@media (max-width: 960px) {
  .setting-menu ul li a {
    padding: 0px 15px;
  }
  .responsive-tab a i {
    margin-right: 5px;
  }
}

/* ---------------------------------- */
/* Pricing
------------------------------------- */

/* Pricing Plans Container */
.pricing-plans-container {
  border-radius: 4px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  display: flex;
  background: white;
  margin-top: 70px;
}

.pricing-plan {
  flex: 1;
  padding: 35px 17px;
  position: relative;
}

.pricing-plan:first-of-type {
  padding-left: 35px;
}

.pricing-plan:last-of-type {
  padding-right: 35px;
}

.pricing-plan h3 {
  font-size: 22px;
  font-weight: 600;
}

.pricing-plan p {
  margin: 0;
}

/* Pricing Plan Label */
.pricing-plan-label {
  background: #f6f6f6;
  border-radius: 4px;
  font-size: 18px;
  color: #888;
  text-align: center;
  line-height: 24px;
  padding: 15px;
  margin: 22px 0;
}

.pricing-plan-label strong {
  font-size: 32px;
  font-weight: 700;
  color: #12111b;
  margin-right: 5px;
  line-height: 30px;
}

.recommended .pricing-plan-label {
  background-color: rgba(102, 103, 107, 0.06);
  color: #66676b;
}

.recommended .pricing-plan-label strong {
  color: #12111b;
}

/* Pricing Plan Features */
.pricing-plan-features strong {
  color: #333;
  font-weight: 600;
  margin-bottom: 5px;
  line-height: 24px;
  display: inline-block;
  margin-top: 5px;
}

.pricing-plan-features ul {
  padding: 0;
  margin: 0;
}

.pricing-plan-features ul li {
  display: block;
  margin: 0;
  padding: 3px 0;
  line-height: 24px;
}

/* Pricing Plan Button */
.pricing-plan .button:hover,
.pricing-plan.recommended .button {
  color: #fff;
  background-color: #66676b;
  box-shadow: 0 4px 12px rgba(102, 103, 107, 0.15);
}

.pricing-plan .btns {
  color: #12111b;
  background-color: #fff;
  border: 1.5px solid #12111b;
  box-shadow: 0 4px 12px rgba(102, 103, 107, 0.1);
  width: 100%;
  display: inline-block;
  margin: 0;
  padding: 6px 0;
  text-align: center;
  line-height: 28px;
  border-radius: 5px;
  margin-top: 15px;
  font-weight: 600;
}

.pricing-plan .button:hover,
.recommended .btns {
  box-shadow: 0 4px 12px rgba(102, 103, 107, 0.15);
  background: #12111b;
  color: white;
  border-color: #12111b;
}

/* Recommended Plan */
.pricing-plan.recommended {
  box-shadow: 0 0px 45px rgba(0, 0, 0, 0.09);
  padding: 35px;
  margin: 0 15px;
}

.pricing-plan .recommended-badge {
  background-color: #12111b;
  color: #fff;
  position: absolute;
  width: 100%;
  height: 45px;
  top: -45px;
  left: 0;
  text-align: center;
  border-radius: 4px 4px 0 0;
  font-weight: 600;
  line-height: 45px;
}

.pricing-swicher {
  width: max-content;
  padding: 10px !important;
  border-radius: 8px;
  margin: auto !important;
}

.pricing-swicher > :first-child {
  padding-left: 0px !important;
}

.pricing-swicher li {
  padding-left: 10px !important;
  opacity: 0.4;
}

.pricing-swicher li a {
  font-size: 15px !important;
  font-weight: 700;
  position: relative;
  padding: 6px 12px;
}

.pricing-swicher > li.uk-active {
  opacity: 1;
}

.pricing-swicher > .uk-active > a {
  color: #12111b !important;
}

.pricing-swicher li a span {
  top: -10px;
  margin-left: 10px;
  background: #12111b;
  font-size: 11px;
  padding: 3px 5px;
  font-weight: 400;
  color: white;
  border-radius: 4px;
}

/* Media Queries for Pricing Plans */
@media (max-width: 992px) {
  .pricing-plans-container {
    box-shadow: none;
    display: block;
    margin-top: -10px;
  }

  .pricing-plan {
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
    flex: auto;
    border-radius: 4px;
    padding: 35px;
  }

  .pricing-plan.recommended {
    padding: 35px;
    margin: 0 0 40px 0;
  }

  .recommended.pricing-plan {
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    margin-top: 80px;
  }
}

/* Pricing table  */
.bg-pricing {
  background-size: cover;
  position: absolute;
  height: 450px;
  width: 100%;
}

.pricing {
  margin: 40px 0;
}

.pricing h1,
.pricing h5,
.pricing h6 {
  text-align: center;
}

.pricing h5 {
  margin: 20px 0;
  color: #a2a2a2;
}

.pricing .pricing-card {
  background-color: #ffffff;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 2px 10px 0px #0000001a;
  overflow: hidden;
  display: flex;
  max-width: 800px;
  margin: 25px auto;
  align-items: center;
}

.pricing .pricing-card > * {
  flex: 1;
  padding: 35px 17px;
  position: relative;
}

.pricing .pricing-card .plan-name {
  margin-bottom: 0px;
}

.pricing .pricing-card .price {
  font-size: 6rem;
  margin: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.pricing .pricing-card .price span {
  font-size: 18px;
  margin-top: 25px;
  display: block;
  margin-right: 9px;
}

.pricing .pricing-card .price-type {
  display: block;
  margin-bottom: 15px;
  font-weight: 600;
  color: #b1b1b1;
}

.pricing .pricing-card ul.list-2 li {
  margin: 16px 0;
  font-size: 16px;
  font-weight: 600;
}

@media (max-width: 768px) {
  .pricing .pricing-card {
    display: block;
  }

  .pricing .pricing-card > * {
    padding: 30px 17px;
  }

  .pricing .pricing-card ul.list-2 li {
    margin: 13px 0;
    font-size: 15px;
  }
}

/* ---------------------------------- */
/*  embed video responsive 
------------------------------------- */

.embed-video {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.embed-video iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  /* padding: 20px */
}

/* ---------------------------------- */
/* countdown
------------------------------------- */

.countdown .box .countdown-text p {
  margin-bottom: 0;
}

.countdown .box .countdown-text {
  background: #f2f4fb;
  padding: 10px;
  color: #46515a;
  text-align: center;
  font-size: 13px;
}

.countdown .box .uk-countdown-number {
  padding: 25px;
  font-size: 50px;
  color: #525e68;
}

.countdown .box {
  background: white;
  border-radius: 7px;
  overflow: hidden;
}

.countdown-form {
  background: #ffffff;
  padding: 8px 14px !important;
  border-radius: 33px;
}

@media (max-width: 640px) {
  .countdown .box {
    background: white;
    border-radius: 6px;
    overflow: hidden;
  }

  .countdown .box .uk-countdown-number {
    padding: 15px 2px;
    font-size: 24px;
    color: #4f5a64;
  }

  .countdown .box .countdown-text {
    padding: 5px;
    font-size: 9px;
  }

  .countdown-form {
    padding: 8px 14px !important;
  }
}

/* ---------------------------------- */
/* Footer
------------------------------------- */
.footer {
  margin-top: 50px;
  padding: 20px 15px;
  border-top: 1px solid #e4e4e4;
}

.footer p {
  color: #656565;
}

.footer-links ul {
  list-style: none;
  padding: 0;
}

.footer-links.footer-nav ul li {
  display: inline-block !important;
  padding-right: 14px;
}

.footer-links ul li a {
  color: #6f6f6f;
  line-height: 25px;
  padding: 4px 0;
  font-size: 14px;
  font-weight: 600;
}

.footer-links ul li a {
  display: inline-block;
}

.footer-links ul li a:hover {
  color: #000;
}

.footer-description {
  color: #6f6f6f;
  font-weight: 500;
}

.uk-light .footer-links ul li a,
.uk-light .footer-description {
  color: rgba(255, 255, 255, 0.7) !important;
}

.uk-light .footer-links h3,
.uk-light .footer-links ul li a:hover {
  color: white !important;
}

.footer-nav-icon a {
  color: #757575;
  font-weight: 400;
  display: block;
  padding: 0.5rem 0.8rem;
  font-size: 15px;
}

.footer-nav-icon ul {
  list-style: none;
  padding: 0;
}

.footer-nav-icon ul li {
  display: inline-block;
}

.footer-nav-icon ul li a {
  color: #757575;
  font-weight: 400;
  display: block;
  padding: 0.5rem 0.6rem;
  font-size: 15px;
}

.uk-light .footer-nav-icon ul li a {
  color: rgba(255, 255, 255, 0.7);
}

.uk-light .footer-nav-icon ul li a:hover {
  color: white;
}

@media (max-width: 768px) {
  .footer {
    padding: 30px 15px;
    margin-top: 15px;
    padding-bottom: 0px;
  }
}

/* ---------------------------------- */
/* general
------------------------------------- */

.shadow {
  box-shadow: 0px 2px 7px -3px #0000001a;
}

.shadow-0 {
  box-shadow: none;
}

.rounded-sm {
  border-radius: 7px;
}

.rounded {
  border-radius: 8px;
}

.rounded-lg {
  border-radius: 14px;
}

.circle {
  border-radius: 100%;
}

/* course thunbail general img */

.course-thumbnail {
  overflow: hidden;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
  transition: 0.3s;
  position: relative;
  border-radius: 8px;
}

/* play icon */
.course-thumbnail .play-button-trigger {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: url(../images/icon-play.svg) no-repeat;
  background-size: auto 50%;
  background-position: center;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 150ms ease-in-out;
  -moz-transition: opacity 150ms ease-in-out;
  -ms-transition: opacity 150ms ease-in-out;
  -o-transition: opacity 150ms ease-in-out;
  transition: opacity 150ms ease-in-out;
  -webkit-transition: -webkit-transform 150ms ease-in-out;
  -moz-transition: -moz-transform 150ms ease-in-out;
  -o-transition: -o-transform 150ms ease-in-out;
  transition: transform 150ms ease-in-out;
}

.course-thumbnail:hover .play-button-trigger,
.play-button-trigger.show {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

span.item-tag {
  font-size: 12.5px;
  font-weight: 600;
  color: #696969;
  background-color: #fff;
  border-radius: 4px;
  padding: 5px 12px;
  line-height: 21px;
  font-weight: 400;
  margin-bottom: 9px;
  position: absolute;
  top: 30px;
  left: 32px;
  z-index: 110;
  transition: 0.4s;
}

/* ---------------------------------- */
/* animation   
------------------------------------- */

.transition-3d-hover,
.animate-this {
  transition: all 0.2s ease-in-out;
}

.transition-3d-hover:hover,
.transition-3d-hover:focus {
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}

.animate-this:hover,
.animate-this:focus {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

/* ---------------------------------- */
/* Back to top
------------------------------------- */
#backtotop {
  position: fixed;
  right: 0;
  opacity: 0;
  visibility: hidden;
  bottom: 25px;
  margin: 0 25px 0 0;
  z-index: 999;
  transition: 0.35s;
  transform: translateY(10px);
}

#backtotop.visible {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

#backtotop a {
  text-decoration: none;
  border: 0 none;
  display: block;
  width: 46px;
  height: 46px;
  background-color: #12111b;
  opacity: 1;
  transition: all 0.3s;
  border-radius: 100%;
  text-align: center;
  font-size: 26px;
}

body #backtotop a {
  color: #fff;
}

#backtotop a:after {
  content: '\e931';
  font-family: 'Feather-Icons';
  position: relative;
  display: block;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media (max-width: 768px) {
  #backtotop {
    display: none;
  }
}

/* books*/

.book-card .book-cover {
  display: block;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  height: 240px;
}

.book-card .book-cover img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.book-card .book-content {
  margin-top: 6px;
}

.book-card .book-content h5 {
  font-weight: 500;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 16px;
}

@media (max-width: 960px) {
  .book-card .book-cover {
    height: 180px;
  }
}

#book-popular .book-popular-card > * {
  width: auto;
}

#book-popular .book-popular-card {
  border: 0;
  box-shadow: 0 4px 11px -4px rgba(0, 0, 0, 0.2);
  margin: 24px 0 27px;
  padding: 16px 9px;
  padding-right: 15px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

#book-popular .book-popular-card .book-details {
  margin-left: 12px;
  flex: 1;
  min-width: 1px;
}

#book-popular .book-popular-card .book-details h4 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  color: #393939;
  font-size: 15px;
  margin: 0;
  transition: all 0.2s;
}

#book-popular .book-popular-card .book-details p {
  margin: 0;
  font-size: 13px;
  color: #8d8d8d;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 4px;
  font-weight: 500;
  line-height: 1;
  transition: all 0.2s;
}

#book-popular .book-popular-card .cover-img {
  border-radius: 6px;
  width: 65px;
  height: 78px;
  margin-top: -24px;
  box-shadow: 0 3px 11px -2px rgba(0, 0, 0, 0.33);
}

@media (max-width: 768px) {
  .book-card-grid .book-card .book-cover {
    height: 200px;
  }
}

/* grid slider header and their icon sliders */
.grid-slider-header {
  margin-bottom: 10px;
}

.grid-slider-header h4 {
  overflow: hidden;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0 !important;
  line-height: 33px;
}

.grid-slider-header,
.grid-slider-header-link {
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 0 !important;
}

.grid-slider-header .slide-nav-prev.uk-invisible,
.grid-slider-header .slide-nav-next.uk-invisible {
  visibility: visible !important;
  opacity: 0.15;
  color: white;
  cursor: default;
}

.grid-slider-header .slide-nav-prev,
.grid-slider-header .slide-nav-next {
  background: #12111b;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  position: relative;
  margin-left: 5px;
  color: #ffffff;
}

.grid-slider-header .slide-nav-prev:hover,
.grid-slider-header .slide-nav-next:hover {
  background-color: #12111b;
  color: #fff;
}

.grid-slider-header .slide-nav-prev:before,
.grid-slider-header .slide-nav-next:before {
  font-family: 'Feather-Icons';
  font-weight: 600;
  font-size: 19px;
  line-height: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.grid-slider-header .slide-nav-prev:before {
  content: '\e92f';
}

.grid-slider-header .slide-nav-next:before {
  content: '\e930';
}

@media (max-width: 768px) {
  .grid-slider-header,
  .grid-slider-header-link {
    padding-right: 10px !important;
  }

  .grid-slider-header .slide-nav-prev,
  .grid-slider-header .slide-nav-next {
    width: 32px;
    height: 32px;
  }
}

/* Slider custom icon */

.slidenav-next,
.slidenav-prev {
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 38px !important;
  font-weight: 700;
  height: 38px;
  line-height: 38px;
  color: #999;
  border: 1px solid rgba(20, 23, 28, 0.05);
  border-radius: 100%;
  background-color: white;
}

.slidenav-next:hover,
.slidenav-prev:hover {
  background-color: rgba(20, 23, 28, 0.05);
}

.slidenav-next:before,
.slidenav-prev:before {
  font-family: 'Feather-Icons';
  font-weight: 600;
  text-align: center;
  margin: 0;
  margin-left: 0;
  color: #7d7d7d;
  position: absolute;
  font-size: 19px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slidenav-prev:before {
  content: '\e92f';
}

.slidenav-next:before {
  content: '\e930';
}

/* if they are inside the card */
.uk-position-center-left.slidenav-prev,
.uk-position-center-right.slidenav-next {
  background-color: white;
  box-shadow: 0 0 8px -6px black;
}

.uk-position-center-left.slidenav-prev {
  margin-left: 0px !important;
}

.uk-position-center-right.slidenav-next {
  margin-right: 0px !important;
}

@media (max-width: 768px) {
  .slidenav-next,
  .slidenav-prev {
    background: white;
  }

  .uk-position-small[class*='slidenav-next'],
  .uk-position-small[class*='slidenav-prev'] {
    margin-left: -10px;
    margin-right: -10px;
  }
}

/* Search overlay */

.is-active .search-input-wrapper {
  left: 80px;
  display: inline;
}

.search-input-wrapper {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
}

.search-overlay {
  position: fixed;
  top: -100px;
  left: -100px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.9);
  transition: all 0.4s;
  -webkit-transform: scale(1);
  transform: scale(1);
  z-index: 1000;
}

.is-active .search-overlay {
  -webkit-transform: scale(50);
  transform: scale(50);
}

.search-input-container {
  position: relative;
  top: 25%;
  padding: 0 18%;
}

.search-input-control {
  position: relative;
  width: 100%;
  transition: all 1.3s 1s;
  font-size: 1rem;
  position: relative;
  text-align: left;
  transition-duration: 1.2s;
}

.search-input-control input {
  width: 94%;
  height: 155px;
  padding-right: 43px;
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0;
  border-bottom: 3px solid #2b2d3a;
  background: transparent;
  font-size: 5rem;
  font-weight: 200;
  font-family: 'Alegreya Sans SC', sans-serif;
  text-transform: uppercase;
  padding-bottom: 10px;
  outline: none;
}

.search-input-control .search-help {
  display: block;
  color: #1b245f;
  font-size: 1.3rem;
  padding-top: 20px;
}

.search-input-control .btn-close {
  font-size: 30px;
  position: absolute;
  right: 0;
  top: 16px;
  z-index: 10;
  cursor: pointer;
  padding: 10px;
  padding-right: 0;
}

/* ---------------------------------- */
/* syntax highlighter
------------------------------------- */

.hljs-string,
.hljs-bullet {
  color: #4fc1ea;
}

.hljs {
  display: block;
  overflow-x: auto;
  padding: 1.5em;
  background: #f3f3f3;
  color: #9c9c9c;
  margin: -10px;
}

.hljs-name,
.hljs-strong {
  /* font-weight: bold; */
  color: #f35a00;
}

.hljs-attr {
  color: #68bb88;
}

/* ---------------------------------- */
/* btn source code previewer
------------------------------------- */

.Markup-botton {
  padding-left: 44px !important;
  font-size: 14px !important;
  background: #f0f0f0;
  text-transform: capitalize !important;
  color: #565656;
  border: 0 !important;
  overflow: hidden;
  cursor: pointer;
  outline: 0;
}

.Markup-botton::before {
  font-family: 'Feather-Icons';
  font-size: 14px;
  content: '\e940';
  left: 0px;
  width: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  top: 0px;
  color: white;
  background: #bdbcbc;
  position: absolute;
}

.Markup-botton:hover,
.Markup-botton:focus {
  background: #c7c7c7;
}

@media (min-width: 1099px) {
  .Markup-botton {
    margin: 20px 0 !important;
  }
}

.docs-list a {
  color: #999 !important;
  padding: 3px 0 !important;
  font-size: 0.9rem !important;
  display: block;
}

.docs-list a:hover,
.docs-list a:active,
.docs-list a:focus {
  color: #000 !important;
}

/* ---------------------------------- */
/* Background   
------------------------------------- */
.bg-primary {
  background-color: #377dff !important;
}

.bg-secondary {
  background-color: #77838f !important;
}

.bg-success {
  background-color: #62d76b !important;
}

.bg-info {
  background-color: #00dffc !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

.bg-danger {
  background-color: #ff3b30 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.bg-grey {
  background-color: #12111b !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-dark {
  background-color: #000 !important;
}

.bg-transparent {
  background-color: transparent !important;
}

/* ---------------------------------- */
/* gradient Backgrounds  
------------------------------------- */
.bg-transparent {
  background-color: transparent !important;
}

.bg-gradient-primary {
  background: linear-gradient(80deg, #78a6fc, #377dff 100%) !important;
}

.bg-gradient-secondary {
  background: linear-gradient(80deg, #fafafa 0, #fafafa 100%) !important;
}

.bg-gradient-success {
  background: linear-gradient(80deg, #4cd964 0, #50d94c 100%) !important;
}

.bg-gradient-info {
  background: linear-gradient(80deg, #24b7fa 0, #24e2fa 100%) !important;
}

.bg-gradient-warning {
  background: linear-gradient(80deg, #ff9500 0, #ff6200 100%) !important;
}

.bg-gradient-danger {
  background: linear-gradient(80deg, #ff3b30 0, #ff304e 100%) !important;
}

.bg-gradient-light {
  background: linear-gradient(80deg, #ced4da 0, #ced6da 100%) !important;
}

.bg-gradient-grey {
  background: linear-gradient(
    80deg,
    rgb(62, 71, 79) 0,
    rgb(95, 109, 121) 100%
  ) !important;
}

.bg-gradient-dark {
  background: linear-gradient(80deg, #05172a 0, #051e2a 100%) !important;
}

.bg-gradient-1 {
  background-color: rgba(62, 62, 62, 0.52);
  background-blend-mode: color-burn;
}

.bg-gradient-2 {
  background-image: linear-gradient(
    -225deg,
    #5d9fff 0%,
    #b8dcff 48%,
    #6bbbff 100%
  );
  background-blend-mode: color-burn;
}

.bg-gradient-3 {
  background-image: linear-gradient(to top, #0d8464 0%, #4c8bb1 100%);
  background-blend-mode: color-burn;
}

.bg-color-burn {
  background-color: rgba(62, 62, 62, 0.52);
  background-blend-mode: color-burn;
}

.bg-image-holder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  border-radius: inherit;
}

/* ---------------------------------- */
/* topic- Backgrounds  
------------------------------------- */

.topic-1 {
  background-color: #3659a2 !important;
}

.topic-2 {
  background-color: #9b3b5a !important;
}

.topic-3 {
  background-color: #890ca1 !important;
}

.topic-4 {
  background-color: #30826c !important;
}

.topic-5 {
  background-color: #4a4290 !important;
}

.topic-6 {
  background-color: rgb(122, 130, 48) !important;
}

.topic-7 {
  background-color: rgb(201, 37, 146) !important;
}

.topic-8 {
  background-color: rgb(130, 97, 48) !important;
}

.topic-9 {
  background-color: #30826c !important;
}

.topic-10 {
  background-color: rgb(130, 48, 48) !important;
}

/* ---------------------------------- */
/* Background Soft Colors  
------------------------------------- */
.bg-soft-primary {
  background-color: rgba(55, 125, 255, 0.1) !important;
}

.bg-soft-secondary {
  background-color: rgba(119, 131, 143, 0.1) !important;
}

.bg-soft-success {
  background-color: rgba(0, 201, 167, 0.1) !important;
}

.bg-soft-info {
  background-color: rgba(0, 223, 252, 0.1) !important;
}

.bg-soft-warning {
  background-color: rgba(255, 193, 7, 0.1) !important;
}

.bg-soft-danger {
  background-color: rgba(222, 68, 55, 0.1) !important;
}

.bg-soft-light {
  background-color: rgba(248, 249, 250, 0.1) !important;
}

.bg-soft-dark {
  background-color: rgba(30, 32, 34, 0.1) !important;
}

.bg-soft-white {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.bg-soft-indigo {
  background-color: rgba(45, 21, 130, 0.1) !important;
}

.bg-soft-facebook {
  background-color: rgba(59, 89, 152, 0.1) !important;
}

.bg-soft-google {
  background-color: rgba(209, 65, 48, 0.1) !important;
}

.bg-soft-twitter {
  background-color: rgba(29, 161, 242, 0.1) !important;
}

.bg-soft-instagram {
  background-color: rgba(63, 114, 155, 0.1) !important;
}

.bg-soft-github {
  background-color: rgba(36, 41, 46, 0.1) !important;
}

.bg-soft-gray {
  background-color: rgba(248, 250, 253, 0.1) !important;
}

.bg-color-burn {
  background-color: rgba(62, 62, 62, 0.52);
  background-blend-mode: color-burn;
}

/* ---------------------------------- */
/* button  
------------------------------------- */

.but ton {
  font-weight: 600;
  text-transform: capitalize;
  display: inline-block;
  user-select: none;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  border: 1.5px solid transparent;
  position: relative;
  color: #223ca9;
  background: #f2f4fb;
  font-size: 14px;
  line-height: 35px;
  padding: 0 20px;
  border-radius: 6px;
  margin: 0 5px;
  height: 36px;
}

/* ---------------------------------- */
/* Button size  
------------------------------------- */
.button.small {
  font-size: 13px;
  line-height: 30px;
  padding: 0 17px;
  height: 32px;
}

.button.large {
  font-size: 15px;
  line-height: 45px;
  padding: 0px 23px;
  height: 48px;
}

.button.xlarge {
  font-size: 15px;
  line-height: 45px;
  padding: 0px 22px;
  height: 47px;
}

/* ---------------------------------- */
/* delimiter  
------------------------------------- */
.delimiter:before {
  left: 50%;
  display: block;
  width: 80%;
  margin: auto;
  height: 1px;
  content: '';
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0.2) 0,
    rgba(255, 255, 255, 0) 75%
  );
}

.delimiter {
  border: 0 !important;
}

.delimiter-bottom,
.delimiter-top {
  position: relative;
}

.delimiter-top:before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 80%;
  height: 1px;
  margin-left: -40%;
  content: '';
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0.2) 0,
    rgba(255, 255, 255, 0) 75%
  );
}

.delimiter-bottom:after {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: block;
  width: 80%;
  height: 1px;
  margin-left: -40%;
  content: '';
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0.2) 0,
    rgba(255, 255, 255, 0) 75%
  );
}

/* ---------------------------------- */
/* admin chats
------------------------------------- */
.chats-container {
  margin: 0;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  background-color: #fff;
}

/* Scrollbar Adjustments */
.chats-inbox ul {
  max-height: 875px;
}

.message-content-inner {
  max-height: 750px;
}

.message-content,
.chats-container-inner .chats-inbox {
  /* overflow: hidden; */
}

.message-content-inner,
.chats-container-inner .chats-inbox ul {
  overflow-y: scroll;
  width: calc(100% + 18px);
}

.message-content-inner::-webkit-scrollbar,
.chats-container-inner .chats-inbox ul::-webkit-scrollbar {
  width: 17px;
}

/*  Scrollbar Hide - End */

.chats-headline {
  max-height: 82px;
  font-size: 16px;
  margin: 0;
  padding: 22px 30px;
  color: #333;
  background-color: #fff;
  display: block;
  border-bottom: 1px solid #eaeaea;
  border-radius: 4px 4px 0 0;
  position: relative;
}

.chats-headline input {
  background-color: #f6f6f6;
  box-shadow: none;
  height: 38px;
  padding: 14px;
  font-size: 14px;
  margin: 0;
}

.chats-headline input:focus {
  box-shadow: none;
}

.chats-headline .input-with-icon i {
  font-size: 18px;
  right: 14px;
}

.chats-headline h4 {
  font-size: 16px;
  color: #333;
  padding: 6px 0;
  padding-bottom: 0;
  margin: 0;
  font-weight: 600;
  display: inline-block;
}

.chats-headline h4 span {
  display: block;
  font-size: 10px;
}

.message-action {
  border-radius: 50px;
  font-size: 13px;
  color: #666;
  font-weight: 500;
  display: inline-block;
  transition: 0.3s;
  color: #666;
  padding: 0;
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
}

.message-action i {
  position: relative;
  top: 1px;
  margin-right: 1px;
}

/* List */
.chats-inbox ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.message-avatar img {
  display: inline-block;
  width: 54px;
  height: 54px;
  border-radius: 50%;
}

.chats-inbox ul li a {
  position: relative;
  display: block;
  padding: 30px;
}

.chats-inbox ul li {
  transition: 0.2s;
  position: relative;
}

.chats-inbox ul li:nth-child(2n) {
  /* background-color: #fafafa; */
}

.chats-inbox ul li:last-child {
  border-bottom: none;
}

.chats-inbox ul li.active-message {
  background-color: #fafafa;
}

.chats-inbox ul li:before {
  position: absolute;
  top: 0;
  left: -4px;
  width: 0.25rem;
  height: 100%;
  background-color: #00d27a;
  display: block;
  content: '';
  opacity: 0;
  transition: 0.3s;
}

.chats-inbox ul li:hover:before,
.chats-inbox ul li.active-message:before {
  opacity: 1;
  left: 0px;
}

.chats-inbox .message-avatar {
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
}

.status-icon.status-online,
.status-online:after {
  background-color: #38b653;
}

.status-icon,
.user-avatar:after {
  position: absolute;
  content: '';
  height: 12px;
  width: 12px;
  background-color: #c0c0c0;
  bottom: 0;
  right: 0;
  display: block;
  border: 2px solid #fff;
  border-radius: 50%;
}

.chats-inbox .message-by {
  margin-left: 85px;
  font-size: 15px;
  color: #666;
}

.chats-inbox .message-by p {
  margin: 0;
  padding: 0;
  line-height: 25px;
  font-size: 15px;
}

.message-by-headline h5,
.message-by-headline span {
  display: inline-block;
}

.message-by-headline span {
  float: right;
  color: #969595;
  font-size: 14px;
}

.message-by-headline h5 {
  font-weight: 600;
  color: #888;
  margin: 0 0 7px 0;
  padding: 0;
}

.message-by-headline:after {
  content: '.';
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.message-by h5 i {
  background-color: #66676b;
  border-radius: 4px;
  line-height: 20px;
  font-size: 12px;
  color: #fff;
  font-style: normal;
  padding: 3px 8px;
  margin-left: 3px;
}

/* Single Message */
.chats-container-inner {
  display: flex;
}

.chats-container-inner .chats-inbox,
.chats-container-inner .message-content {
  flex: 1;
}

.chats-container-inner .message-content-inner {
  padding: 30px;
  padding-bottom: 0;
  flex-grow: 1;
}

.chats-container-inner .message-content {
  display: flex;
  flex-direction: column;
}

.chats-container-inner .chats-inbox {
  max-width: 340px;
  border-right: 1px solid #eaeaea;
}

.chats-container-inner .chats-inbox ul li a {
  padding: 17px 20px;
}

.chats-container-inner .chats-inbox .message-by p {
  height: 26px;
  max-width: 205px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  color: #888;
}

.chats-container-inner .message-avatar img {
  width: 40px;
  height: 40px;
}

.chats-container-inner .chats-inbox .message-avatar {
  left: 25px;
}

.chats-container-inner .chats-inbox .message-by {
  margin-left: 60px;
}

.chats-container-inner .chats-inbox .message-readed {
  float: right;
  margin-top: -21px;
  color: #0ae489;
}

.chats-container-inner .message-by-headline h5 {
  font-size: 14px;
  margin-bottom: 2px;
  max-width: 160px;
  line-height: 23px;
}

.chats-container-inner .message-by-headline span {
  font-size: 12.5px;
  position: absolute;
  top: 20px;
  right: 25px;
}

.chats-container-inner .active-message .message-by-headline h5,
.chats-container-inner .active-message .message-by p {
  color: black;
}

/* Message Bubbles */
.message-bubble {
  display: block;
  position: relative;
  margin-bottom: 20px;
}

.message-bubble .message-avatar {
  position: absolute;
  left: 0;
  top: 0;
}

.message-bubble .message-avatar img {
  width: 50px;
  height: 50px;
}

.message-bubble .message-text {
  margin-left: 62px;
  background-color: #f4f4f4;
  border-radius: 4px;
  padding: 10px 16px;
  position: relative;
  display: inline-block;
  float: left;
  line-height: 23px;
  max-width: 70%;
}

.message-bubble.me .message-text {
  float: right;
}

.message-bubble .message-text p {
  font-size: 15px;
  padding: 0;
  margin: 0;
  line-height: 25px;
}

/* Message Bubble "me" */
.message-bubble.me .message-avatar {
  left: auto;
  right: 0;
}

.message-bubble.me .message-text {
  margin-left: 0;
  margin-right: 62px;
  background-color: #12111b;
  color: #fff;
  position: relative;
}

/* Arrow */
.message-bubble .message-text:before {
  content: '';
  width: 0;
  height: 0;
  border-top: 0px solid transparent;
  border-bottom: 11px solid transparent;
  border-right: 10px solid #f4f4f4;
  left: -8px;
  right: auto;
  top: 0px;
  position: absolute;
}

.message-bubble.me .message-text:before {
  border-top: 0px solid transparent;
  border-bottom: 11px solid transparent;
  border-left: 9px solid #12111b;
  border-right: none;
  right: -7px;
  left: auto;
}

/* Message Time Sign */
.message-time-sign {
  text-align: center;
  display: block;
  position: relative;
  margin-bottom: 25px;
}

.message-time-sign span {
  background-color: #fff;
  font-size: 14px;
  color: #888;
  z-index: 11;
  position: relative;
  padding: 0 10px;
}

/* Reply */
.message-reply {
  border-top: 1px solid #eaeaea;
  padding: 30px;
  display: flex;
  align-items: flex-start;
  margin-top: 15px;
}

.message-reply textarea {
  min-width: auto;
  height: 48px;
  min-height: 48px;
  padding: 10px 0;
  margin: 0 15px 0 0;
  flex: 1;
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  box-shadow: none;
  border: none;
  box-sizing: border-box;
  resize: none;
}

.message-reply button {
  border-radius: 4px;
  flex: auto;
  flex-grow: 0;
  height: 44px;
  text-align: center;
  align-self: center;
  padding: 0 25px;
}

.message-reply i {
  padding: 0;
  margin: 0;
}

/* Typing Indicastor */
.typing-indicator {
  width: auto;
  height: 24px;
  padding-top: 8px;
}

.typing-indicator span {
  height: 5px;
  width: 5px;
  float: left;
  margin: 0 1.5px;
  background-color: #060606;
  display: block;
  border-radius: 50%;
  opacity: 0.4;
}

.typing-indicator span:nth-of-type(1) {
  animation: 1s blink infinite 0.33333s;
}

.typing-indicator span:nth-of-type(2) {
  animation: 1s blink infinite 0.66666s;
}

.typing-indicator span:nth-of-type(3) {
  animation: 1s blink infinite 0.99999s;
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}

/* chats Media Queries */
@media (max-width: 992px) {
  .chats-container-inner .chats-inbox {
    max-width: 300px;
  }
}

@media (max-width: 768px) {
  .chats-container-inner {
    display: block;
  }

  .chats-container-inner .chats-inbox {
    max-width: 100%;
    border-bottom: 1px solid #e4e4e4;
    border-right: none;
  }

  .chats-inbox ul {
    max-height: 500px;
  }

  .chats-container-inner .message-by-headline span {
    position: relative;
    top: 0;
    right: 0;
    display: block;
    float: none;
    padding: 0;
    margin: -4px 0 0 0;
  }

  .message-action {
    display: block;
    position: relative;
    right: 0;
    top: 0;
    transform: none;
    margin-top: -5px;
  }

  .chats-headline {
    max-height: 120px;
  }
}

/* ---------------------------------- */
/* Navigation for horizontal layout
------------------------------------- */

#navigation {
  margin-left: 10px;
}

#navigation ul {
  padding: 0;
  margin: 0;
}

* #navigation ul li {
  display: inline-block;
}

#navigation ul li {
  position: relative;
  float: left;
  /* margin: 0; */
  padding: 0;
}

#navigation ul > li > a {
  color: #b0b2be;
  transition: 0.1s ease-in-out;
  transition-property: color, background-color;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  line-height: 70px;
  padding: 0 13px;
  font-size: 17px;
  font-weight: 600;
}

#navigation li > a:hover,
#navigation li .nav-active {
  color: #12111b;
}

.uk-light #navigation ul > li > a {
  color: rgba(255, 255, 255, 0.77);
}

.uk-light#navigation li > a:hover,
.uk-light#navigation li .nav-active {
  color: white;
}

#navigation ul > li > a i {
  padding-right: 7px;
}

#navigation ul li ul {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 100%;
  left: 9px;
  padding: 0;
  z-index: 99999;
}

#navigation ul ul li {
  float: none;
}

#navigation ul ul li a {
  color: #b0b2be !important;
  display: block;
  text-decoration: none;
  font-size: 15px;
  font-weight: 600;
  transition: 0.3s;
}

#navigation ul ul li a:hover {
  background-color: #f0f5fd;
  color: #2f3152 !important;
}

#navigation ul ul ul {
  top: 0;
  left: 100%;
  margin: 0 0 0 15px;
}

#navigation ul ul {
  margin: 0;
}

#navigation ul ul ul {
  pointer-events: none;
}

#navigation ul ul li:hover > ul {
  pointer-events: all;
}

#navigation ul ul ul:after {
  content: '';
  position: absolute;
  width: 15px;
  height: 100%;
  left: -15px;
  top: 0;
  opacity: 0;
}

#navigation ul li:hover > ul {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

/* if the header is transparent chenge the navigation link  to light */
.uk-light #navigation li .nav-item {
  color: rgba(255, 255, 255, 0.69);
}

.uk-light #navigation > li > .nav-item:hover,
.uk-light #navigation > li > .nav-active {
  color: white;
}

#navigation .angle-right:after {
  content: '\f105';
  font-family: 'Font Awesome ';
  position: absolute;
  font-size: 16px;
  color: #bbb;
  opacity: 1;
  right: 25px;
}

/* Indicator Arrow */
.uk-navbar-nav li a:after,
.uk-navbar-nav ul ul li a:after,
#navigation ul li a:after,
#navigation ul ul li a:after {
  font-family: 'Feather-Icons';
  opacity: 0.5;
  font-size: 12px;
}

.uk-light .uk-navbar-nav li a:after,
.uk-light #navigation ul li a:after {
  opacity: 1;
}

.uk-navbar-nav li a:after,
#navigation ul li a:after {
  content: '\e92e';
  padding-left: 7px;
}

.uk-navbar-nav ul li a:after,
#navigation ul ul li a:after {
  content: '\e930';
  position: absolute;
  color: #bbb;
  opacity: 1;
  right: 17px;
  font-size: 16px;
}

.uk-navbar-nav ul li a:only-child:after,
.uk-navbar-nav ul ul li a:only-child:after,
#navigation ul li a:only-child:after,
#navigation ul ul li a:only-child:after {
  content: '';
  display: none;
}

/* Dropdown Styles
------------------------------------- */
#navigation ul ul {
  background-color: #fff;
  box-shadow: 0px 2px 7px -1px rgba(0, 0, 0, 0.06);
  border-radius: 7px;
}

#navigation ul ul li {
  border-radius: 4px;
}

#navigation ul ul li:last-child {
  border-bottom: none;
}

#navigation ul li:hover ul a,
#navigation ul ul a {
  padding: 7px 35px 7px 20px;
  line-height: 26px;
}

#navigation ul ul:after {
  content: '';
  position: absolute;
  left: 25px;
  top: -7px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ffffff;
}

body #navigation ul ul {
  padding: 9px 0;
  box-sizing: border-box;
  margin-top: 12px;
  box-shadow: 0px 2px 9px -3px #0000002b;
}

body #navigation ul ul ul {
  margin-top: 0;
}

#navigation ul ul ul:before {
  width: calc(100% + 15px);
  left: -15px;
}

#navigation ul ul li {
  width: 215px;
}

#navigation ul ul li:hover {
  border-radius: 0;
}

#navigation ul ul li:first-child {
  border-radius: 4px 4px 0 0;
}

#navigation ul ul li:last-child {
  border-radius: 0 0 4px 4px;
}

#navigation ul ul {
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s;
  transform: translate3d(0, 15px, 0);
}

#navigation ul ul ul {
  transform: translate3d(15px, 0, 0);
}

#navigation ul li ul {
  z-index: 10;
}

#navigation ul li:hover ul {
  z-index: 20;
}

#navigation ul li:hover > ul {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

#navigation ul ul li {
  margin: 0;
  font-size: 14.6px;
}

/* nav course dropdown */
#navigation ul ul.nav-large {
  width: 446px;
  padding: 5px;
}

/* ---------------------------------- */
/* mmenu.js - mobile menu
------------------------------------- */

.mm-hidden {
  display: none !important;
}

.mm-wrapper {
  overflow-x: hidden;
  position: relative;
}

.mm-menu {
  box-sizing: border-box;
  background: inherit;
  display: block;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}

.mm-panels,
.mm-panels > .mm-panel {
  background: inherit;
  border-color: inherit;
  box-sizing: border-box;
  margin: 0;
  position: absolute;
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  z-index: 0;
}

.mm-panels {
  overflow: hidden;
}

.mm-panel {
  -webkit-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
}

.mm-panel.mm-opened {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-panel.mm-subopened {
  -webkit-transform: translate(-30%, 0);
  -ms-transform: translate(-30%, 0);
  transform: translate(-30%, 0);
  -webkit-transform: translate3d(-30%, 0, 0);
  transform: translate3d(-30%, 0, 0);
}

.mm-panel.mm-highest {
  z-index: 1;
}

.mm-panel.mm-noanimation {
  -webkit-transition: none !important;
  transition: none !important;
}

.mm-panel.mm-noanimation.mm-subopened {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-panels > .mm-panel {
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 20px;
}

.mm-panels > .mm-panel.mm-hasnavbar {
  padding-top: 40px;
}

.mm-panels > .mm-panel:not(.mm-hidden) {
  display: block;
}

.mm-panels > .mm-panel:after,
.mm-panels > .mm-panel:before {
  content: '';
  display: block;
  height: 20px;
}

.mm-vertical .mm-panel {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
}

.mm-listview .mm-vertical .mm-panel,
.mm-vertical .mm-listview .mm-panel {
  display: none;
  padding: 10px 0 10px 10px;
}

.mm-listview .mm-vertical .mm-panel .mm-listview > li:last-child:after,
.mm-vertical .mm-listview .mm-panel .mm-listview > li:last-child:after {
  border-color: transparent;
}

.mm-vertical li.mm-opened > .mm-panel,
li.mm-vertical.mm-opened > .mm-panel {
  display: block;
}

.mm-listview > li.mm-vertical > .mm-next,
.mm-vertical .mm-listview > li > .mm-next {
  box-sizing: border-box;
  height: 40px;
  bottom: auto;
}

.mm-listview > li.mm-vertical.mm-opened > .mm-next:after,
.mm-vertical .mm-listview > li.mm-opened > .mm-next:after {
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
  right: 19px;
}

.mm-btn {
  box-sizing: border-box;
  width: 40px;
  height: 50px;
  position: absolute;
  top: 0;
  z-index: 1;
}

.mm-clear:after,
.mm-clear:before,
.mm-close:after,
.mm-close:before {
  content: '';
  border: 2px solid transparent;
  display: block;
  width: 5px;
  height: 5px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.mm-clear:before,
.mm-close:before {
  border-right: none;
  border-bottom: none;
  right: 18px;
}

.mm-clear:after,
.mm-close:after {
  border-left: none;
  border-top: none;
  right: 25px;
}

.mm-next:after,
.mm-prev:before {
  content: '';
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  display: block;
  width: 8px;
  height: 8px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
}

.mm-prev:before {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 23px;
  right: auto;
}

.mm-next:after {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  right: 23px;
  left: auto;
}

.mm-navbar {
  border-bottom: 1px solid;
  border-color: inherit;
  text-align: center;
  line-height: 30px;
  height: 50px;
  padding: 0 40px;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.mm-navbar > * {
  display: block;
  padding: 10px 0;
}

.mm-navbar a,
.mm-navbar a:hover {
  text-decoration: none;
}

.mm-navbar .mm-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mm-navbar .mm-btn:first-child {
  left: 0;
}

.mm-navbar .mm-btn:last-child {
  text-align: right;
  right: 0;
}

.mm-panel .mm-navbar {
  display: none;
}

.mm-panel.mm-hasnavbar .mm-navbar {
  display: block;
  background-color: #262626;
}

.mm-listview,
.mm-listview > li {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0;
}

.mm-listview {
  font: inherit;
  font-size: 14px;
  line-height: 20px;
}

.mm-listview a,
.mm-listview a:hover {
  text-decoration: none;
}

.mm-listview > li {
  position: relative;
}

.mm-listview > li,
.mm-listview > li .mm-next,
.mm-listview > li .mm-next:before,
.mm-listview > li:after {
  border-color: inherit;
}

.mm-listview > li {
  border-bottom: 1px solid #404040;
}

.mm-listview > li,
.mm-listview > li > span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: inherit;
  display: block;
  padding: 15px 10px 15px 23px !important;
  margin: 0;
}

.mm-listview > li:not(.mm-divider):after {
  content: '';
  border-bottom-width: 1px;
  border-bottom-style: solid;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
}

.mm-listview > li:not(.mm-divider):after {
  left: 20px;
}

.mm-listview .mm-next {
  background: rgba(3, 2, 1, 0);
  width: 50px;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}

.mm-listview .mm-next:before {
  content: '';
  border-left-width: 1px;
  border-left-style: solid;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.mm-listview .mm-next + a,
.mm-listview .mm-next + span {
  margin-right: 50px;
}

.mm-listview .mm-next.mm-fullsubopen {
  width: 100%;
}

.mm-listview .mm-next.mm-fullsubopen:before {
  border-left: none;
}

.mm-listview .mm-next.mm-fullsubopen + a,
.mm-listview .mm-next.mm-fullsubopen + span {
  padding-right: 50px;
  margin-right: 0;
}

.mm-panels > .mm-panel > .mm-listview {
  margin: 20px -20px;
}

.mm-panels > .mm-panel > .mm-listview:first-child,
.mm-panels > .mm-panel > .mm-navbar + .mm-listview {
  margin-top: -10px;
}

.mm-menu {
  background: #2a2a2a;
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.mm-menu .mm-navbar a,
.mm-menu .mm-navbar > * {
  color: #fff;
  font-weight: 600;
  font-size: 16px;
}

.mm-menu .mm-btn:after,
.mm-menu .mm-btn:before {
  border-color: rgba(255, 255, 255, 0.8);
}

.mm-menu .mm-listview {
  border-color: #2a2a2a;
}

.mm-menu .mm-listview > li .mm-next:after {
  border-color: rgba(255, 255, 255, 0.8);
}

.mm-menu .mm-listview > li a:not(.mm-next) {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.5);
  tap-highlight-color: rgba(255, 255, 255, 0.5);
}

.mm-menu .mm-listview > li.mm-selected > a:not(.mm-next),
.mm-menu .mm-listview > li.mm-selected > span {
  background: rgba(255, 255, 255, 0.05);
}

.mm-menu .mm-listview > li {
  transition: 0.2s;
}

.mm-menu .mm-listview > li:hover {
  background: rgba(255, 255, 255, 0.03);
}

.mm-menu .mm-listview > li.mm-opened.mm-vertical > .mm-panel,
.mm-menu .mm-listview > li.mm-opened.mm-vertical > a.mm-next,
.mm-menu.mm-vertical .mm-listview > li.mm-opened > .mm-panel,
.mm-menu.mm-vertical .mm-listview > li.mm-opened > a.mm-next {
  background: rgba(0, 0, 0, 0.05);
}

.mm-menu .mm-divider {
  background: rgba(0, 0, 0, 0.05);
}

.mm-page {
  box-sizing: border-box;
  position: relative;
  top: -40px;
}

.mm-slideout {
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  z-index: 1;
}

html.mm-opened {
  overflow-x: hidden;
  position: relative;
}

html.mm-blocking {
  overflow: hidden;
}

html.mm-blocking body {
  overflow: hidden;
}

html.mm-background .mm-page {
  background: inherit;
}

#mm-blocker {
  background: rgba(3, 2, 1, 0);
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

html.mm-blocking #mm-blocker {
  display: block;
}

.mm-menu.mm-offcanvas {
  z-index: 0;
  display: none;
  position: fixed;
}

.mm-menu.mm-offcanvas.mm-opened {
  display: block;
}

.mm-menu.mm-offcanvas.mm-no-csstransforms.mm-opened {
  z-index: 10;
}

.mm-menu.mm-offcanvas {
  width: 80%;
  min-width: 140px;
  max-width: 440px;
}

html.mm-opening .mm-menu.mm-opened ~ .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  transform: translate(80%, 0);
  -webkit-transform: translate3d(80%, 0, 0);
  transform: translate3d(80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-opening .mm-menu.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0);
  }
}

@media all and (min-width: 550px) {
  html.mm-opening .mm-menu.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(440px, 0);
    -ms-transform: translate(440px, 0);
    transform: translate(440px, 0);
    -webkit-transform: translate3d(440px, 0, 0);
    transform: translate3d(440px, 0, 0);
  }
}

.mm-sronly {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
}

em.mm-counter {
  font: inherit;
  font-size: 14px;
  font-style: normal;
  text-indent: 0;
  line-height: 20px;
  display: block;
  margin-top: -10px;
  position: absolute;
  right: 45px;
  top: 50%;
}

em.mm-counter + a.mm-next {
  width: 90px;
}

em.mm-counter + a.mm-next + a,
em.mm-counter + a.mm-next + span {
  margin-right: 90px;
}

em.mm-counter + a.mm-fullsubopen {
  padding-left: 0;
}

em.mm-counter + a.mm-fullsubopen + a,
em.mm-counter + a.mm-fullsubopen + span {
  padding-right: 90px;
}

.mm-listview em.mm-counter + .mm-next.mm-fullsubopen + a,
.mm-listview em.mm-counter + .mm-next.mm-fullsubopen + span {
  padding-right: 90px;
}

.mm-vertical > .mm-counter {
  top: 12px;
  margin-top: 0;
}

.mm-vertical.mm-spacer > .mm-counter {
  margin-top: 40px;
}

.mm-nosubresults > .mm-counter {
  display: none;
}

.mm-menu em.mm-counter {
  background-color: rgba(0, 0, 0, 0.3);
  height: 20px;
  width: 20px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  line-height: 21px;
  font-size: 10px;
  font-weight: 600;
}

.mm-menu em.mm-counter {
  background-color: #66676b;
}

/* Trigger Styles */
.mmenu-trigger {
  height: 46px;
  width: 46px;
  display: none;
  position: relative;
  margin: 0;
  border-radius: 4px;
  cursor: pointer;
}

/* Hamburger Icon */
.hamburger {
  padding: 0;
  top: 7px;
  left: 12px;
  transform: scale(0.67);
  -moz-transform: scale(0.7) translateY(4px);
  position: relative;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger-box {
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 33px;
  height: 4px;
  background-color: #777676;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
  content: '';
  display: block;
}

.hamburger-inner::before {
  top: -10px;
}

.hamburger-inner::after {
  bottom: -10px;
}

/* Media Queries Menu */
@media (max-width: 1099px) {
  /* Header Responsive Styles */
  #responsive {
    display: none;
  }

  .mmenu-trigger {
    display: inline-block !important;
  }

  .mmenu-trigger {
    top: 0px;
    margin-right: 5px;
  }

  .mm-page {
    top: 0px;
  }
}

/* ---------------------------------- */
/* Icons Cheatsheet Styles
------------------------------------- */
.icon-set-container {
  display: block;
  width: calc(100% + 30px);
  left: -15px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.glyph.fs1 {
  position: relative;
  font-size: 20px;
  margin: 10px 15px;
  background-color: #fff;
  box-shadow: -10px 8px 10px -7px rgba(25, 42, 70, 0.13);
  border-radius: 4px;
  flex: 1 1 calc(18% - 36px);
  align-content: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 15px 15px;
  padding-left: 0;
  transition: 0.3s;
  color: #666;
  border-radius: 6px;
  overflow: hidden;
}

.glyph.fs1:hover {
  transform: translateY(-5px);
  background-color: #f5f5f5;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.glyph.fs1:hover span:not([class*='mls']) {
  background-color: #fff;
  color: #333;
}

.glyph.fs1 .fs0,
.glyph.fs1 fieldset {
  display: none;
}

.glyph.fs1 span:not([class*='mls']) {
  vertical-align: middle;
  top: -4px;
  height: 100%;
  width: 52px;
  background: rgba(0, 0, 0, 0.04);
  display: inline-block;
  /* line-height: 40px; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-radius: 4px; */
  font-size: 24px;
  transition: 0.3s;
  position: relative;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.glyph.fs1 .mls:not([class*='icon-']) {
  max-width: 80%;
  display: inline-block;
  overflow: hidden;
  font-size: 13px;
  line-height: 20px;
  top: 0;
  vertical-align: middle;
  padding-left: 65px;
}

@media (max-width: 768px) {
  .glyph.fs1 {
    box-shadow: -10px 8px 10px -7px rgba(25, 42, 70, 0.13);
    flex: 1 1 calc(40% - 30px);
  }
}

/****************
Simplebar  Styles
****************/
[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
  height: 100%;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  width: auto;
  visibility: visible;
  overflow: scroll;
  max-width: 100%;
  max-height: 100%;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: inherit;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.simplebar-scrollbar {
  position: absolute;
  right: 0px;
  width: 5px;
  min-height: 10px;
}

.simplebar-scrollbar:before {
  position: absolute;
  content: '';
  border-radius: 7px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s linear;
  transition: opacity 0.2s, background-color 0.3s;
  background-color: #545252;
}

.simplebar-track .simplebar-scrollbar.simplebar-visible:before {
  opacity: 0.5;
  transition: opacity 0s linear;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 10px;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}

[data-simplebar-direction='rtl'] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}

/* ---------------------------------- */
/* nprogress
------------------------------------- */

/* Make clicks pass-through  */
#nprogress {
  pointer-events: none;
  -webkit-pointer-events: none;
}

#nprogress .bar {
  background: #12111b;
  position: fixed;
  z-index: 111000;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
}

/* Fancy blur effect   */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #6de877, 0 0 5px #8bca90;
  opacity: 1;
  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -moz-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  -o-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner  */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 110000;
  top: 25px;
  right: 25px;
}

/* spinner
#nprogress .spinner-icon {
  width: 14px;
  height: 14px;
  border: solid 3px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 78px;
  -webkit-animation: nprogress-spinner 400ms linear infinite;
  -moz-animation: nprogress-spinner 400ms linear infinite;
  -ms-animation: nprogress-spinner 400ms linear infinite;
  -o-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

@-webkit-keyframes nprogress-spinner {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }

  100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

@-moz-keyframes nprogress-spinner {
  0% {
      -moz-transform: rotate(0deg);
      transform: rotate(0deg);
  }

  100% {
      -moz-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

@-o-keyframes nprogress-spinner {
  0% {
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
  }

  100% {
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

@-ms-keyframes nprogress-spinner {
  0% {
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
  }

  100% {
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

@keyframes nprogress-spinner {
  0% {
      transform: rotate(0deg);
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

*/

/* ---------------------------------- */
/* Bootstrap-select
------------------------------------- */
select.bs-select-hidden,
select.selectpicker {
  display: none !important;
}

.bootstrap-select .btn {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.bootstrap-select > .dropdown-toggle {
  width: 100%;
  padding-right: 25px;
  z-index: 1;
}

.bootstrap-select > .dropdown-toggle.bs-placeholder,
.bootstrap-select > .dropdown-toggle.bs-placeholder:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder:active {
  color: #808080;
}

.bootstrap-select > select {
  position: absolute !important;
  bottom: 0;
  left: 50%;
  display: block !important;
  width: 0.5px !important;
  height: 100% !important;
  padding: 0 !important;
  opacity: 0 !important;
  border: none;
}

.bootstrap-select > select.mobile-device {
  top: 0;
  left: 0;
  display: block !important;
  width: 100% !important;
  z-index: 2;
}

.has-error .bootstrap-select .dropdown-toggle,
.error .bootstrap-select .dropdown-toggle {
  border-color: #b94a48;
}

.bootstrap-select.fit-width {
  width: auto !important;
}

.bootstrap-select:not([class*='col-']):not([class*='form-control']):not(
    .input-group-btn
  ) {
  width: 100%;
}

.bootstrap-select.form-control {
  margin-bottom: 0;
  padding: 0;
  border: none;
}

.bootstrap-select.form-control:not([class*='col-']) {
  width: 100%;
}

.bootstrap-select.form-control.input-group-btn {
  z-index: auto;
}

.bootstrap-select.form-control.input-group-btn:not(:first-child):not(
    :last-child
  )
  > .btn {
  border-radius: 0;
}

.bootstrap-select.btn-group:not(.input-group-btn),
.bootstrap-select.btn-group[class*='col-'] {
  float: none;
  display: flex;
  margin-left: 0;
}

.bootstrap-select.btn-group.dropdown-menu-right,
.bootstrap-select.btn-group[class*='col-'].dropdown-menu-right,
.row .bootstrap-select.btn-group[class*='col-'].dropdown-menu-right {
  float: right;
}

.form-inline .bootstrap-select.btn-group,
.form-horizontal .bootstrap-select.btn-group,
.checkbox .bootstrap-select.btn-group {
  margin-bottom: 0;
}

.checkbox-lg .bootstrap-select.btn-group.form-control,
.checkbox-sm .bootstrap-select.btn-group.form-control {
  padding: 0;
}

.checkbox-lg .bootstrap-select.btn-group.form-control .dropdown-toggle,
.checkbox-sm .bootstrap-select.btn-group.form-control .dropdown-toggle {
  height: 100%;
  font-size: inherit;
  line-height: inherit;
  border-radius: inherit;
}

.form-inline .bootstrap-select.btn-group .form-control {
  width: 100%;
}

.bootstrap-select.btn-group.disabled,
.bootstrap-select.btn-group > .disabled {
  cursor: not-allowed;
}

.bootstrap-select.btn-group.disabled:focus,
.bootstrap-select.btn-group > .disabled:focus {
  outline: none !important;
}

.bootstrap-select.btn-group.bs-container {
  position: absolute;
  height: 0 !important;
  padding: 0 !important;
}

.bootstrap-select.btn-group.bs-container .dropdown-menu {
  z-index: 1060;
}

.bootstrap-select.btn-group .dropdown-toggle .filter-option {
  overflow: hidden;
  width: 100%;
  line-height: 42px;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 90%;
  float: left;
  height: 42px;
  display: block;
}

.bootstrap-select.btn-group .dropdown-menu li.hidden {
  display: none;
}

.language-switcher.bootstrap-select.btn-group .dropdown-toggle .filter-option,
.sort-by .bootstrap-select.btn-group .dropdown-toggle .filter-option {
  max-width: 100%;
  float: left;
}

.sort-by .bootstrap-select.btn-group .dropdown-toggle .filter-option {
  line-height: 20px;
  height: auto;
}

.bootstrap-select.btn-group .dropdown-toggle .caret {
  position: absolute;
  top: 50%;
  right: 20px;
  margin-top: -2px;
  vertical-align: middle;
  transition: 0.35s;
}

.bootstrap-select.open .dropdown-toggle .caret {
  transform: rotate(180deg);
}

.bootstrap-select.btn-group[class*='col-'] .dropdown-toggle {
  width: 100%;
}

.bootstrap-select.btn-group .dropdown-menu {
  min-width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 16px;
}

.bootstrap-select.btn-group .dropdown-menu.inner {
  position: static;
  float: none;
  border: 0;
  padding: 0 5px;
  border-radius: 0;
  box-shadow: none;
}

.bootstrap-select.btn-group .dropdown-menu li {
  position: relative;
  margin-bottom: 1px;
}

.bootstrap-select.btn-group .dropdown-menu li.disabled {
  opacity: 0.75;
}

.bootstrap-select.btn-group .dropdown-menu li.active small {
  color: #fff;
}

.bootstrap-select.btn-group .dropdown-menu li.disabled a {
  cursor: not-allowed;
}

.bootstrap-select.btn-group .dropdown-menu li a {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.bootstrap-select.btn-group .dropdown-menu li a span.text {
  display: inline-block;
}

.bootstrap-select.btn-group .dropdown-menu li small {
  padding-left: 7px;
  opacity: 0.8;
}

.bootstrap-select.btn-group .dropdown-menu .notify {
  position: absolute;
  bottom: 5px;
  width: calc(100% - 20px);
  margin: 0;
  min-height: 26px;
  padding: 3px 5px;
  pointer-events: none;
  padding: 5px 10px;
  background: #333;
  color: #fff;
  margin: 5px;
  margin-bottom: 5px;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 14px;
  border: none;
}

.bootstrap-select.btn-group .no-results {
  padding: 5px 10px;
  background: #f6f6f6;
  margin: 5px 0;
  margin-bottom: 0;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 14px;
}

.bootstrap-select.btn-group.fit-width .dropdown-toggle .filter-option {
  position: static;
}

.bootstrap-select.btn-group.fit-width .dropdown-toggle .caret {
  position: static;
  top: auto;
  margin-top: -1px;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li a span.text {
  white-space: normal;
}

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle {
  z-index: 1061;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle:before {
  content: '';
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid rgba(204, 204, 204, 0.2);
  position: absolute;
  bottom: -4px;
  left: 9px;
  display: none;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle:after {
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  position: absolute;
  bottom: -4px;
  left: 10px;
  display: none;
}

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:before {
  bottom: auto;
  top: -3px;
  border-top: 7px solid rgba(204, 204, 204, 0.2);
  border-bottom: 0;
}

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:after {
  bottom: auto;
  top: -3px;
  border-top: 6px solid white;
  border-bottom: 0;
}

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:before {
  right: 12px;
  left: auto;
}

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:after {
  right: 13px;
  left: auto;
}

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle:before,
.bootstrap-select.show-menu-arrow.open > .dropdown-toggle:after {
  display: block;
}

.bs-searchbox,
.bs-actionsbox,
.bs-donebutton {
  padding: 0 5px;
}

.bs-actionsbox {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 5px;
  margin-bottom: 10px;
  margin-top: -5px;
}

.bs-actionsbox div {
  display: flex;
}

.bootstrap-select.btn-group .bs-actionsbox div button {
  flex: 1;
  background: #f6f6f6;
  box-shadow: none;
  padding: 5px;
  height: 36px;
  font-size: 14px;
  color: #777;
  transition: 0.3s;
}

.bootstrap-select.btn-group .bs-actionsbox div button:first-child {
  border-radius: 4px 0 0 4px;
  background-color: #f0f0f0;
}

.bootstrap-select.btn-group .bs-actionsbox div button:last-child {
  border-radius: 0 4px 4px 0;
}

.bootstrap-select.btn-group .bs-actionsbox div button:hover {
  background: #66676b;
  color: #fff;
}

.bs-actionsbox .btn-group button {
  width: 50%;
}

.bs-donebutton {
  float: left;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.bs-donebutton .btn-group button {
  width: 100%;
}

.bs-searchbox + .bs-actionsbox {
  padding: 0;
}

.bs-searchbox .form-control:focus,
.bs-searchbox .form-control {
  margin-bottom: 0;
  width: 100%;
  float: none;
  box-shadow: none;
  background: #f4f4f4;
  margin: -5px 0 10px 0;
  height: 38px;
  line-height: 38px;
  padding: 10px;
}

.bs-searchbox {
  position: relative;
}

.bs-searchbox:after {
  font-family: 'Material-Icons';
  content: '\e982';
  font-size: 20px;
  width: 20px;
  height: 20px;
  line-height: 0;
  display: inline-block;
  position: absolute;
  margin: 0;
  color: #a0a0a0;
  right: 15px;
  top: 18px;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid #aaa;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup,
.dropdown {
  position: relative;
}

.dropdown-toggle:focus {
  outline: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  padding: 10px 5px !important;
  margin: -4px 0 0 0 !important;
  font-size: 15px;
  text-align: left;
  list-style: none !important;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.12);
}

.dropdown-menu.pull-right {
  right: 0;
  left: auto;
}

.dropdown-menu .divider {
  display: none;
}

.dropdown-menu > li > a {
  display: block;
  padding: 7px 10px;
  padding-right: 35px;
  clear: both;
  font-weight: normal;
  line-height: 21px;
  color: #808080;
  border-radius: 4px;
}

.bootstrap-select:before {
  content: '';
  position: absolute;
  z-index: 2100;
  bottom: 0px;
  left: 0;
  height: 8px;
  width: 100%;
  background-color: #fff;
  display: block;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
}

.bootstrap-select.open:before {
  opacity: 1;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover {
  color: #66676b;
  text-decoration: none;
  background-color: rgba(102, 103, 107, 0.07);
}

.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:hover {
  color: #777;
}

.dropdown-menu > .disabled > a:hover {
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
  background-image: none;
}

/* Custom Scrollbar for -wekbit */
.dropdown-menu.inner::-webkit-scrollbar {
  width: 5px;
}

.dropdown-menu.inner::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 12px;
}

.dropdown-menu.inner::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background-color: #d8d8d8;
}

.dropdown-menu.inner::-webkit-scrollbar-thumb:hover {
  border-radius: 12px;
  background-color: #c8c8c8;
}

.bootstrap-select .dropdown-menu {
  opacity: 0;
  transition: 0.4s;
  display: block;
  pointer-events: none;
  visibility: hidden;
  cursor: default;
  min-width: 140px;
}

.bootstrap-select.open .dropdown-menu {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
}

.open > a {
  outline: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-header {
  display: block;
  padding: 7px 10px;
  line-height: 20px;
  white-space: nowrap;
  background: #f6f6f6;
  color: #777;
  border-radius: 4px;
  margin-bottom: 1px;
}

.dropdown-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990;
}

.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  content: '';
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
}

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: 100%;
  bottom: auto;
  margin-bottom: 2px;
}

@media (min-width: 767px) {
  .navbar-right .dropdown-menu {
    right: 0;
    left: auto;
  }

  .navbar-right .dropdown-menu-left {
    right: auto;
    left: 0;
  }
}

.bootstrap-select.btn-group {
  position: relative;
}

.bootstrap-select.btn-group button {
  position: relative;
  display: inline-block;
  height: 43px;
  padding: 0 20px;
  margin: 0;
  outline: none;
  font-size: 15px;
  color: #808080;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  display: block;
  border: 1px solid #e2e5ec;
  background-color: #fff;
  font-weight: 500;
  opacity: 1;
  border-radius: 4px;
  /* border: none; */
  /* box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.12); */
  min-width: 140px;
}

.bootstrap-select .dropdown-toggle::after {
  display: none !important;
}

/* Select Check Icon */
.hide-tick .check-mark {
  display: none !important;
}

.bootstrap-select.btn-group .dropdown-menu li a span.check-mark {
  position: absolute;
  display: inline-block;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  line-height: 0;
  width: 20px;
  height: 20px;
  text-align: center;
}

.bootstrap-select.btn-group .dropdown-menu li span.check-mark:before {
  font-family: 'Feather-Icons';
  content: '\e92b';
  font-size: 18px;
  transition: all 0.3s;
  display: inline-block;
  top: 50%;
  right: 0;
  position: absolute;
  margin: 0;
  opacity: 0;
  transform: scale(0.5);
}

.bootstrap-select.btn-group .dropdown-menu li.selected span.check-mark:before {
  opacity: 1;
  transform: scale(1);
  transition: all 0.35s cubic-bezier(0.3, -0.41, 0.19, 2), opacity 0.3s;
}

.bootstrap-select .dropdown-menu li.selected a span.check-mark:before,
.bootstrap-select .dropdown-menu li.selected:hover a span.check-mark:before {
  color: #66676b;
}

/* Icons*/
.bootstrap-select .glyphicon:not(.check-mark) {
  font-size: 17px;
  line-height: 0;
  position: relative;
  top: 1px;
  margin-right: 3px;
}

.bootstrap-select .filter-option .glyphicon {
  position: relative;
  top: 1px;
}

/* Styles with border */
.with-border .dropdown-menu,
.with-border.bootstrap-select.btn-group button {
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.05);
}

.with-border.bootstrap-select:before {
  width: calc(100% - 2px);
  left: 1px;
}
