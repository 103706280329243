#loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw !important;
  height: 100vh !important;
  color: green !important;
  z-index: 999;

  display: flex;
  justify-content: center;
  align-items: center;

  #loading-app {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 160px;
      opacity: 1;
      stroke-width: 12px;
      animation: dash 2.3s ease-in infinite alternate;
    }

    @keyframes dash {
      0% {
        stroke-dasharray: 600 400;
        stroke-dashoffset: 300;
        fill-opacity: 0.2;
      }
      90% {
        fill-opacity: 1;
      }
      100% {
        stroke-dasharray: 900 800;
        stroke-dashoffset: 400;
        stroke-opacity: 0;
      }
    }
  }
}
