#page-learn {
  position: relative;

  background-color: var(--color-400-primary) !important;
  color: var(--color-text);
  min-height: 100vh !important;
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 3px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }

  .video-responsive {
    position: relative;
  }
  #overlay {
    display: flex;
    background: rgba(0, 0, 0, 0.4);
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    color: white;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 0.8;

    i {
      font-size: 30px;
    }
    div {
      display: none;
    }
  }

  .countdown {
    display: flex;
    background: rgba(0, 0, 0, 1);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10000;
    color: white;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 0.9;

    i {
      font-size: 40px;
      opacity: 0.8;
    }

    .next-text {
      opacity: 0.5;
    }

    .next-lesson {
      padding: 0 16px;
      font-size: 24px;
      font-weight: 600;
      cursor: pointer;
    }
    .cancel,
    .next {
      opacity: 0.8;
      cursor: pointer;
      margin-top: 20px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .description {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 12px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #25252b;
    }
  }
  .back-button {
    font-size: 22px;
    margin-right: 10px;

    transform: translateY(2px);
  }

  .drop-show {
    display: block;
  }

  .course-layouts {
    background: #f6f6f6;
    min-height: 100vh;
  }

  .course-content-inner {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    max-width: none;
    min-height: calc(99vh - 90px) !important;
    height: auto !important;

    .check-box {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 0px;

      span {
        margin-left: 15px;
      }
    }

    .react-switch-bg {
      > div {
        width: 22px !important;
        height: 22px !important;
        margin: 0 5px;
      }
    }

    .video-responsive {
      min-height: 50vh;

      width: 100%;
      display: block;
      padding: 0;

      background-color: #040915;
      iframe {
        height: 100%;
        width: 100%;
        position: relative;
        padding: 0;
        margin: 0;
        left: 50%;
        transform: translateX(-50%);
        max-width: 900px;
      }
    }
    .content {
      padding: 39px 20px;
      width: 100%;
      max-width: 900px;
      margin: 0 auto;

      .open-notes-wrap {
        display: flex;
        align-items: center;
        margin-bottom: 24px;

        .open-notes {
          display: block;
          width: 44px;
          height: 44px;
          div {
            border-radius: 50%;
            padding: 13px;
            background-color: transparent;
            outline: 2px solid #4dd0bb;
            &:hover {
              background-color: #38baa5;
              svg {
                path {
                  fill: var(--color-text);
                }
              }
            }
            svg {
              path {
                fill: #4dd0bb;
              }
            }
          }
        }
      }

      .tools {
        display: flex;
        margin-bottom: 24px;
        .check-box {
          .text {
            font-family: 'Poppins', 'sans-serif';
            font-size: 16px;
            margin-left: 8px;
          }
        }
        .next-video {
          button {
            border-radius: 16px;
            padding: 8px;
            font-size: 21px;
            &:last-child {
              margin-left: 8px;
            }
          }
        }
      }

      .row-nav {
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 59px;
        width: 100%;
        width: 100%;
        border-bottom: 1px solid #171426;
        .nav {
          padding-bottom: 2px;
          .nav-link {
            display: flex;
            color: #b2e9e0;
            &.active {
              color: #fff;
              margin-bottom: -4px;
              border-bottom: 3px solid #4dd0bb;
            }
            span {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 22px;
            }
          }
        }
      }
      .tab-pane {
        background-color: transparent !important;
        h4 {
          font-family: 'Poppins', 'sans-serif';
          color: #fff !important;
          font-size: 16px;
          font-weight: 700;
          line-height: 26px;
          letter-spacing: 0em;
          text-align: left;
        }
      }
    }
  }

  .course-sidebar-title {
    .close {
      display: none;
    }
  }
  .course-content {
    background-color: #0c1325;
    position: fixed;
    width: 75%;
    height: 100vh;
    left: 25%;
    overflow-y: auto;
  }

  .course-sidebar {
    position: fixed;
    width: 25%;
    height: 100vh;
    overflow-y: auto;
    background-color: #040915;

    box-shadow: 0px 4px 73px rgba(0, 0, 0, 0.96);

    .search-wrap {
      padding: 0 15px;
      padding-bottom: 16px;
      border-bottom: 1px solid #161d2d;
      .search-module {
        margin-top: 16px;
        position: relative;
        padding: 10px 16px;
        padding-left: 48px;
        border: 2px solid #2c364f;
        border-radius: 100px;

        width: 100%;
        height: 41px;
        label {
          position: absolute;
          top: 50%;
          left: 16px;
          transform: translateY(-50%);
          i {
            color: #2c364f;
            font-size: 24px;
          }
        }
        input {
          position: absolute;
          top: 0;
          left: 0;
          background-color: transparent;
          border: 0;
          padding: 0px;
          margin: 0;
          height: 100%;
          width: 100%;
          padding-left: 48px;
          &::placeholder {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #2c364f;
          }
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #fff !important;
          opacity: 1;
        }
      }
    }
  }

  .course-video-list-section {
    li {
      &.current {
        background-color: #161d2d !important;
      }
      a {
        &:hover {
          background-color: #161d2d;
          .accordion-module-content {
            transform: scale(0.93);
          }
        }
        &::before {
          content: '' !important;
          display: none !important;
        }
        display: flex;
        align-items: center;
        .accordion-check {
          flex-shrink: 0;
          color: #b2e9e0;
          svg {
            width: 20px !important;
            height: 20px !important;
            display: block;
            path {
              /* 	fill: red;  */
            }
          }
        }
        .accordion-module-content {
          margin-left: 16px;
          .module-count {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 300;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0.13em;
            color: #b2e9e0;
            margin-bottom: 4px;
          }
          .module-text {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            color: #ffffff;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            @media (max-width: 1100px) {
              font-size: 13px;
              max-width: 55px !important;
            }
            @media (max-width: 768px) {
              font-size: 14px;
              max-width: 100% !important;
            }
            @supports (-webkit-line-clamp: 3) {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: initial;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }
        }
        .total-lessons {
          margin-left: auto;
          align-items: center;
          justify-self: flex-end;
          padding-left: 16px;

          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #ffffff;
        }
      }
    }
  }

  .course-video-list {
    li {
      background-color: #040915 !important;
      a {
        color: #fff !important;
      }
      .accordion-check {
        svg {
          path {
            fill: #2c364f;
          }
        }
      }
      &.watched {
        background-color: #040915 !important;
        .accordion-check {
          svg {
            path {
              fill: #47e6b1;
            }
          }
        }
      }
    }
  }

  .menu-icon {
    display: none;
  }

  .course-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 10px;
  }
  .course-header {
    background-color: #0c1325;
    & > div {
      flex: 0;
    }
    h4 {
      flex: 1;
      text-align: center;

      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      color: #ffffff;
    }
  }

  @media (max-width: 768px) {
    .course-header {
      justify-content: space-between;
    }
    .menu-icon {
      display: block;
    }
    .course-content {
      left: 0;
      width: 100%;
    }

    .course-sidebar-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .course-sidebar {
      position: fixed;
      width: 85%;
      max-width: 370px !important;
      top: 0;
      left: 0;

      //   &.hidden {
      //   left: -100%;
      //   }

      //   .course-sidebar-title {
      //       display: flex;
      //       justify-content: space-between;
      .close {
        color: #12111b !important;
        float: right;
        display: block;
        font-size: 24px;
        opacity: 1;
      }
      //   }
    }
  }
  .lesson-video {
    display: flex;
    padding: 24px;
    @media (max-width: 600px) {
      padding: 16px !important;
    }
    &:hover {
      .lesson-video-title {
        color: #fff;
        opacity: 1;
      }
      .lesson-video-duration {
        color: #fff;
        opacity: 1;
      }
    }
  }
}

.course-video-list li {
  a {
    .accordion-check {
      flex-shrink: 0;
    }
    .lesson-video-title {
      margin-left: 16px;

      font-family: 'Poppins';
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.06em;
      color: #b2e9e0;
      padding: 0px !important;

      white-space: nowrap !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
    }
    .lesson-video-duration {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #b2e9e0;
      opacity: 0.7;

      margin-left: auto;
      padding-left: 16px;
    }
  }
}

.drop-show {
  .accordion-expand {
    margin-left: 8px;
    i {
      display: block;
      transform: rotate(45deg);
      font-size: 26px;
      display: block;
      color: #4dd0bb;
    }
  }
  &.uk-open {
    .accordion-expand {
      i {
        transform: rotate(90deg);
        color: #a1f6e9;
      }
    }
    & > div {
      display: block !important;
    }
  }
}
.uk-accordion-content {
  border-bottom: 1px solid #161d2d;
}

@media screen and (max-width: 768px) {
  #page-learn {
    .content {
      .tools {
        .check-box {
          .text {
            font-size: 14px !important;
          }
        }
      }
      .row-nav {
        margin-bottom: 32px !important;
        .nav {
          .nav-item {
            .nav-link {
              background-color: transparent !important;
              span {
                font-size: 12px !important;
              }
            }
          }
        }
      }
    }
  }
}

.notes {
  position: absolute;
  top: 0;
  right: -375px;
  width: 375px;
  height: 100%;
  background-color: #241d4f;
  z-index: 999;
  display: none;
  &.show {
    right: 0px;
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
  }
  padding: 24px;
  .header {
    display: flex;
    align-items: center;
    i {
      font-size: 26px !important;
    }

    h2 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 31px;
      color: #ffffff;
      margin: 0 auto;
    }
  }
  .search-note {
    margin-top: 24px;
    position: relative;
    padding: 10px 16px;
    padding-left: 48px;
    border: 2px solid #5b508b;
    border-radius: 100px;

    width: 100%;
    height: 41px;
    label {
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translateY(-50%);
      i {
        color: #5b508b;
        font-size: 24px;
      }
    }
    input {
      position: absolute;
      top: 0;
      left: 0;
      background-color: transparent;
      border: 0;
      padding: 0px;
      margin: 0;
      height: 100%;
      width: 100%;
      padding-left: 48px;
      &::placeholder {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #7b6eb1;
        opacity: 0.3;
      }
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #fff !important;
      opacity: 1;
    }
  }
  .list-notes {
    display: flex;
    margin-top: 24px;
    flex-direction: column;
    row-gap: 8px;

    overflow: auto;

    max-height: 70% !important;

    .note {
      &:hover {
        transform: scale(0.95);
      }
      cursor: pointer;
      border-radius: 16px;
      background-color: #161230;
      padding: 20px;
      .head {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        color: #ffffff;
        margin: 0;
      }
      .text {
        margin-top: 8px;

        height: 54px;

        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: var(--color-text);
      }
      svg {
        margin-top: 16px;
      }
    }
  }
  .btn {
    margin-top: 24px;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    max-width: 100%;
    background-color: #4dd0bb;
    border-radius: 100px;
    border: 0;
    &:hover {
      background-color: #38baa5;
    }
    span {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      display: flex;
      align-items: center;
      color: #ffffff;
    }
    i {
      margin-left: 8px;
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 768px) {
  .notes {
    &.show {
      width: calc(100% - 60px);
    }
    .header {
      h2 {
        font-size: 23px !important;
      }
    }
  }
  .next-lesson {
    font-size: 18px !important;
  }
}

.attachments {
  display: flex;
  flex-wrap: wrap;
  row-gap: 24px;
  column-gap: 24px;
  .file {
    cursor: pointer;
    padding: 20px 20px;
    width: 200px;
    background-color: var(--color-600-primary) !important;
    color: var(--color-text);
    border-radius: 16px;
    &:hover {
      background-color: var(--color-600-primary) !important;
      .name {
        transform: scale(0.97);
      }
      .details {
        transform: scale(0.97);
      }
    }
    .name {
      font-weight: 600;
      margin-bottom: 8px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icone {
        i {
          font-size: 18px;
        }
        span {
          margin-left: 4px;
        }
      }
    }
  }
}

.modal-create-notes {
  margin: 0 auto;
  max-width: 370px;
  .modal-content {
    background-color: transparent;
    .modal-body {
      form {
        max-width: 327px;
        .post {
          border-radius: 16px;
          padding: 16px;
          background-color: var(--color-500-primary) !important;
          .field-title {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            color: var(--color-text);

            background-color: transparent;
            border: 0;
            padding: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            &::placeholder {
              color: var(--color-text);
            }
          }
          .form-group {
            margin: 0;
          }

          .field-note {
            height: 100% !important;
            width: 100%;
            display: block;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: var(--color-text);
            background-color: transparent;
            border: 0;
            padding: 0;
            margin: 0;
            &:focus {
              box-shadow: none;
            }
            &::placeholder {
              color: var(--color-text);
            }
          }
          .btn {
            display: flex;
            align-items: center;
            border: 0;
            background-color: var(--color-400-secondary) !important;
            color: var(--color-400-primary) !important;

            border-radius: 100px;
            margin-left: auto;
            line-height: 0 !important;
            span {
              font-size: 14px;
            }
            i {
              font-size: 20px;
            }
          }
          .btn-close {
            background-color: transparent !important;
            border: 2px solid var(--color-text) !important;
            color: var(--color-text) !important;
            box-shadow: none !important;
          }
        }
        .row-btn {
          display: flex;
          flex-direction: row !important;
          .btn-close {
            background-color: #f7f7f7;
            color: #19191a;
            margin-left: 0;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
  }
}

.modal-notes {
  .modal-content {
    background-color: transparent;
    .modal-body {
      width: 100%;
      padding: 16px;
      .post {
        background-color: red;
      }
    }
  }
}

.modal-notes {
  margin: 0 auto;
  max-width: 370px;
  .modal-content {
    background-color: transparent;
    .modal-body {
      .post {
        max-width: 327px;
        border-radius: 16px;
        padding: 16px;
        background-color: var(--color-500-primary) !important;
        h2 {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;
          color: var(--color-text);
        }
        p {
          margin-bottom: 0;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: var(--color-text);
          min-height: 120px;
        }
        .btn {
          display: flex;
          align-items: center;
          border: 0;
          border-radius: 100px;
          margin-left: auto;

          span {
            font-size: 12px;
          }
        }
        .row-btn {
          display: flex;
          flex-direction: row !important;
          .btn-close {
            margin-left: 0;
          }
        }
      }
    }
  }
}

#container-video {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  max-width: 1440px;
  padding: 0 20px;
  flex-wrap: unset;
  @media (max-width: 1000px) {
    margin-top: 58px !important;
    flex-wrap: wrap !important;
    flex-direction: column-reverse;
  }
  #video-container {
    background-color: var(--color-500-primary) !important;
    width: 100%;
    border-radius: 0 0 0 20px;
    #video {
      iframe {
        max-width: 100%;
        aspect-ratio: 16 / 9;
        width: 100%;
        height: 100%;
      }
    }
    .infos {
      color: var(--color-text);
      h2 {
        margin-top: 34px;
        font-size: 31px;
        font-weight: 600;
        color: var(--color-text);
      }
    }
  }
  #right-sidebar {
    background-color: var(--color-500-primary) !important;

    max-width: 393px !important;
    min-width: 393px !important;
    border-radius: 0 0 20px 0 !important;

    @media (max-width: 1000px) {
      min-width: 100% !important;
      width: 100% !important;
      max-width: 600px !important;
    }
    .info-module {
      padding: 24px;
      h6 {
        color: var(--color-text);
        opacity: 0.5;
      }
      h3 {
        color: var(--color-text);
        font-size: 24px;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .wrap-progress {
        progress {
          border-radius: 3.5px !important;
          height: 7px;
          width: 100%;
          background-color: #393939 !important;
          border: 0;
        }
        progress::-webkit-progress-bar {
          border-radius: 3.5px !important;
          height: 7px;
          width: 100%;
          background-color: #393939 !important;
          border: 0;
        }
        progress::-moz-progress-bar {
          border-radius: 3.5px !important;
          height: 7px;
          width: 100%;
          background-color: #393939 !important;
          border: 0;
        }
        progress::-webkit-progress-value {
          border-radius: 3.5px !important;

          background-color: #20c374 !important;
        }
        progress::-moz-progress-bar {
          background-color: #20c374 !important;
        }
        .progress-text {
          display: flex;
          justify-content: space-between;
          margin-top: 12px;
          font-size: 12px;
          font-weight: 500;
        }
      }
      border-bottom: 1px solid var(--color-600-primary) !important;
    }
    .bar {
      padding: 24px;
      background-color: var(--color-500-primary) !important;
      overflow: hidden;
      border-radius: 0 0 20px 0 !important;

      .lesson-item {
        display: flex;
        align-items: flex-start;
        color: var(--color-text);

        &:hover {
          color: var(--color-text);
          opacity: 0.5;
        }
        .status-indicator {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 16px;
          .circle {
            width: 100%;
            height: 100%;
            &.pending {
              width: 24px;
              height: 24px;
              border: 2px solid #333333;
            }
            &.complete {
              font-size: 18px;
              border: 2px solid #20c374;
              background-color: #20c374;
              color: var(--color-text);
              padding: 1px;
            }
            &.current {
              font-size: 18px;
              border: 2px solid var(--color-400-secondary);
              color: var(--color-400-secondary);
              padding: 1px;
            }
            &.wached {
              font-size: 18px;
              border: 2px solid #20c374;
              background-color: #20c374;
              color: #fff;
              padding: 1px;
            }
          }
          .line {
            display: block;
            width: 2px;
            height: 30px;
            &.pending {
              background-color: #333333;
            }
            &.complete {
              background-color: #20c374;
            }
            &.current {
              background-color: var(--color-400-secondary);
            }
            &.wached {
              background-color: #20c374;
            }
          }
        }
        .text {
          font-size: 14px;
          font-weight: 500;
        }
      }
      .lesson-title {
        color: unset !important;

        &.pending {
          color: #333333 !important;
        }
        &.watched {
          .line {
            background-color: #20c374 !important;
          }
          i {
            border-color: #20c374 !important;
            color: #20c374 !important;
          }
        }
        &:hover {
          color: unset !important;
        }
      }
      .control-button {
        display: flex;
        align-items: center;
        font-size: 15px;
        font-weight: 500;
        padding: 7px 16px;
        border-radius: 100px;
        background-color: #df004300;
        border: 1px solid var(--color-400-secondary) !important;

        width: min-content;
        text-wrap: nowrap;
        color: #fff;
        margin-top: 24px;
        cursor: pointer;
        &:hover {
          background-color: var(--color-400-secondary);
        }

        i {
          font-size: 21px;
        }
      }
    }
  }
  .wrap-check {
    @media (max-width: 998px) {
      .control-button {
        margin-left: auto;
        margin-top: 20px;
      }
    }
  }

  .control-bar {
    display: flex;
    flex-direction: column;
    background-color: var(--color-500-primary) !important;
    padding: 32px 51px;
    @media (max-width: 998px) {
      padding: 24px;
    }
    h2 {
      margin: 0 !important;
      margin-bottom: 21px !important;
    }
    .check-box {
      display: flex;
      align-content: center;
      margin-bottom: 0;
      .react-switch {
        margin-right: 14px;
      }
    }
    .control-button {
      display: flex;
      align-items: center;
      font-size: 15px;
      font-weight: 500;
      padding: 7px 16px;
      border-radius: 100px;
      background-color: var(--color-400-secondary);
      color: #fff;
      cursor: pointer;
      &:hover {
        background-color: var(--color-500-secondary);
      }
      div {
        margin-right: 37px;
      }
      i {
        font-size: 21px;
      }
    }
  }
  .content-text {
    .nav {
      background-color: var(--color-500-primary);
      padding-top: 24px;

      border-bottom: 2px solid var(--color-600-primary) !important;
      .nav-item {
        .nav-link {
          font-size: 16px;
          font-weight: 600;
          padding: 16px 12px;
          color: var(--color-text);
          &:hover {
            color: var(--color-400-secondary);
          }
          &.active {
            background-color: transparent !important;
            border-bottom: 2px solid var(--color-400-secondary) !important;
            border-radius: 0;
            color: var(--color-400-secondary) !important;
          }
        }
      }
    }
  }
  .content {
    min-height: max-content;
    color: var(--color-text);
    background-color: var(--color-500-primary);
    padding: 24px;
    padding-bottom: 27px;
    border-radius: 0 0 0 20px;
    .comments {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
    }
    .card-comment {
      width: 100%;
      padding: 24px;
      background-color: #1c1c1c;
      border-radius: 8px;
      .top {
        display: flex;
        justify-content: space-between;
        .user {
          display: flex;
          align-items: center;
          gap: 13px;
          .img {
            width: 40px;
          }
          .name {
            font-size: 16px;
            font-weight: 500;
            color: #fff;
          }
          .date {
            font-size: 13px;
            font-weight: 400;
            color: #515151;
          }
        }
        .right {
          display: flex;
          align-items: center;
          cursor: pointer;

          .icon-right {
            margin-right: 8px;
            color: #9f9f9f;
            font-size: 21px;
          }
          .text {
            font-size: 14px;
            font-weight: 400;
            color: #9f9f9f;
          }
        }
      }
      .text-comment {
        margin-top: 16px;
        font-size: 13px;
        font-weight: 400;
        color: #cbcbcb;
      }
    }
    .card-response {
      /* border-left: 2px solid #282828; */
      margin-left: 120px !important;
      width: 100%;
      border-radius: 8px;
      padding: 24px;
      background-color: #1c1c1c;
      display: flex;
      justify-content: space-between;
      gap: 16px;
      .img {
        min-width: 40px !important;
      }
      .text-comment {
        padding: 12px 24px;
        border-radius: 8px;
        background-color: #111111;
        color: #fff;
        font-size: 16px;
        font-weight: 400;
      }
      .btn-response {
        background-color: #df0043;
        &:hover {
          background-color: #c2013b;
        }
        border-radius: 8px;
        color: #fff;
        font-size: 16px;
        padding: 12px;
        height: min-content !important;
        cursor: pointer;
      }
    }
  }
}

#page-video {
  header {
    padding: 8px;
    background-color: #070707;
    display: block;
    margin-bottom: 0 !important;
  }
}

.archives {
  display: flex;
  width: 100%;
  gap: 16px;
  .archive {
    background-color: var(--color-400-primary);
    &:hover {
      background-color: var(--color-500-primary);
    }
    max-width: 200px;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    .title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .bottom-bar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;
      .type {
        display: flex;
        align-items: center;
        i {
          color: var(--color-text);
          margin-right: 4px;
        }
        .arquive-type {
          font-size: 14px;
        }
      }
      .file-size {
        font-size: 13px;
      }
    }
  }
}
