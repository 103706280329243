#page-home {
  position: relative;
  margin-top: -50px;
  overflow: visible;
  z-index: 99999;
  .products {
    &:first-child {
      margin-top: 0px;
    }
    h2 {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: #ffffff;
      margin-bottom: 35px;
    }
    h3 {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 24px;
      line-height: 30px;
      color: #ffffff;
      margin-bottom: 26px;
      text-transform: none;
    }
  }
}
