#page-activity {
  table {
    tr {
      flex-wrap: nowrap;
      th {
        border-top: 0;
        div {
          display: flex !important;
          align-items: center !important;
        }
      }
    }
    td {
      vertical-align: middle;
    }
  }
}

#page-activity {
  width: 100%;
  .page-activity-bg {
    position: relative;
    object-fit: fill;
    height: 500px;
    width: 100%;
    .activity-background {
      display: inline-block;
      width: 100%;
      height: inherit;
    }
    .activity-background-color {
      background-color: #0f1b35 !important;
      display: inline-block;
      width: 100%;
      height: inherit;
    }
    .eclipse {
      position: absolute;
      height: inherit;
      width: 100%;
      bottom: 0px;
      right: 0px;
      background: linear-gradient(to top, #121212 0%, transparent 40%);
    }
  }
  .activity {
    position: relative;
    z-index: 222;

    h2 {
      z-index: 223;
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 51px;
      line-height: 56px;
      color: #ffffff;
      margin-bottom: 55px;
    }
  }
  .btn-refund {
    background-color: transparent;
    color: #aaa;
    border-color: #aaa;
    padding: 8px 16px;
    &:hover {
      background-color: #aaa;
      color: #1d1d1d;
    }
  }
}
@media screen and (max-width: 768px) {
  .data-table {
    .top-bar {
      .text {
        font-size: 12px !important;
      }
    }
  }
}
.table-action {
  tbody {
    color: white;
    tr {
      &:first-child {
        td {
          border-top: 0px;
        }
      }
      td {
        vertical-align: middle !important;
        border-top: 1px solid #b2e9e0;
      }
    }
  }
  a {
    color: #4dd0bb;
    &:hover {
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 768px) {
  .table-action {
    tbody {
      tr {
        td {
          overflow-wrap: break-word !important;
          font-size: 12px;
        }
      }
    }
  }
  .modal-body {
    padding: 0rem;
  }
}
